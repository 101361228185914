import React from "react"
import "../drag-n-drop-display.sass"

import { useTranslation } from "react-i18next"
import { DragIndicator } from "@mui/icons-material"

import * as GraphQL from "../../../../graphql"
import {
  audienceDisplayCodes,
  AudienceGroup,
  DragNDropSwitchInformation,
  getDisplayName,
} from "../../constants"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { AUDIENCE_DISPLAY_TOGGLES, DefaultSuggestionListToggles } from "../../../../util/constant"
import ToggleGroup from "./group"
import { setUpdateListForm } from "../../../../state/listConfigurationSlice"
import Switch from "../../../Switch"

/**
 * AudienceDisplayTogglesProps: All properties passed to this component
 */
export interface AudienceDisplayTogglesProps {
  isDragging: boolean
  setDraggingInfo: (info: AudienceGroup | GraphQL.SuggestionListToggleInput | undefined) => void
}

/**
 * AudienceDisplayToggles: This component displays all the toggles and groups of toggles for the Audience
 * display toggles.
 * @param props The properties passed to this component
 * @returns The React JSX elements to display the toggle switches
 */
export default function AudienceDisplayToggles({ isDragging, setDraggingInfo }: AudienceDisplayTogglesProps) {
  // Local field variables
  const {
    t: translateCVH,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  // Global state
  const updateListForm = useSelector((state) => state.listConfiguration.updateListForm)

  // Local state
  const [ toggleOrder, setToggleOrder ] = React.useState<GraphQL.SuggestionListToggleInput[]>([])
  const [ switches, setSwitches ] = React.useState<(AudienceGroup | GraphQL.SuggestionListToggleInput)[]>([])
  const [ dragInfo, setDragInfo ] = React.useState<DragNDropSwitchInformation>()

  /**
   * React hook to get all the toggles and ensure they are in array format.
   */
  const allToggles = React.useMemo((): GraphQL.SuggestionListToggleInput[] => {
    if (updateListForm) {
      const { toggles } = updateListForm
      if (Array.isArray(toggles)) return [ ...toggles ]
      return [ toggles ]
    }
    return []
  }, [ updateListForm ])

  /**
   * React hook to place all the toggles in the correct order as well as create the
   * array of switches for display purposes
   */
  React.useEffect(() => {
    if (allToggles.length > 0) {
      // Pull the default toggles
      const defaultToggles = (toggleOrder.length === 0)
        ? DefaultSuggestionListToggles
          .filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.AudienceToggles)
          .sort((t1, t2) => t1.order - t2.order)
        : [ ...toggleOrder ]

      // Get selected toggles
      const selectedToggles = allToggles
        .filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.AudienceToggles)
        .sort((a, b) => a.order - b.order)

      // Replace default toggles with selected ones
      selectedToggles.forEach((toggle) => {
        const foundToggle = defaultToggles.find((t) => t.name === toggle.name)
        if (foundToggle) {
          // Remove and add toggle in proper place
          defaultToggles.splice(defaultToggles.indexOf(foundToggle), 1)
          defaultToggles.splice(toggle.order, 0, toggle)
        }
      })
      const updateTogglesOrder = defaultToggles.map((t, index) => ({
        ...t,
        order: index,
      }))

      // Set the switches and toggle order
      setSwitches(buildAudienceSwitches(updateTogglesOrder))
      setToggleOrder(updateTogglesOrder)
    }
  }, [ allToggles ])

  /**
   * buildAudienceSwitches: This function takes a set of toggles and builds the switches used for display
   * basked on updated order of the toggles provided
   * @param toggles The ordered toggle list
   * @returns The switches array based on the order
   */
  const buildAudienceSwitches = (
    toggles: GraphQL.SuggestionListToggleInput[],
  ): (AudienceGroup | GraphQL.SuggestionListToggleInput)[] => {
    // Create the switches variables
    const updateSwitches: (AudienceGroup | GraphQL.SuggestionListToggleInput)[] = []

    // Build array of switches
    toggles.forEach((toggle, index) => {
      if (isMemberOfGroup(toggle)) {
        // Get the name of the group
        const groupName = getGroupName(toggle)

        // Check to see if the group already exists
        const group = updateSwitches.find((g) => g.name === groupName) as AudienceGroup | undefined
        if (group) {
          // Add toggle to group
          group.toggles.push(toggle)
        } else {
          // Create new group
          updateSwitches.push({
            name: groupName,
            order: index,
            toggles: [ toggle ],
          })
        }
      } else {
        // Add the toggle to the list
        updateSwitches.push(toggle)
      }
    })

    // Return the switches
    return updateSwitches
  }

  /**
   * isMemberOfGroup: Checks to see if the option or toggle is member of a widget that represents a group
   * of toggles
   * @param option The option you are checking
   * @returns True if the option is a member of a group
   */
  const isMemberOfGroup = (option: GraphQL.SuggestionListToggleInput): boolean => {
    // Check the code
    switch (option.name) {
      case AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE:
      case AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE:
      case AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL:
      case AUDIENCE_DISPLAY_TOGGLES.COUNTRIES:
      case AUDIENCE_DISPLAY_TOGGLES.STATES:
      case AUDIENCE_DISPLAY_TOGGLES.CITIES:
      case AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS:
      case AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES:
      case AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS:
      case AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES:
        return true
      default:
        return false
    }
  }

  /**
   * getGroupName: Retrieves the name of the group
   * @param option The option you are checking
   * @returns The name of the group
   */
  const getGroupName = (option: GraphQL.SuggestionListToggleInput): string => {
    // Check the code
    switch (option.name) {
      case AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE:
      case AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE:
      case AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL:
        return "Basic"
      case AUDIENCE_DISPLAY_TOGGLES.COUNTRIES:
      case AUDIENCE_DISPLAY_TOGGLES.STATES:
      case AUDIENCE_DISPLAY_TOGGLES.CITIES:
        return "Location"
      case AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS:
      case AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES:
      case AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS:
      case AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES:
        return "Vocation"
      default:
        return ""
    }
  }

  /**
   * isChecked: Checks to see if the toggle has been checked by checking
   * the update list form for the toggle name
   * @param option The toggle to check for
   * @returns True if the toggle exists in the update list form, otherwise false
   */
  const isChecked = (option: GraphQL.SuggestionListToggleInput) => (
    allToggles.some((toggle) => toggle.name === option.name)
  )

  /**
   * toggleSwitch: Checks or unchecks a switch based on the option being in the list
   * form toggles.
   * @param option The option being toggled
   */
  const toggleSwitch = (option: GraphQL.SuggestionListToggleInput) => {
    if (isChecked(option)) {
      // Get the toggle from all toggles
      const toggle = allToggles.find((t) => t.name === option.name)
      if (toggle) {
        // Remove the toggle
        allToggles.splice(allToggles.indexOf(toggle), 1)
      }
    } else {
      // Add the toggle
      allToggles.push(option)
    }

    // Make sure form exists
    if (updateListForm) {
      // Copy of form
      const form = { ...updateListForm }

      // Set the toggles
      form.toggles = allToggles

      // Save to update form
      dispatch(setUpdateListForm(form))
    }
  }

  /**
   * startDrag: This function runs when dragging an item begins
   * @param event The event for the drag
   * @param option The option being dragged
   */
  const startDrag = (
    event: React.DragEvent<HTMLDivElement>,
    option: AudienceGroup | GraphQL.SuggestionListToggleInput,
  ) => {
    // Set local state to indicate dragging
    setDragInfo({
      currentIndex: switches.indexOf(option),
      option,
    })

    // Pass to parent for drag end event processing
    setDraggingInfo(option)
  }

  /**
   * dragOverSwitch: This function runs when the drag item moves over the top of a droppable item
   * @param event The event of moving over drop zone
   * @param option The option we are over top of
   */
  const dragOverSwitch = (
    event: React.DragEvent<HTMLDivElement>,
    option: AudienceGroup | GraphQL.SuggestionListToggleInput,
  ) => {
    // Prevent default actions
    event.preventDefault()

    // Make sure this component is dragging something
    if (dragInfo) {
      // Get the index of the option
      const optionIndex = switches.indexOf(option)

      // Check to see if current index is different from option index
      if (dragInfo.currentIndex !== optionIndex) {
        // Move option to new location
        const updatedSwitches = [ ...switches ]
        updatedSwitches.splice(optionIndex, 0, ...updatedSwitches.splice(dragInfo.currentIndex, 1))

        // Set new drag information
        setDragInfo({
          ...dragInfo,
          currentIndex: optionIndex,
        })

        // Set the new switches
        setSwitches(updatedSwitches)
      }
    }
  }

  /**
   * dragOverWholeList: The drag over option for the whole list.  This helps to
   * ensure dropping between items will clear the drag state
   * @param event The event fired
   */
  const dragOverWholeList = (event: React.DragEvent<HTMLDivElement>) => {
    // Nothing to do, just prevent the default
    event.preventDefault()
  }

  /**
   * dropSwitch: This event is fired when the drag is relaeased and it
   * reorders and places the order in the update list for as well as in
   * the view.
   * @param event The event for dropping
   */
  const dropSwitch = (event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default action
    event.preventDefault()

    // Make sure we are dragging something
    const formToggles = [ ...allToggles ]
    if (dragInfo && updateListForm) {
      // Reset the drag info
      setDragInfo(undefined)

      // Get the form and toggle order
      const updatedToggleOrder: GraphQL.SuggestionListToggleInput[] = []

      // Update the switches and orders
      let counter = 0
      const updatedSwitches = switches.map((option) => {
        // Check to see if it's a switch or a group of switches
        const foundOption = toggleOrder.find((t) => t.name === option.name)
        if (foundOption) { // It's a switch
          const opt = {
            ...option as GraphQL.SuggestionListToggleInput,
            // eslint-disable-next-line no-plusplus
            order: counter++,
          }
          if (isChecked(opt)) {
            // Find the option
            const selectOpt = formToggles.find((t) => t.name === opt.name)
            if (selectOpt) {
              // Replace the option with new version
              formToggles.splice(formToggles.indexOf(selectOpt), 1, opt)
            }
          }
          updatedToggleOrder.push(opt)
          return opt
        }

        // Set new group
        const newGroup: AudienceGroup = option as AudienceGroup
        newGroup.order = counter

        // Update children toggles
        newGroup.toggles = newGroup.toggles.map((ntoggle) => {
          const opt = {
            ...ntoggle,
            // eslint-disable-next-line no-plusplus
            order: counter++,
          }
          if (isChecked(opt)) {
            // Find the option
            const selectOpt = formToggles.find((t) => t.name === opt.name)
            if (selectOpt) {
              // Replace the option with new version
              formToggles.splice(formToggles.indexOf(selectOpt), 1, opt)
            }
          }
          return opt
        })

        // Return the group in proper order
        updatedToggleOrder.push(...newGroup.toggles)
        return newGroup
      })

      // Update the form and switches
      setToggleOrder(updatedToggleOrder)
      setSwitches(updatedSwitches)

      // Update the form data
      const form = { ...updateListForm }

      // set the form toggles and update
      form.toggles = formToggles
      dispatch(setUpdateListForm(form))
    }
  }

  const updateGroupToggles = (toggles: GraphQL.SuggestionListToggleInput[]) => {
    // Loop through toggles and update toggle order
    const updatedToggleOrder = [ ...toggleOrder ]
    toggles.forEach((toggle) => {
      // Find the toggle in order
      const foundToggle = updatedToggleOrder.find((t) => t.name === toggle.name)
      if (foundToggle) {
        // Replace with new toggle
        updatedToggleOrder.splice(updatedToggleOrder.indexOf(foundToggle), 1, toggle)
      }
    })

    // Sort the updated toggles
    setToggleOrder(updatedToggleOrder.sort((t1, t2) => t1.order - t2.order))
  }

  /**
   * renderOption: Renders the option or group of options
   * @param option The option or group of options
   * @returns The React JSX Element for rendering
   */
  const renderOption = (option: AudienceGroup | GraphQL.SuggestionListToggleInput): React.JSX.Element => {
    const foundOption = toggleOrder.find((t) => t.name === option.name)
    if (foundOption) { // It is not a group
      // Set the toggle type
      const toggle = option as GraphQL.SuggestionListToggleInput

      return (
        <div
          className={
            `config-option${ (dragInfo && dragInfo.option.name === option.name)
              ? " dragging"
              : "" }`
          }
          draggable={ true }
          onDragStart={ (e) => { startDrag(e, toggle) } }
          onDragOver={ (e) => { dragOverSwitch(e, toggle) } }
          onDrop={ (e) => { dropSwitch(e) } }
        >
          <Switch
            className="config-option-switch"
            isChecked={ isChecked(toggle) }
            handleChange={ () => toggleSwitch(toggle) }
          />
          <span className="config-option-label">
            { translateCVH(getDisplayName(toggle.name, audienceDisplayCodes)) }
          </span>
          <DragIndicator className="config-option-dragicon" />
        </div>
      )
    }

    // Set the toggle type
    const toggle = option as AudienceGroup

    // Return the group
    return (
      <div
        className={
          `config-group-option${ (dragInfo && dragInfo.option.name === option.name)
            ? " dragging"
            : "" }`
        }
        onDrop={ (e) => { dropSwitch(e) } }
      >
        <div
          className="config-group-option-header"
          draggable={ true }
          onDragStart={ (e) => { startDrag(e, toggle) } }
          onDragOver={ (e) => { dragOverSwitch(e, toggle) } }
          onDrop={ (e) => { dropSwitch(e) } }
        >
          <p className="config-group-option-header-text">{ translateCVH(toggle.name) }</p>
          <DragIndicator className="config-group-option-header-dragicon" />
        </div>
        <ToggleGroup
          startIndex={ toggle.order }
          toggles={ toggle.toggles }
          updateToggles={ updateGroupToggles }
          setDraggingInfo={ setDraggingInfo }
          onDrop={ (e) => { dropSwitch(e) } }
        />
      </div>
    )
  }

  // Turn off dragging, if dragEnd event fired upstream
  if (!isDragging && dragInfo) {
    // Clear drag information
    setDragInfo(undefined)

    // Set the correct order
    const updateToggleOrder = toggleOrder.sort((t1, t2) => t1.order - t2.order)
    setToggleOrder(updateToggleOrder)
    setSwitches(buildAudienceSwitches(updateToggleOrder))
  }

  return (
    <div className="cp_component_audience-display-toggles" onDragOver={ dragOverWholeList } onDrop={ dropSwitch }>
      { switches.map((option) => renderOption(option)) }
    </div>
  )
}
