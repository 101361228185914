import React, { useEffect } from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { DeleteOutlineOutlined } from "@mui/icons-material"
import AttachmentIcon from "@mui/icons-material/Attachment"
import { useDispatch, useSelector } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import Checkbox from "../../Checkbox"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"
import ErrorHandler from "../../ErrorHandler"
import {
  deleteDraftMessage,
  fetchDraftMessage,
  resetDraftMessage,
  fetchDraftsList,
  resetListViewState,
} from "../../../state/commEmailListView"
import { CommunicationDraftData, Toast } from "../../../util/types"
import { formatTimestampToCustomDate, limitTextLength } from "../../../util/miscHelper"
import { openConfirmationModal } from "../../../state/confirmationModalSlice"
import { pushToast } from "../../../state/toastSlice"

// Create the message type
type DraftMessage = {
  id: string,
  handleName: string,
  date: string,
  subject: string,
  body: string,
  hasAttachment: boolean,
}

/**
 * The left hand view of the drafts folder that contains the draft message list
 * @returns The render objects
 */
export default function DraftsFolderView() {
  // ********************* Variables *********************
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsDraftList" })
  const dispatch = useDispatch()
  const { commGroupID } = useParams()

  // ********************* Redux state *********************
  const { drafts, draftMessage } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)

  // ********************* Local React state *********************
  const [ processedDraftMessages, setProcessedDraftMessages ] = React.useState<DraftMessage[]>([])
  const [ selectedDraftMessages, setSelectedDraftMessages ] = React.useState<string[]>([])

  // ********************* React hooks *********************
  /**
   * This loads all the draft messages whenever draft state changes or on mount
   * as well as parses the draft message in order to get required information for
   * the view.
   */
  useEffect(() => {
    // Check to see if drafts have been pulled or not
    if (drafts === "init") {
      // Retrieve the draft messages from database
      getDraftMessages()
    } else if (API.isSuccess(drafts)) {
      // Extract draft messages
      const { getCommunicationGroupDrafts: dms } = drafts.payload

      // Map the draft information into DraftMessage object for use in view
      const messages: DraftMessage[] = dms.map((message) => {
        // Parse the data into JSON
        const data = JSON.parse(message.data) as CommunicationDraftData

        // Get the HTML for the content of the message
        const element = new DOMParser().parseFromString(data.richTextEditorState.content, "text/html").body

        // Create the draft message object
        const dm: DraftMessage = {
          id: message.id,
          handleName: (data.toList.length > 0)
            ? data.toList.map((toUser) => toUser.socialAccountUsername || "No Username").join(", ")
            : "No Username",
          date: formatTimestampToCustomDate(message.created),
          subject: data.subject,
          body: (element.textContent) ? limitTextLength(element.textContent, 140) : "",
          hasAttachment: data.richTextEditorState.attachments.length > 0,
        }

        // Return draft message
        return dm
      })

      // Set the messages for the view
      setProcessedDraftMessages(messages)
    }

    // Clean up state before and after mount
    return clearState
  }, [ drafts ])

  // ********************* Functions *********************
  /**
   * Clear the local state values
   */
  const clearState = () => {
    setProcessedDraftMessages([])
    setSelectedDraftMessages([])
  }

  /**
   * Retrieve the list of draft messages for the communication group
   */
  const getDraftMessages = () => {
    // Make sure we have communication group id
    if (commGroupID) {
      // Query database for draft messages
      dispatch(fetchDraftsList({ communicationGroupID: commGroupID }))
    }
  }

  /**
   * Handle selecting or unselecting all the draft messages
   */
  const handleSelectUnselectAll = () => {
    // Unselect if everything is selected
    if (selectedDraftMessages.length === processedDraftMessages.length) {
      setSelectedDraftMessages([])
    } else { // select everything
      setSelectedDraftMessages(processedDraftMessages.map((draft) => draft.id))
    }
  }

  /**
   * Handle deleting selected draft messages
   */
  const handleDeleteSelectedDrafts = () => {
    // Delete the drafts
    dispatch(openConfirmationModal({
      onConfirm: () => {
        // Loop through draft message and remove
        selectedDraftMessages.forEach((draftId) => {
          // Delete the draft
          dispatch(deleteDraftMessage({
            vars: { communicationGroupDraftId: draftId },
            onSuccess: () => {
              const toast: Toast = {
                type: "success",
                message: translate("Successfully removed draft message."),
              }
              dispatch(pushToast(toast))
            },
            onError: () => {
              const toast: Toast = {
                type: "error",
                message: translate("Failed to remove draft message"),
              }
              dispatch(pushToast(toast))
            },
          }))
        })

        // Reset state
        setTimeout(() => {
          clearState()
          dispatch(resetListViewState())
          getDraftMessages()
        }, (selectedDraftMessages.length * 1000))
      },
      title: translate("Delete Message(s)"),
      subtext: (selectedDraftMessages.length === 1)
        ? translate("Are you sure you want to delete this message?")
        : translate("Are you sure you want to delete these messages?"),
      primaryLabel: translate("Yes"),
      secondaryLabel: translate("Cancel"),
    }))
  }

  /**
   * Handle selection toggling of the draft message
   * @param id The draft message ID
   */
  const handleSelectedDraftMessage = (id: string) => {
    const hasActiveDraft = draftMessage && API.isSuccess(draftMessage)

    if (hasActiveDraft) {
      const activeId = draftMessage.payload.getCommunicationGroupDraft.id

      if (activeId === id) {
        // User clicked the active draft again — reset
        dispatch(resetDraftMessage())
        selectDraftMessage(id)
        return
      }

      // User clicked a different draft — update selection
      const updatedIds = selectedDraftMessages.filter((currId) => currId !== activeId)
      setSelectedDraftMessages([ ...updatedIds, id ])
      dispatch(fetchDraftMessage({ id }))
      return
    }

    // No active draft — fetch new
    selectDraftMessage(id)
    dispatch(fetchDraftMessage({ id }))
  }

  /**
   * Handle deleting single draft message
   * @param id The draft message ID
   */
  const deleteDraftEmail = (id: string) => {
    // Delete draft email
    dispatch(openConfirmationModal({
      onConfirm: () => {
        // Delete the draft
        dispatch(deleteDraftMessage({
          vars: { communicationGroupDraftId: id },
          onSuccess: () => {
            const toast: Toast = {
              type: "success",
              message: translate("Successfully removed draft message."),
            }
            dispatch(pushToast(toast))
            clearState()
            dispatch(resetListViewState())
            getDraftMessages()
          },
          onError: () => {
            const toast: Toast = {
              type: "error",
              message: translate("Failed to remove draft message"),
            }
            dispatch(pushToast(toast))
          },
        }))
      },
      title: translate("Delete Message"),
      subtext: translate("Are you sure you want to delete this message?"),
      primaryLabel: translate("Yes"),
      secondaryLabel: translate("Cancel"),
    }))
  }

  /**
   * selectDraftMessage: Check or uncheck draft message
   * @param messageId The ID of the message
   */
  const selectDraftMessage = (messageId: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (selectedDraftMessages.includes(messageId)) {
      setSelectedDraftMessages(
        selectedDraftMessages.filter((id) => id !== messageId),
      )
    } else {
      setSelectedDraftMessages([ ...selectedDraftMessages, messageId ])
    }
    if (e) e.stopPropagation()
  }

  // ********************* Other *********************

  // If draft messages haven't been loaded, display loading card
  if (drafts === "init" || drafts === "loading") {
    return (
      <div className="cp_component-drafts-folder-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  // If draft message had an error during pull, display error
  if (drafts.status === "error") {
    return (
      <div className="cp_component-drafts-folder-view error">
        <ErrorHandler />
      </div>
    )
  }

  // Return JSX
  return (
    <div className="cp_component-drafts-folder-view">
      <section className="draft-messages-view-header">
        { processedDraftMessages.length > 0 && (
          <Checkbox
            onClick={ handleSelectUnselectAll }
            checked={ processedDraftMessages.length === selectedDraftMessages.length }
            indeterminate={ selectedDraftMessages.length > 0 && selectedDraftMessages.length < processedDraftMessages.length }
          />
        ) }
        <div className="draft-messages-view-header_actions">
          <DeleteOutlineOutlined
            className={ `actions-delete${ (processedDraftMessages.length === 0) ? " hidden" : "" }` }
            onClick={ handleDeleteSelectedDrafts }
          />
        </div>
      </section>
      <section className="draft-messages-view-messages">
        { processedDraftMessages.length === 0 && (
          <div className="draft-messages-view-messages_no-drafts">
            <p>{ translate("There are no drafts for this communication group.") }</p>
          </div>
        ) }
        { processedDraftMessages.map((draft, index) => (
          <div
            className={ `draft-message${ selectedDraftMessages.includes(draft.id) ? " selected" : "" }` }
            id={ `draft-message${ selectedDraftMessages.includes(draft.id) ? "-selected" : "" }` }
            key={ draft.id }
            onClick={ () => handleSelectedDraftMessage(draft.id) }
            onKeyUp={ (event) => {
              if (event.key === "Enter" || event.key === " ") {
                handleSelectedDraftMessage(draft.id)
              }
            } }
            role="button"
            tabIndex={ 0 }
          >
            <div className="draft-message_selector">
              <Checkbox
                id={ `email-cb-${ index }` }
                checked={ selectedDraftMessages.includes(draft.id) }
                onClick={ (e) => selectDraftMessage(draft.id, e) }
              />
            </div>
            <div className="draft-message_content">
              <div className="draft-message_content_top">
                <p className="handle-name">
                  <div className="handle-name_constant">
                    { translate("Draft ") }
                  </div>
                  { draft.handleName }
                </p>
                <div className="draft-message_content_top-right">
                  <DeleteOutlineOutlined
                    className="delete-draft-message-icon"
                    onClick={ () => deleteDraftEmail(draft.id) }
                  />
                  { draft.hasAttachment && (
                    <div className="draft-message-has-attachment"><AttachmentIcon className="email-attachment-icon" /></div>
                  ) }
                  <p className="date">{ draft.date }</p>
                </div>
              </div>
              <p className="draft-message_content_subject">{ draft.subject }</p>
              <p className="draft-message_content_body">{ draft.body }</p>
            </div>
          </div>
        )) }
      </section>
    </div>
  )
}
