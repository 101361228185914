import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom"
import "./communications-body.sass"

import { EditOutlined, Link as LinkIcon } from "@mui/icons-material"
import { Skeleton } from "@mui/material"
import dayjs from "dayjs"
import SearchBar from "../SearchBar"
import { useSelector, useDispatch } from "../../state/hooks"
import { Scope } from "../../util/types"
import ErrorHandler from "../ErrorHandler"
import * as Constant from "../../util/constant"
import IconButton from "../IconButton"
import CommunicationsEmailListView from "./CommunicationsEmailListView"
import { setEditCommGroupID, setCommGroupModalOpen } from "../../state/ModalCommGroupSlice"
import { resetListViewState, setConversationQuery } from "../../state/commEmailListView"
import CommunicationMessages from "./CommunicationMessages"
import CommunicationsBatchListView from "./CommunicationsBatchListView"
import Button from "../Button"
// import CommunicationsDraftListView from "./CommunicationsDraftListView"
// import DraftEditPanelView from "./DraftEditPanelView"
import DraftsFolderView from "./Drafts"
import DraftMessageEditPanel from "./Drafts/DraftEditPanel"
import { isError, isSuccess } from "../../util/apiClient"

export default function CommunicationsBody() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  const [ clearInput, setClearInput ] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { vanity, commGroupID } = useParams()
  const [ searchParams ] = useSearchParams()
  const isInboxActive = location.pathname.includes("conversations")
  const isSentActive = location.pathname.includes("sent")
  const isBatchActive = location.pathname.includes("batch")
  const isDraftsActive = location.pathname.includes("drafts")
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)
  const { communicationGroup } = useSelector(({ communicationsPage }) => communicationsPage)
  const { conversationQuery } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const created = useMemo((): number | undefined => {
    if (isSuccess(communicationGroup)) {
      return communicationGroup.payload.communicationGroup.created
    }
    return undefined
  }, [ communicationGroup ])

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setConversationQuery(e.target.value))
  }, [])

  // Clear the search input if the conversationQuery if it's null
  // for example when there is a change in the conversationFilter
  useEffect(() => {
    if (!conversationQuery) {
      setClearInput(true)
    } else {
      setClearInput(false)
    }
  }, [ conversationQuery ])

  if (isError(communicationGroup)) {
    return <ErrorHandler />
  }

  const onEditClick = () => {
    if (commGroupID) {
      dispatch(setEditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const navigateBackToBatch = () => {
    dispatch(resetListViewState())
    navigate(`/${ vanity }/communications/group/${ commGroupID }/batch`)
  }

  return (
    <div className="cp_component_communications-body-main-container">
      <div className={ `header-section ${ isDraftsActive ? "is-draft" : "" }` }>
        { (created)
          ? (!isDraftsActive)
            ? (
              <SearchBar
                className="search-bar"
                onChange={ handleInputChange }
                clearInput={ clearInput }
              />
            )
            : null
          : <Skeleton variant="rectangular" width={ 320 } height={ 40 } />
        }
        <div className="details-container">
          <p className="created-label">
            { (created)
              ? `${ translate("Created") } ${ dayjs(created * 1000)
                .format(Constant.MONTH_DAY_ABBR_FORMAT) }`
              : <Skeleton variant="text" width={ 100 } />
            }
          </p>
          <div className="campaign-page-button">
            { (created)
              ? (
                <>
                  <LinkIcon />
                  <p>{ translate("Campaign") }</p>
                </>
              )
              : <Skeleton variant="text" width={ 70 } />
            }
          </div>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <IconButton variant="outlined" onClick={ (created) ? onEditClick : undefined }>
              { (created)
                ? <EditOutlined />
                : <Skeleton variant="rectangular" width={ 18 } height={ 18 } />
              }
            </IconButton>
          ) }
        </div>
      </div>
      { isBatchActive && searchParams.size > 0 && (
        <div id="breadcrumbs-container" className="cp_component_breadcrumbs-container">
          <Button
            className="back-to-batch-btn"
            isTextButton={ true }
            label={ translate("< Back to Batch View") }
            onClick={ navigateBackToBatch }
          />
        </div>
      ) }
      { isInboxActive && (
      <div id="cp_inbox-communications" className="communications-body-wrapper">
        <CommunicationsEmailListView />
        <CommunicationMessages />
      </div>
      ) }
      { isSentActive && (
      <div id="cp_sent-communications" className="communications-body-wrapper">
        <CommunicationsEmailListView conversationFilter="sent" />
        <CommunicationMessages />
      </div>
      ) }
      { isBatchActive && (
      <div id="cp_batch-communications" className="communications-body-wrapper">
        { searchParams.size > 0 && (
        <>
          <CommunicationsEmailListView conversationFilter="batch" batchId={ searchParams.get("batch_id") } />
          <CommunicationMessages />
        </>
        ) }
        { searchParams.size === 0 && (
        <>
          <CommunicationsBatchListView />
          <CommunicationMessages isBatch={ true } />
        </>
        ) }
      </div>
      ) }
      { isDraftsActive && (
      <div id="cp_drafts-communications" className="communications-body-wrapper">
        <DraftsFolderView />
        <DraftMessageEditPanel />
      </div>
      ) }
      { !isInboxActive && !isSentActive && !isBatchActive && !isDraftsActive && ( // TODO: Add the other views
      <div className="communications-body-wrapper">
        <div className="communications-email-conversation-detail">Placeholder</div>
      </div>
      ) }
    </div>
  )
}
