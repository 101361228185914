import React from "react"
import { useTranslation } from "react-i18next"

import LoadingIndicator from "../../../LoadingIndicator"
import PublicBrands from "./PublicBrands"
import { GetPublicMentionedPostsQueryVariables, PublicGetMentionedSocialAccountsQueryVariables } from "../../../../graphql"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  fetchDetectedBrands,
  fetchMentionedBrands,
  fetchWorkedWithBrands,
  getMentionedPosts,
  getMoreMentionedPosts,
} from "../../../../state/publicListSlice"

export type FetchPublicBrandParams = Omit<PublicGetMentionedSocialAccountsQueryVariables, "mentionType">

type Props = {
  displayBrandsMentionedWorked: boolean
  displayBrandsDetected: boolean
}

export default function BrandsWrapper({ displayBrandsDetected, displayBrandsMentionedWorked }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const dispatch = useDispatch()
  const {
    list,
    selectedListSocialAccount,
    detectedBrands,
    mentionedBrands,
    workedWithBrands,
    mentionedPostsContent,
    mentionedPostsStatus,
  } = useSelector(({ publicList }) => publicList)

  if (list === "loading" || list === "init" || !selectedListSocialAccount) {
    return (
      <div>
        <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
      </div>
    )
  }
  if (list.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <PublicBrands
      socialProfile={ selectedListSocialAccount.socialAccount }
      detectedBrands={
        detectedBrands.find((mention) => mention.socialAccountId === selectedListSocialAccount.socialAccount.id)?.data
      }
      mentionedBrands={
        mentionedBrands.find((mention) => mention.socialAccountId === selectedListSocialAccount.socialAccount.id)?.data
      }
      workedWithBrands={
        workedWithBrands.find((mention) => mention.socialAccountId === selectedListSocialAccount.socialAccount.id)?.data
      }
      fetchDetectedBrands={ (params: FetchPublicBrandParams) => dispatch(fetchDetectedBrands(params)) }
      fetchMentionedBrands={ (params: FetchPublicBrandParams) => dispatch(fetchMentionedBrands(params)) }
      fetchWorkedWithBrands={ (params: FetchPublicBrandParams) => dispatch(fetchWorkedWithBrands(params)) }
      mentionedPostsStatus={ mentionedPostsStatus }
      mentionedPostsContent={ mentionedPostsContent }
      displayBrandsDetected={ displayBrandsDetected }
      displayBrandsMentionedWorked={ displayBrandsMentionedWorked }
      getMentionedPosts={ (params: GetPublicMentionedPostsQueryVariables) => dispatch(getMentionedPosts(params)) }
      getMoreMentionedPosts={ (params: GetPublicMentionedPostsQueryVariables) => dispatch(getMoreMentionedPosts(params)) }
    />
  )
}
