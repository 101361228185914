import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as API from "../../../util/apiClient"
import AccountInsightsDetailsToggleWrapper from "./AccountInsightDetails/AccountInsightDetailsToggleWrapper"
import AccountAudienceDetailsToggleWrapper from "./AccountAudienceDetails/AccountAudienceDetailsToggleWrapper"
import AccountContentWrapper from "./AccountContentWrapper"
import {
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
} from "../../../util/constant"
import { fetchListSocialProfile, fetchListSocialProfileToggles } from "../../../state/listSocialProfileSlice"
import { useDispatch, useSelector } from "../../../state/hooks"
import "./style.sass"
import Divider from "../../Divider"
import { ToggleFragment } from "../../../graphql"

type Props = {
  toggles: ToggleFragment[]
}

export default function AccountAppendixWrapper({ toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountAppendix" })
  const dispatch = useDispatch()
  const {
    list,
    loadedListSocialAccount,
  } = useSelector(({ publicList }) => publicList)

  useEffect(() => {
    if (API.isSuccess(list) && API.isSuccess(loadedListSocialAccount)) {
      const { socialAccount } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
      dispatch(fetchListSocialProfile({
        listId: list.payload.publicSuggestionListByCode.id,
        socialAccountId: socialAccount.id,
      }))
      dispatch(fetchListSocialProfileToggles({
        listId: list.payload.publicSuggestionListByCode.id,
        socialAccountId: socialAccount.id,
      }))
    }
  }, [ list, loadedListSocialAccount ])

  const toggleCodes = React.useMemo(() => {
    if (toggles) {
      return toggles.map((toggle) => toggle.name)
    }
    return []
  }, [ toggles ])

  const checkDisplayByToggles = (
    toggleSet: Record<string, string>,
  ): boolean => {
    const ALL_TOGGLE_VALUES = new Set(Object.values(toggleSet))
    return toggleCodes.some((toggle) => ALL_TOGGLE_VALUES.has(toggle))
  }

  return (
    <div className="cp_account-appendix-wrapper">
      { checkDisplayByToggles(APPENDIX_INSIGHTS_DISPLAY_TOGGLES) && (
        <>
          <h2>{ translate("Account Insights") }</h2>
          <Divider />
          <AccountInsightsDetailsToggleWrapper isAppendixTab={ true } />
        </>
      ) }
      { checkDisplayByToggles(APPENDIX_AUDIENCE_DISPLAY_TOGGLES) && (
      <>
        <h2>{ translate("Audience") }</h2>
        <Divider />
        <AccountAudienceDetailsToggleWrapper isAppendixTab={ true } toggles={ toggles } />
      </>
      ) }
      { checkDisplayByToggles(APPENDIX_CONTENT_DISPLAY_TOGGLES) && (
      <>
        <h2>{ translate("Content") }</h2>
        <Divider className="last-divider" />
        <AccountContentWrapper isAppendixTab={ true } />
      </>
      ) }
    </div>
  )
}
