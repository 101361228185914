import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Pill from "../../../Pill"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  Metric,
  populateState,
  setUpdateListForm,
} from "../../../../state/listConfigurationSlice"
import ContentAdditionalFields from "../../ContentAdditionalFields"
import Divider from "../../../Divider"
import { appendixMetrics as metrics, contentTitleMetrics } from "../../constants"
import { isSuccess } from "../../../../util/apiClient"

import "./style.sass"
import { SuggestionListToggleInput } from "../../../../graphql"

export default function AppendixDisplay() {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  const { updateListForm } = useSelector((state) => state.listConfiguration)
  const list = useSelector((state) => state.list.list)

  React.useEffect(() => {
    if (isSuccess(list)) {
      dispatch(populateState())
    }
  }, [ list ])

  const activeMetrics = useMemo<SuggestionListToggleInput[]>(() => {
    if (!updateListForm?.toggles) return []

    return Array.isArray(updateListForm.toggles)
      ? updateListForm.toggles
      : [ updateListForm.toggles ]
  }, [ updateListForm?.toggles ])

  const accountInsightMetrics: Metric[] = React.useMemo(() => {
    const accountInsightsGroup = metrics.find(
      (group) => group.groupName === "Account Insights",
    )

    if (!accountInsightsGroup) return []

    return accountInsightsGroup.metrics.filter((metric) => activeMetrics.some((toggle) => toggle.name === metric.code))
  }, [ metrics, activeMetrics ])

  const audienceMetrics: Metric[] = React.useMemo(() => {
    const audienceGroup = metrics.find(
      (group) => group.groupName === "Audience Metrics",
    )

    if (!audienceGroup) return []

    return audienceGroup.metrics.filter((metric) => activeMetrics.some((toggle) => toggle.name === metric.code))
  }, [ metrics, activeMetrics ])

  const contentMetrics: Metric[] = React.useMemo(() => {
    const contentGroup = metrics.find(
      (group) => group.groupName === "Content",
    )

    if (!contentGroup) return []

    return contentGroup.metrics.filter((metric) => activeMetrics.some((toggle) => toggle.name === metric.code))
  }, [ metrics, activeMetrics ])

  /**
   * deleteAppendixsMetric: Removes the metrics from global state
   * @param metric The metric to be removed
   */
  const deleteAppendixsMetric = (metric: Metric) => {
    // Check if update list form exists
    if (updateListForm) {
      // Remove the metric from the update list form
      const listForm = { ...updateListForm }
      listForm.toggles = (Array.isArray(listForm.toggles))
        ? listForm.toggles.filter((toggle) => toggle.name !== metric.code)
        : [ listForm.toggles ].filter((toggle) => toggle.name !== metric.code)
      dispatch(setUpdateListForm(listForm))
    }
  }

  return (
    <div className="cp_lcv-accordion-details_display">
      { (accountInsightMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Account Insights") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { accountInsightMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteAppendixsMetric(metric) } }
              />
            )) }
          </div>
        </div>
      ) }
      { (audienceMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Audience Metrics") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { audienceMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteAppendixsMetric(metric) } }
              />
            )) }
          </div>
        </div>
      ) }
      { (contentMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Content") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { contentMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteAppendixsMetric(metric) } }
              />
            )) }
          </div>
          { contentMetrics.some((item) => item.name === contentTitleMetrics.RELEVANT_POSTS) && (
            <>
              <Divider />
              <ContentAdditionalFields />
            </>
          ) }
        </div>
      ) }
    </div>
  )
}
