import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"

import * as API from "../../util/apiClient"
import CustomersOverviewDataGrid from "./CustomersOverviewDataGrid"
import SearchBar from "../SearchBar"
import Toggle, { ToggleMenuValue } from "../Toggle"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  getCustomers,
  getCustomersByUser,
  getMoreCustomers,
  getMoreCustomersByUser,
  setSearchInput,
  setSearchToggle,
} from "../../state/customersPageSlice"

import "./style.sass"

export default function CustomersOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomersOverview" })
  const dispatch = useDispatch()
  const {
    customersStatus,
    customersContent,
    customersByUserStatus,
    sortDirection,
    searchToggle,
    searchInput,
    columnSort,
  } = useSelector(({ customers }) => customers)

  const [ page, setPage ] = useState(1)

  const setSearchInputCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setSearchInput(e.target.value)),
    [],
  )

  useEffect(() => {
    if (searchToggle === "customer") {
      dispatch(getCustomers({
        startsWith: searchInput,
        column: columnSort,
        direction: sortDirection,
        page: 1,
        limit: 50,
      }))
    } else {
      dispatch(getCustomersByUser({
        startsWith: searchInput,
        column: columnSort,
        direction: sortDirection,
        page: 1,
        limit: 50,
      }))
    }
    setPage(1)
  }, [ searchInput, searchToggle, sortDirection, columnSort ])

  const toggleOptions: ToggleMenuValue[] = useMemo(
    () => [
      {
        value: "customer", label: translate("Customer"), keyId: uuidv4(),
      },
      {
        value: "user", label: translate("User"), keyId: uuidv4(),
      },
    ],
    [ translate ],
  )

  const fetchMore = useMemo(() => {
    if (searchToggle === "customer" && API.isSuccess(customersStatus)) {
      return async () => {
        const nextPage = page + 1
        await dispatch(getMoreCustomers({
          startsWith: searchInput,
          column: columnSort,
          direction: sortDirection,
          limit: 50,
          page: nextPage,
        }))
        setPage(nextPage)
      }
    }
    if (searchToggle === "user" && API.isSuccess(customersByUserStatus)) {
      return async () => {
        const nextPage = page + 1
        await dispatch(getMoreCustomersByUser({
          startsWith: searchInput,
          column: columnSort,
          direction: sortDirection,
          limit: 50,
          page: nextPage,
        }))
        setPage(nextPage)
      }
    }
    return () => {}
  }, [ searchToggle, page, customersStatus, customersByUserStatus, columnSort, sortDirection ])

  return (
    <div id="cp_customers-overview_container" className="cp_customers-overview_container">
      <div className="cp_customers-overview-header">
        <div className="cp_customers-overview-header-search">
          <SearchBar
            id="cp_customers-overview_search-bar"
            className="cp_customers-overview_search-bar"
            searchPlaceholderText={ translate("Search") }
            onChange={ setSearchInputCallback }
            lastSubmittedSearch={ searchInput }
          />
          <Toggle
            id="cp_customers-overview_toggle"
            toggleOptions={ toggleOptions }
            value={ searchToggle }
            setValue={ (option) => dispatch(setSearchToggle(option)) }
          />
        </div>
      </div>
      <CustomersOverviewDataGrid
        customers={ customersContent }
        fetchMore={ fetchMore }
        isLoading={ customersStatus === "loading" || customersByUserStatus === "loading" }
      />
    </div>
  )
}
