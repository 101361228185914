import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { debounce } from "@mui/material"
import * as API from "../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import Input, { Props as InputProps } from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import { Affinity } from "../../constants"
import Autocomplete from "../../../Autocomplete"
import { Network } from "../../../../graphql"

interface Props {
  setSelectedAffinities: (affinities: Affinity[]) => void
  selectedAffinities: Affinity[],
  placeholder: string,
  notFoundLabel: string,
  network?: Network,
}

function AffinitiesAutocomplete({
  setSelectedAffinities,
  selectedAffinities,
  placeholder,
  notFoundLabel,
  network,
}:Props) {
  const [ affinityTags, setAffinityTags ] = useState<Affinity[]>([])

  // React hook to get all the options
  const options = React.useMemo((): Affinity[] => (
    affinityTags.sort().filter((affinity) => !selectedAffinities.map((selAff) => selAff.name).includes(affinity.name))
  ), [ affinityTags, selectedAffinities ])

  // Affinities
  const searchAffinities = async (startsWith: string) => {
    const result = await API.fetchAffinitiesForList(startsWith, network)

    if (API.isSuccess(result)) {
      setAffinityTags(result.payload.searchAffinities.rows)
    } else {
      setAffinityTags([ {
        id: "",
        name: notFoundLabel,
        code: "",
      } ])
    }
  }

  const debouncedSearchAffinities = useCallback(debounce((e, inputValue) => {
    searchAffinities(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchAffinities ])

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    arAffinity: NonNullable<string | Affinity> | (string | Affinity)[] | null,
  ) => {
    if (!arAffinity) return
    if (typeof arAffinity === "string") {
      setSelectedAffinities([ {
        name: arAffinity, id: "", code: "",
      } ])
    } else if (!Array.isArray(arAffinity)) {
      setSelectedAffinities([ arAffinity ])
    } else {
      const cleanedAffinities = arAffinity.map((affinity) => {
        if (typeof affinity === "string") {
          return {
            name: affinity, id: "", code: "",
          }
        }
        return affinity
      })
      setSelectedAffinities(cleanedAffinities)
    }
  }

  useEffect(() => {
    searchAffinities("")
  }, [])

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedAffinities }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (affinity) => {
      // Value selected with enter, right from the input
        if (typeof affinity === "string") return affinity
        return affinity.name
      } }
      ListboxProps={ {
        className: "cp_component_autocomplete_tags-list",
      } }
      onInputChange={ debouncedSearchAffinities }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="affinities-autocomplete"
      options={ options }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, { name, id }, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ id }
            id={ `affinities-autocomplete-${ name.toLowerCase().replaceAll(" ", "_") }` }
          >
            { name }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ placeholder }
        />
      )
}
    />
  )
}

export default AffinitiesAutocomplete
