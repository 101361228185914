import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { debounce } from "@mui/material"
import * as GraphQL from "../../../../graphql"
import * as API from "../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import Input, { Props as InputProps } from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import Autocomplete from "../../../Autocomplete"

interface Props {
  setSelectedLocations: (locations: GraphQL.AudienceLocation[]) => void
  selectedLocations: GraphQL.AudienceLocation[],
  placeholder: string,
  notFoundLabel: string,
}

function LocationAutocomplete({
  setSelectedLocations,
  selectedLocations,
  placeholder,
  notFoundLabel,
}:Props) {
  const [ locationTags, setLocationTags ] = useState<GraphQL.AudienceLocation[]>([])

  // React hook tu get all the options
  const options = React.useMemo((): GraphQL.AudienceLocation[] => (
    locationTags.sort().filter((location) => !selectedLocations.map((selLoc) => selLoc.name).includes(location.name))
  ), [ locationTags, selectedLocations ])

  // Locations
  const searchLocations = async (startsWith: string) => {
    const result = await API.fetchAudienceLocations(startsWith)

    if (API.isSuccess(result)) {
      setLocationTags(result.payload.searchAudienceLocations.rows)
    } else {
      setLocationTags([ {
        code: "", name: notFoundLabel, type: GraphQL.AudienceLocationType.City,
      } ])
    }
  }

  const debouncedSearchLocals = useCallback(debounce((e, inputValue) => {
    searchLocations(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchLocations ])

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    value: NonNullable<string | GraphQL.AudienceLocation> | (string | GraphQL.AudienceLocation)[] | null,
  ) => {
    if (!value) return
    if (typeof value === "string") {
      setSelectedLocations([ {
        name: value, type: GraphQL.AudienceLocationType.City, code: "",
      } ])
    } else if (!Array.isArray(value)) {
      setSelectedLocations([ value ])
    } else {
      const cleanedLocals = value.map((local) => {
        if (typeof local === "string") {
          return {
            name: local, type: GraphQL.AudienceLocationType.City, code: "",
          }
        }
        return local
      })
      setSelectedLocations(cleanedLocals)
    }
  }

  useEffect(() => {
    searchLocations("")
  }, [])

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedLocations }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (location) => {
      // Value selected with enter, right from the input
        if (typeof location === "string") return location
        return location.name
      } }
      ListboxProps={ {
        className: "cp_component_autocomplete_tags-list",
      } }
      onInputChange={ debouncedSearchLocals }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="location-autocomplete"
      options={ options }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, { name, type }, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ name }
            id={ `locations-autocomplete-${ name.toLowerCase().replaceAll(" ", "_") }` }
          >
            { `${ type }: ${ name }` }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ placeholder }
        />
      )
}
    />
  )
}

export default LocationAutocomplete
