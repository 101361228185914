import React, { useCallback, useEffect } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as API from "../../../util/apiClient"
import * as Constant from "../../../util/constant"
import EntityInfoRow from "../../EntityInfoRow"
import LoadingIndicator from "../../LoadingIndicator"
import ModalAddTo from "../../ModalAddTo"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  getComms,
  getCommsForAccount,
  setAddToModalPhase,
  toggleSelectedComm,
} from "../../../state/socialProfileSlice"

type Props = {
    onSubmit: () => {}
}

export default function ModalAddToCommunications({ onSubmit }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const {
    comms,
    commsForAccount,
    selectedCommIDs,
    selectedSocialAccountID,
    selectedSocialAccountNetwork,
    addToModalPhase: modalPhase,
  } = useSelector(({ socialProfile }) => socialProfile)

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedSocialAccountNetwork) {
      dispatch(getCommsForAccount(selectedSocialAccountID, [ selectedSocialAccountNetwork ]))
    }
  }, [])

  const fetchData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(getComms(e.target.value)),
    [],
  )

  const renderContent = () => {
    if (comms === "init" || comms === "loading" || commsForAccount === "init" || commsForAccount === "loading") {
      return (
        <LoadingIndicator flexWrapperEnabled={ true } size={ 20 } />
      )
    } if (API.isSuccess(comms) && API.isSuccess(commsForAccount)) {
      const idsAlreadyAdded = commsForAccount.payload.searchCommunicationGroupsByNetworkAccountIds.rows.map((row) => row.id)
      return comms.payload.searchCommunicationGroup.rows.map((row, index) => {
        const id = `comms-group-${ index }`
        const alreadyAdded = idsAlreadyAdded.includes(row.id)
        const checked = alreadyAdded || selectedCommIDs.includes(row.id)
        const disabled = alreadyAdded || (!checked && selectedCommIDs.length >= 10)
        return (
          <EntityInfoRow
            id={ id }
            key={ row.id }
            onClick={ () => {
              if (!disabled) dispatch(toggleSelectedComm(row.id))
            } }
            checkboxEnabled={ true }
            checkboxDisabled={ disabled }
            checkboxValue={ checked }
            name={ row.name }
            subInfo={
              `${ row.creator.username } | ${ dayjs(row.created, "X").format(Constant.LONGFORM_DATE) }`
            }
          />
        )
      })
    }
    return <p className="cp_component-modal-add-to-error">Error!</p>
  }

  return (
    <ModalAddTo
      title={ translate("Add To Communication") }
      subtitle={ translate("Select the communication group that you would like to add the selected account to.") }
      selectedEntityCount={ selectedCommIDs.length }
      open={ modalPhase === 2 }
      closeAction={ () => dispatch(setAddToModalPhase(null)) }
      primaryAction={ onSubmit }
      fetchData={ fetchData }
      disabled={ selectedCommIDs.length >= 10 }
    >
      { renderContent() }
    </ModalAddTo>
  )
}
