import React from "react"
import "./style.sass"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"

import { useDispatch, useSelector } from "../../../../state/hooks"
import Input from "../../../Input"
import { setUpdateListForm, updateList } from "../../../../state/listConfigurationSlice"
import Pill from "../../../Pill"
import Button from "../../../Button"
import ImageTagsAutocomplete from "../../../ListFormModal/ImageTagsAutocomplete"
import { pushToast } from "../../../../state/toastSlice"
import { fetchList } from "../../../../state/listSlice"

export default function RelevantPostsForm() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()
  const { listID } = useParams()

  const updateListForm = useSelector((state) => state.listConfiguration.updateListForm)

  const [ expanded, setExpanded ] = React.useState<boolean>(true)
  const [ saved, setSaved ] = React.useState<boolean>(false)
  const [ relevantPostsKeyword, setRelevantPostsKeyword ] = React.useState<string>("")
  const [ keywords, setKeywords ] = React.useState<string[]>([])
  const [ imageTags, setImageTags ] = React.useState<string[]>([])

  React.useEffect(() => {
    // Make sure list form has been populated
    if (updateListForm) {
      // Check to see if there are keywords and/or image tags saved
      if (updateListForm.keywords.length > 0 || updateListForm.imageTags.length > 0) {
        setSaved(true)
        setExpanded(false)
      }
    }
  }, [ ])

  React.useEffect(() => {
    // Make sure list form has been populated
    if (updateListForm) {
      // Update the keywords and image tags
      setKeywords((Array.isArray(updateListForm.keywords)) ? updateListForm.keywords : [ updateListForm.keywords ])
      setImageTags((Array.isArray(updateListForm.imageTags)) ? updateListForm.imageTags : [ updateListForm.imageTags ])
    }
  }, [ updateListForm ])

  // Event handler Keywords
  const handleAddKeywords = (): void => {
    // Split the input by commas, trim whitespace, and filter out any empty strings
    const newKeywords = relevantPostsKeyword.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)

    const filteredKeywords = newKeywords
      .filter((keyword) => !keywords.includes(keyword))

    if (filteredKeywords.length > 0) {
      // Save to update list form
      if (updateListForm) {
        const listForm = { ...updateListForm }
        listForm.keywords = [ ...keywords, ...filteredKeywords ]
        dispatch(setUpdateListForm(listForm))
      }

      // Set the keywords
      setKeywords([ ...keywords, ...filteredKeywords ])
    }

    setRelevantPostsKeyword("") // Clear the input field after adding keywords
  }

  const handleKeywordKeyDown = (event: React.KeyboardEvent): void => {
    // When the user presses Enter, add the keywords
    if (event.key === "Enter") {
      event.preventDefault() // Prevent form submission and other default actions
      handleAddKeywords()
    }
  }

  const handleRemoveKeywords = (tagName: string): void => {
    const filteredKeywords = keywords.filter((tag: string) => (tag !== tagName))

    // Update list form
    if (updateListForm) {
      // Create copy of list form
      const listForm = { ...updateListForm }

      // Update the list form values
      listForm.keywords = (Array.isArray(listForm.keywords))
        ? listForm.keywords.filter((tag) => tag !== tagName)
        : (listForm.keywords === tagName) ? [] : [ listForm.keywords ]

      // Update the form
      dispatch(setUpdateListForm(listForm))
    }

    // Update local form
    setKeywords(filteredKeywords)
  }

  const renderKeywordPills = (): React.JSX.Element[] => keywords.map((tag) => (
    <Pill
      key={ tag }
      label={ tag }
      onDelete={ () => handleRemoveKeywords(tag) }
      deleteIcon={ <CloseIcon /> }
    />
  ))

  const handleImageTags = (tagName: string): void => {
    const filterImageTags = imageTags.filter((tag) => tag !== tagName)

    // Update list form
    if (updateListForm) {
      // Create copy of list form
      const listForm = { ...updateListForm }

      // Update the list form values
      listForm.imageTags = (Array.isArray(listForm.imageTags))
        ? listForm.imageTags.filter((tag) => tag !== tagName)
        : (listForm.imageTags === tagName) ? [] : [ listForm.imageTags ]

      // Update the form
      dispatch(setUpdateListForm(listForm))
    }

    // Update local form
    setKeywords(filterImageTags)
  }

  const setSelectedImageTags = (tags: string[]): void => {
    // Update the form
    if (updateListForm) {
      const listForm = { ...updateListForm }
      listForm.imageTags = tags
      dispatch(setUpdateListForm(listForm))
    }

    // Set the image tags
    setImageTags(tags)
  }

  const renderImageTagPills = (): React.JSX.Element[] => imageTags.map((tag) => (
    <Pill
      key={ tag }
      label={ tag }
      onDelete={ () => handleImageTags(tag) }
      deleteIcon={ <CloseIcon /> }
    />
  ))

  const handleRelevantPostsFormPersistence = () => {
    // Make the call
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully save relevant posts form!"),
        }))
        setSaved(true)
        setExpanded(false)
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: translate("Unable to save relevant posts form. Please try again later!"),
        }))
      },
    }))
  }

  return (
    <Accordion
      onChange={ () => setExpanded(!expanded) }
      className="highlights-relevant-posts-form"
      elevation={ 0 }
      id="highlights-relevant-posts-form"
      defaultExpanded={ true }
      expanded={ expanded }
    >
      <AccordionSummary
        className="highlights-relevant-posts-form-summary"
        expandIcon={ expanded
          ? <KeyboardArrowUp className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
          : <KeyboardArrowRight className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
        }
        aria-controls="panel1a-content"
        id="highlights-relevancy-header"
      >
        <h3 className={ `highlights-relevant-posts-form-summary-heading${ (saved) ? " dsf-saved" : " dsf-not-saved" }` }>
          { translate("Relevant Posts*") }
        </h3>
      </AccordionSummary>
      <AccordionDetails className="highlights-relevant-posts-form-details">
        <div className="relevant-post-form">
          <div className="relevant-post-form_keywords">
            <FormControl
              className="keywords-form-control"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend" className="section-label">
                { translate("Relevant Posts") }
              </FormLabel>
              <Input
                className="create-list-long-description"
                value={ relevantPostsKeyword }
                placeholder={ translate("Keywords") }
                onKeyDown={ handleKeywordKeyDown }
                onChange={ (e) => setRelevantPostsKeyword(e.currentTarget.value) }
                fullWidth={ true }
                label={ translate("Keywords") }
              />
              <div className="config-option-switch-pills">
                {
                  renderKeywordPills()
                }
              </div>
            </FormControl>
          </div>
          <div className="relevant-post-form_image-tags">
            <ImageTagsAutocomplete
              setSelectedImageTags={ setSelectedImageTags }
              selectedImageTags={ imageTags }
              inputLabel={ translate("Image Tags") }
              notFoundLabel={ translate("Image Tag not found") }
            />
            <div className="config-option-switch-pills">
              {
                renderImageTagPills()
              }
            </div>
          </div>
        </div>
        <div className="relevant-post-form-cta">
          <Button
            id="relevant-post-form-save-button"
            label={ translate("Save") }
            isPrimary={ true }
            fullWidth={ true }
            onClick={ handleRelevantPostsFormPersistence }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
