import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../../../util/apiClient"
import ModalAddTo from "../../../../../ModalAddTo"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import {
  fetchCustomersForManageModal,
  setManageModalCustomersOpen,
  updateClients,
} from "../../../../../../state/campaignSlice"
import LoadingIndicatorCard from "../../../../../LoadingIndicatorCard"
import ErrorHandler from "../../../../../ErrorHandler"
import EntityInfoRow from "../../../../../EntityInfoRow"
import { isSuccess } from "../../../../../../util/apiClient"

export default function ModalManageClients() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalManageClients" })
  const {
    campaign,
    manageModalCustomers,
    manageModalCustomersOpen,
  } = useSelector(({ campaignPage }) => campaignPage)
  const [ selectedCustomerIds, setSelectedCustomerIds ] = useState<Array<string>>([])
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const dispatch = useDispatch()

  const fetchData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(fetchCustomersForManageModal(e.target.value))
    },
    [],
  )

  useEffect(() => {
    if (manageModalCustomersOpen && isSuccess(campaign)) {
      dispatch(fetchCustomersForManageModal(""))
      setSelectedCustomerIds(campaign.payload.campaign.customers.map(({ id }) => id))
    }
  }, [ manageModalCustomersOpen ])

  const onSubmit = async () => {
    setSubmitLoading(true)
    await dispatch(updateClients(selectedCustomerIds))
    dispatch(setManageModalCustomersOpen(false))
    setSubmitLoading(false)
  }

  const toggleSelectedCustomer = (id: string) => {
    const indexOfID = selectedCustomerIds.indexOf(id)
    // Remove if already selected
    if (indexOfID !== -1) {
      const newIDs = [ ...selectedCustomerIds ]
      newIDs.splice(indexOfID, 1)
      setSelectedCustomerIds(newIDs)
    } else if (selectedCustomerIds.length < 10) {
      setSelectedCustomerIds([ ...selectedCustomerIds, id ])
    }
  }

  const renderContent = () => {
    if (manageModalCustomers === "init" || manageModalCustomers === "loading" || campaign === "init" || campaign === "loading") {
      return <LoadingIndicatorCard />
    }
    if (API.isError(manageModalCustomers) || API.isError(campaign)) {
      if (API.isError(manageModalCustomers)) {
        return <ErrorHandler err={ manageModalCustomers } />
      }

      if (API.isError(campaign)) {
        return <ErrorHandler err={ campaign } />
      }
    }
    return (
      manageModalCustomers.payload.searchCustomers.rows.map((row) => {
        const checked = selectedCustomerIds.includes(row.id)
        const disabled = (!checked && selectedCustomerIds.length >= 10)
        return (
          <EntityInfoRow
            key={ row.id }
            onClick={ () => {
              if (!disabled) toggleSelectedCustomer(row.id)
            } }
            checkboxEnabled={ true }
            checkboxDisabled={ disabled }
            checkboxValue={ checked }
            name={ row.company?.name || "" }
            avatarSrc={ row.company?.logo?.url.address }
          />
        )
      })
    )
  }

  return (
    <ModalAddTo
      title={ translate("Manage Clients") }
      subtitle={ translate("Select the Clients that you would like to be a part of this campaign.") }
      open={ manageModalCustomersOpen }
      closeAction={ () => dispatch(setManageModalCustomersOpen(false)) }
      primaryAction={ onSubmit }
      fetchData={ fetchData }
      selectedEntityCount={ selectedCustomerIds.length }
      primaryLabel={ translate("Update") }
      submitLoading={ submitLoading }
      searchPlaceholderText={ translate("Search Client Name") }
    >
      { renderContent() }
    </ModalAddTo>
  )
}
