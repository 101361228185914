import React from "react"
import { useTranslation } from "react-i18next"

import ListSuggestionSocialContent from "./ListSuggestionSocialContent"
import LoadingIndicator from "../../LoadingIndicator"
import { useSelector } from "../../../state/hooks"

type Props = {
  isAppendixTab?: boolean
}

export default function AccountContentWrapper({ isAppendixTab }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountAudience" })
  const { listProfileToggles } = useSelector(({ listSocialProfile }) => listSocialProfile)

  if (listProfileToggles === "loading" || listProfileToggles === "init") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />
    )
  }
  if (listProfileToggles.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <ListSuggestionSocialContent
      toggles={ listProfileToggles.payload.suggestionListSocialAccountByListIdSocialAccountId.suggestionList.toggles }
      isAppendixTab={ isAppendixTab }
    />
  )
}
