import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  MenuItem,
  debounce,
} from "@mui/material"

import * as API from "../../../../util/apiClient"
import * as GraphQL from "../../../../graphql"
import AvatarBrand from "../../../AvatarBrand"
import Input from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import PublicModalBrandDetails from "./PublicModalBrandDetails"
import Select from "../../../Select"
import Tabs, { TabLabelProps } from "../../../Tabs"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import { FetchPublicBrandParams } from "./BrandsWrapper"
import { Status } from "../../../../util/types"

import "../../AccountInsights/brands.sass"

const allTime = undefined
const oneYearAgo = `${ dayjs().subtract(1, "year").valueOf() }`
const sixMonthsAgo = `${ dayjs().subtract(6, "month").valueOf() }`

const brandsContentHelper = (
  translate: TFunction,
  onClick: (mention: GraphQL.MentionFragment) => void,
  brands?: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>,
) => {
  if (!brands || brands === "init" || brands === "loading") {
    return <LoadingIndicator className="cp_brands_component-content-loading" size={ 30 } />
  }
  if (API.isError(brands) || brands.payload.publicGetMentionedSocialAccounts.rows.length === 0) {
    return (
      <p className="cp_brands_component-content-no-results">
        { translate("There are no results to display") }
      </p>
    )
  }
  return brands.payload.publicGetMentionedSocialAccounts.rows.map((mention) => (
    <div key={ mention.brandId }>
      <AvatarBrand
        onClick={ () => { onClick(mention) } }
        pictureUrl={ mention.socialAccount.profilePictureUrl }
        timesMentioned={ mention.timesMentioned }
      />
    </div>
  ))
}

export type FetchBrandParams = {
  networkAccountId: string,
  limit: number,
  startTimestamp?: string,
  filter?: string
}

type Props = {
  socialProfile: GraphQL.SocialProfileFragment,
  mentionedBrands?: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>,
  workedWithBrands?: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>,
  detectedBrands?: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>,
  fetchMentionedBrands: (params: FetchPublicBrandParams) => Promise<void>,
  fetchWorkedWithBrands: (params: FetchPublicBrandParams) => Promise<void>,
  fetchDetectedBrands: (params: FetchPublicBrandParams) => Promise<void>,
  mentionedPostsStatus: Status<GraphQL.GetPublicMentionedPostsQuery>,
  mentionedPostsContent: Array<GraphQL.MentionedPostFragment>,
  getMentionedPosts: (params: GraphQL.GetPublicMentionedPostsQueryVariables) => void
  getMoreMentionedPosts: (params: GraphQL.GetPublicMentionedPostsQueryVariables) => void
  displayBrandsMentionedWorked?: boolean
  displayBrandsDetected?: boolean
}

export default function PublicBrands({
  socialProfile,
  mentionedBrands,
  workedWithBrands,
  detectedBrands,
  fetchDetectedBrands,
  fetchMentionedBrands,
  fetchWorkedWithBrands,
  mentionedPostsContent,
  mentionedPostsStatus,
  getMentionedPosts,
  getMoreMentionedPosts,
  displayBrandsDetected = true,
  displayBrandsMentionedWorked = true,
}: Props) {
  const [ timestamp, setTimestamp ] = useState<undefined | string>(allTime)
  const [ filter, setFilter ] = useState<undefined | string>()

  const [ selectedTab, selectTab ] = useState(GraphQL.MentionType.Brand)
  const [ selectedMention, setSelectedMention ] = useState<GraphQL.MentionFragment>()
  const [ brandDetailsModalOpen, setBrandDetailsModalOpen ] = useState<boolean>(false)

  const { listCode } = useParams()

  const { t: translate } = useTranslation([], { keyPrefix: "component.Brands" })

  const onAvatarClick = (mention: GraphQL.MentionFragment) => {
    setSelectedMention(mention)
    setBrandDetailsModalOpen(true)
  }

  const fetchBrands = async () => {
    if (listCode) {
      const params: FetchPublicBrandParams = {
        code: listCode,
        networkAccountId: socialProfile.id,
        limit: 100,
        startTimestamp: timestamp,
        filter,
      }

      switch (selectedTab) {
        case GraphQL.MentionType.Brand:
          await fetchMentionedBrands(params)
          break
        case GraphQL.MentionType.BrandWorkedWith:
          await fetchWorkedWithBrands(params)
          break
        case GraphQL.MentionType.BrandLogoDetection:
          await fetchDetectedBrands(params)
          break
        default:
      }
    }
  }

  useEffect(() => {
    fetchBrands()
  }, [ socialProfile, timestamp, filter, selectedTab ])

  const mentionedBrandAvatars = brandsContentHelper(translate, onAvatarClick, mentionedBrands)

  const workedWithBrandAvatars = brandsContentHelper(translate, onAvatarClick, workedWithBrands)

  const detectedBrandAvatars = brandsContentHelper(translate, onAvatarClick, detectedBrands)

  const [ tabs, mentionTypes ]: [TabLabelProps[], GraphQL.MentionType[]] = useMemo(
    () => {
      const newTabs = []
      const newMentionTypes = []
      if (displayBrandsMentionedWorked) {
        newTabs.push({ label: translate("Mentioned") })
        newTabs.push({ label: translate("Worked With") })
        newMentionTypes.push(GraphQL.MentionType.Brand)
        newMentionTypes.push(GraphQL.MentionType.BrandWorkedWith)
      }
      if (displayBrandsDetected) {
        newTabs.push({ label: translate("Detected") })
        newMentionTypes.push(GraphQL.MentionType.BrandLogoDetection)
      }
      return [ newTabs, newMentionTypes ]
    },
    [ translate ],
  )

  const timestampOptions = useMemo(() => {
    const options = [ {
      name: translate("All Time"),
      value: allTime,
    }, {
      name: translate("Last Year"),
      value: oneYearAgo,
    }, {
      name: translate("Last 6 Months"),
      value: sixMonthsAgo,
    } ]
    return options.map(({ name, value }) => (
      <MenuItem
        key={ name }
        onClick={ () => setTimestamp(value) }
        value={ value }
        className="cp_brands_component-date-item"
      >
        <ListItemText>{ name }</ListItemText>
      </MenuItem>
    ))
  }, [])

  const handleSelectTab = (tabIndex: number) => {
    selectTab(mentionTypes[tabIndex])
  }

  const handleFilterChange = debounce((value: string) => {
    setFilter(value)
  }, DEFAULT_DEBOUNCE_WAIT)

  return (
    <div className="cp_brands_component">
      <div className="cp_brands_component-tabs-container">
        <Tabs
          handleChange={ handleSelectTab }
          tabs={ tabs }
        />
        <div className="cp_brands_component-tabs-controls">
          <Select
            id="select-brands-date"
            label=""
            labelId="select-brands-date"
            menuItems={ timestampOptions }
            value={ timestamp }
            displayEmpty={ true }
            className="cp_brands_component-date"
          />
          <Input
            placeholder="Search"
            className="cp_brands_component-filter"
            value={ filter }
            onChange={ (e) => handleFilterChange(e.currentTarget.value) }
          />
        </div>
        <div className="cp_brands_component-content">
          { selectedTab === GraphQL.MentionType.Brand && mentionedBrandAvatars }
          { selectedTab === GraphQL.MentionType.BrandWorkedWith && workedWithBrandAvatars }
          { selectedTab === GraphQL.MentionType.BrandLogoDetection && detectedBrandAvatars }
        </div>
      </div>

      <PublicModalBrandDetails
        open={ brandDetailsModalOpen }
        onClose={ () => setBrandDetailsModalOpen(false) }
        mention={ selectedMention }
        mentionType={ selectedTab }
        mentionedPostsContent={ mentionedPostsContent }
        mentionedPostsStatus={ mentionedPostsStatus }
        socialProfile={ socialProfile }
        getMentionedPosts={ getMentionedPosts }
        getMoreMentionedPosts={ getMoreMentionedPosts }
      />
    </div>
  )
}
