import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import EmptySplash from "../../../EmptySplash"
import "./style.sass"
import CampaignAdsTable from "./Tables/CampaignAdsTable"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ErrorHandler from "../../../ErrorHandler"
import * as API from "../../../../util/apiClient"
import {
  fetchAllAdCampaigns,
  fetchCampaign,
  fetchCampaignAdCampaigns,
  linkAdCampaignToCampaign,
  unlinkAdCampaignsToCampaign,
} from "../../../../state/campaignSlice"
import Button from "../../../Button"
import SearchBar from "../../../SearchBar"
import LoadingIndicator from "../../../LoadingIndicator"

export default function CampaignAdsDetails() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignAds" })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    allAdCampaigns, campaignAdCampaigns, campaign,
  } = useSelector(({ campaignPage }) => campaignPage)

  const [ searchInput, setSearchInput ] = useState("")
  const [ selectedAllAdIds, setSelectedAllAdIds ] = useState<string[]>([])
  const [ selectedCampaginAdIds, setSelectedCampaginAdIds ] = useState<string[]>([])

  const [ linkAdCampaignLoading, setLinkAdCampaignLoading ] = useState(false)
  const [ unlinkAdCampaignLoading, setUnlinkAdCampaignLoading ] = useState(false)

  useEffect(() => {
    dispatch(fetchAllAdCampaigns({
      contains: searchInput,
    }))
  }, [ searchInput ])

  useEffect(() => {
    if (API.isSuccess(campaign)) {
      const { id } = campaign.payload.campaign
      dispatch(fetchCampaignAdCampaigns({
        campaignId: id,
      }))
    }
  }, [ campaign ])

  const linkSelectedAdCampaigns = async () => {
    if (API.isSuccess(campaign)) {
      setLinkAdCampaignLoading(true)
      const { id: campaignId } = campaign.payload.campaign
      await dispatch(linkAdCampaignToCampaign({
        variables: {
          campaignId,
          adCampaignIds: selectedAllAdIds,
        },
        onSuccess: () => dispatch(fetchCampaign({ campaignId })),
      }))
      setSelectedCampaginAdIds([])
      setLinkAdCampaignLoading(false)
    }
  }

  const unlinkSelectedAdCampaigns = async () => {
    if (API.isSuccess(campaign)) {
      setUnlinkAdCampaignLoading(true)
      const { id: campaignId } = campaign.payload.campaign
      await dispatch(unlinkAdCampaignsToCampaign({
        variables: {
          campaignId,
          adCampaignIds: selectedCampaginAdIds,
        },
        onSuccess: () => dispatch(fetchCampaign({ campaignId })),
      }))
      setSelectedCampaginAdIds([])
      setUnlinkAdCampaignLoading(false)
    }
  }

  const setInputCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value), [])

  const renderCampaignAdsTable = () => {
    if (campaignAdCampaigns === "init" || campaignAdCampaigns === "loading") {
      return <LoadingIndicatorCard />
    }

    if (API.isError(campaignAdCampaigns)) {
      return <ErrorHandler err={ campaignAdCampaigns } />
    }

    const {
      rows: campaignAdCampaignRows,
    } = campaignAdCampaigns.payload.searchAdCampaigns

    return (
      <>
        <CampaignAdsTable
          setSelectedIds={ setSelectedCampaginAdIds }
          rows={ campaignAdCampaignRows }
          selectedIds={ selectedCampaginAdIds }
          onRowClick={ (row) => navigate(`${ row.id }`, { relative: "path" }) }
        />
        { campaignAdCampaignRows.length === 0 && <EmptySplash bodyText={ translate("Add an Ad Campaign Below") } /> }
      </>
    )
  }

  const renderAllAdsTable = () => {
    if (
      campaignAdCampaigns === "init"
      || campaignAdCampaigns === "loading"
      || allAdCampaigns === "init"
      || allAdCampaigns === "loading"
    ) {
      return <LoadingIndicatorCard />
    }

    if (allAdCampaigns.status === "error" || campaignAdCampaigns.status === "error") {
      if (API.isError(allAdCampaigns)) {
        return <ErrorHandler err={ allAdCampaigns } />
      }

      if (API.isError(campaignAdCampaigns)) {
        return <ErrorHandler err={ campaignAdCampaigns } />
      }
    }

    const {
      rows: campaignAdCampaignRows,
    } = campaignAdCampaigns.payload.searchAdCampaigns

    const {
      rows: allAdCampaignRows,
    } = allAdCampaigns.payload.searchAdCampaigns

    const campaignAdIds = campaignAdCampaignRows.map(({ id }) => id)

    const filteredAdCampaigns = allAdCampaignRows.filter((row) => !campaignAdIds.some((id) => id === row.id))

    return (
      <CampaignAdsTable
        setSelectedIds={ setSelectedAllAdIds }
        rows={ filteredAdCampaigns }
        selectedIds={ selectedAllAdIds }
      />
    )
  }

  return (
    <div className="cp_component-campaign-ads-container">
      <div className="table-header-container campaign-ads">
        <h5 className="table-header">{ translate("Connected Ad Campaigns") }</h5>
        <div className="action-container">
          <Button
            className="button"
            disabled={ selectedCampaginAdIds.length === 0 || unlinkAdCampaignLoading }
            isPrimary={ false }
            label={ unlinkAdCampaignLoading ? <LoadingIndicator /> : translate("Disconnect Ad Campaign") }
            onClick={ unlinkSelectedAdCampaigns }
            size="small"
          />
        </div>
      </div>
      { (renderCampaignAdsTable()) }

      <div className="table-header-container all-ads">
        <h5 className="table-header">{ translate("All Ad Campaigns") }</h5>
        <div className="action-container">
          <SearchBar
            className="search-bar"
            onChange={ setInputCallback }
            lastSubmittedSearch={ searchInput }
            searchPlaceholderText={ translate("Search All Ad Campaigns") }
          />
          <Button
            className="button"
            disabled={ selectedAllAdIds.length === 0 || linkAdCampaignLoading }
            label={ linkAdCampaignLoading ? <LoadingIndicator /> : translate("Connect Ad Campaigns") }
            onClick={ linkSelectedAdCampaigns }
            size="small"
            variant="contained"
          />
        </div>
      </div>
      { (renderAllAdsTable()) }
    </div>
  )
}
