import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import Vocation from "../../Audience/Vocation"
import "../../Audience/index.sass"
import { SuggestionListToggle } from "../../../../graphql"

type Props = {
  toggles?: Omit<SuggestionListToggle, "id">[]
  displayOccupations: boolean
  displayIndustries: boolean
  displayEmployers: boolean
  displayUniversities: boolean
}

export default function VocationWrapper({
  toggles, displayOccupations, displayEmployers, displayIndustries, displayUniversities,
}:Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.Vocation" })
  const { audience, listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)
  if (audience === "loading" || audience === "init" || listProfile === "loading" || listProfile === "init") {
    return (
      <>
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Vocation") }
        </h6>
        <LoadingIndicatorCard />
      </>
    )
  }
  if (audience.status === "error" || listProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <Vocation
      toggles={ toggles }
      audienceDemographics={ audience.payload.socialAccount.audienceDemographics }
      socialProfile={ listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount }
      displayOccupations={ displayOccupations }
      displayIndustries={ displayIndustries }
      displayEmployers={ displayEmployers }
      displayUniversities={ displayUniversities }
    />
  )
}
