import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { debounce } from "@mui/material"
import * as API from "../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import Input, { Props as InputProps } from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import Autocomplete from "../../../Autocomplete"
import { Occupation } from "../../constants"

interface Props {
  setSelectedOccupations: (occupations: Occupation[]) => void
  selectedOccupations: Occupation[],
  placeholder: string,
  notFoundLabel: string,
}

function OccupationsAutocomplete({
  setSelectedOccupations,
  selectedOccupations,
  placeholder,
  notFoundLabel,
}:Props) {
  const [ occupationTags, setOccupationTags ] = useState<Occupation[]>([])

  // React hook to get all the options
  const options = React.useMemo((): Occupation[] => (
    occupationTags.sort().filter((occupation) => !selectedOccupations.map((selOcc) => selOcc.name).includes(occupation.name))
  ), [ occupationTags, selectedOccupations ])

  // Occupations
  const searchOccupations = async (startsWith: string) => {
    const result = await API.fetchOccupationsForDemographicScore(startsWith)

    if (API.isSuccess(result)) {
      setOccupationTags(result.payload.searchOccupations.rows)
    } else {
      setOccupationTags([ {
        id: "", name: notFoundLabel, code: "",
      } ])
    }
  }

  const debouncedSearchOccupations = useCallback(debounce((e, inputValue) => {
    searchOccupations(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchOccupations ])

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    occupations: NonNullable<string | Occupation> | (string | Occupation)[] | null,
  ) => {
    if (!occupations) return
    if (typeof occupations === "string") {
      setSelectedOccupations([ {
        name: occupations, id: "", code: "",
      } ])
    } else if (!Array.isArray(occupations)) {
      setSelectedOccupations([ occupations ])
    } else {
      const cleanedOccupations = occupations.map((occupation) => {
        if (typeof occupation === "string") {
          return {
            name: occupation, id: "", code: "",
          }
        }
        return occupation
      })
      setSelectedOccupations(cleanedOccupations)
    }
  }

  useEffect(() => {
    searchOccupations("")
  }, [])

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedOccupations }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (occupation) => {
      // Value selected with enter, right from the input
        if (typeof occupation === "string") return occupation
        return occupation.name
      } }
      ListboxProps={ {
        className: "cp_component_autocomplete_tags-list",
      } }
      onInputChange={ debouncedSearchOccupations }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="occupations-autocomplete"
      options={ options }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, { name, id }, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ id }
            id={ `occupations-autocomplete-${ name.toLowerCase().replaceAll(" ", "_") }` }
          >
            { name }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ placeholder }
        />
      )
}
    />
  )
}

export default OccupationsAutocomplete
