import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../../../util/apiClient"
import ModalAddTo from "../../../../../ModalAddTo"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import {
  fetchCampaignManagersForManageModal,
  setManageModalCampaignManagersOpen,
  updateCampaignManagers,
} from "../../../../../../state/campaignSlice"
import LoadingIndicatorCard from "../../../../../LoadingIndicatorCard"
import ErrorHandler from "../../../../../ErrorHandler"
import EntityInfoRow from "../../../../../EntityInfoRow"
import { isSuccess } from "../../../../../../util/apiClient"

export default function ModalManageManagers() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalManageManagers" })
  const {
    campaign,
    manageModalCampaignManagersOpen: modalOpen,
    manageModalCampaignManagers,
  } = useSelector(({ campaignPage }) => campaignPage)
  const [ selectedManagerIds, setSelectedManagerIds ] = useState<Array<string>>([])
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const dispatch = useDispatch()

  const fetchData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(fetchCampaignManagersForManageModal(e.target.value)),
    [],
  )

  useEffect(() => {
    if (modalOpen && isSuccess(campaign)) {
      dispatch(fetchCampaignManagersForManageModal(""))
      setSelectedManagerIds(campaign.payload.campaign.subscribedUsers.map(({ id }) => id))
    }
  }, [ modalOpen ])

  const onSubmit = async () => {
    setSubmitLoading(true)
    await dispatch(updateCampaignManagers(selectedManagerIds))
    dispatch(setManageModalCampaignManagersOpen(false))
    setSubmitLoading(false)
  }

  const toggleSelectedCustomer = (id: string) => {
    const indexOfID = selectedManagerIds.indexOf(id)
    // Remove if already selected
    if (indexOfID !== -1) {
      const newIDs = [ ...selectedManagerIds ]
      newIDs.splice(indexOfID, 1)
      setSelectedManagerIds(newIDs)
    } else if (selectedManagerIds.length < 10) {
      setSelectedManagerIds([ ...selectedManagerIds, id ])
    }
  }

  const renderContent = () => {
    if (
      manageModalCampaignManagers === "init"
      || manageModalCampaignManagers === "loading"
      || campaign === "init"
      || campaign === "loading"
    ) {
      return <LoadingIndicatorCard />
    }

    if (API.isError(manageModalCampaignManagers) || API.isError(campaign)) {
      if (API.isError(manageModalCampaignManagers)) {
        return <ErrorHandler err={ manageModalCampaignManagers } />
      }

      if (API.isError(campaign)) {
        return <ErrorHandler err={ campaign } />
      }
    }

    return (
      manageModalCampaignManagers.payload.searchUsers.rows.map((row) => {
        const checked = selectedManagerIds.includes(row.id)
        const disabled = (!checked && selectedManagerIds.length >= 10)
        return (
          <EntityInfoRow
            key={ row.id }
            onClick={ () => {
              if (!disabled) toggleSelectedCustomer(row.id)
            } }
            checkboxEnabled={ true }
            checkboxDisabled={ disabled }
            checkboxValue={ checked }
            name={ row.contact.name }
            avatarSrc={ row.contact.avatar.url.address }
          />
        )
      })
    )
  }

  return (
    <ModalAddTo
      title={ translate("Manage Campaign Managers") }
      // eslint-disable-next-line max-len
      subtitle={ translate("Select the Campaign Managers that you would like to be a part of this campaign. Campaign Managers will be notified of updates to this campaign.") }
      open={ modalOpen }
      closeAction={ () => dispatch(setManageModalCampaignManagersOpen(false)) }
      primaryAction={ onSubmit }
      fetchData={ fetchData }
      selectedEntityCount={ selectedManagerIds.length }
      primaryLabel={ translate("Update") }
      submitLoading={ submitLoading }
    >
      { renderContent() }
    </ModalAddTo>
  )
}
