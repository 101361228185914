import React, {
  JSX,
  useCallback,
  useEffect,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import "./communicationsSlidingPanel.sass"
import SearchBar from "../../../SearchBar"
import "./templatesContent.sass"
import LoadingIndicator from "../../../LoadingIndicator"
import * as API from "../../../../util/apiClient"
import { getMoreTemplates, getTemplates } from "../../../../state/slidingPanelSlice/communications"
import { setTemplatesPage, setTemplatesSearchInput } from "../../../../state/slidingPanelSlice"
import DataGrid from "../../../DataGrid"
import EmptyElement from "../../../EmptyElement"
import { openCommsCreateTemplate } from "../../../../state/commsCreateEditTemplateModal"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { CommunicationTemplateFragment } from "../../../../graphql"

function TemplatesContent(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })
  const dispatch = useDispatch()

  const {
    templatesContent, templatesStatus, templatesSearchInput, moreTemplatesLoading, templatesPage,
  } = useSelector(({ slidingPanels }) => slidingPanels)

  const setSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setTemplatesSearchInput(e.target.value)),
    [],
  )

  useEffect(() => {
    dispatch(getTemplates(templatesSearchInput, 1))
    dispatch(setTemplatesPage(1))
  }, [ templatesSearchInput ])

  const COLUMNS: GridColDef[] = [
    {
      field: "name",
      headerName: translate("Name"),
      sortable: true,
      renderCell: (params: { row: CommunicationTemplateFragment}) => (
        <Typography id="cp_component_template-table-header" className="from-label-cell">
          { params.row.name }
        </Typography>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "description",
      headerName: translate("Description"),
      sortable: false,
      renderCell: (params: { row: CommunicationTemplateFragment}) => (
        <Typography className="from-label-cell">{ params.row.description }</Typography>
      ),
      disableColumnMenu: true,
      flex: 1,
    },
  ]

  const renderContent = () => {
    if (templatesStatus === "init" || templatesStatus === "loading") {
      return (
        <LoadingIndicator flexWrapperEnabled={ true } />
      )
    }
    if (API.isSuccess(templatesStatus)) {
      return (
        <DataGrid
          className="cp_component_comms-table"
          rowHeight={ 110 }
          columnHeaderHeight={ 40 }
          columns={ COLUMNS }
          disableColumnReorder={ true }
          onRowClick={ (params) => {
            const template: CommunicationTemplateFragment = params.row
            dispatch(openCommsCreateTemplate({ template }))
          } }
          hideFooter={ true }
          rows={ templatesContent.map((row) => (row)) }
          onRowsScrollEnd={ () => {
            if (templatesStatus.payload.searchCommunicationTemplates.totalCount > templatesContent.length) {
              dispatch(getMoreTemplates(templatesSearchInput, templatesPage + 1))
              dispatch(setTemplatesPage(templatesPage + 1))
            }
          } }
          loading={ moreTemplatesLoading }
          scrollEndThreshold={ 200 }
          slots={ {
            loadingOverlay: LinearProgress,
            noRowsOverlay: EmptyElement,
            noResultsOverlay: EmptyElement,
          } }
          rowSelection={ false }
        />
      )
    }
    return <Typography>Error!</Typography>
  }

  return (
    <>
      <SearchBar
        onChange={ setSearch }
        lastSubmittedSearch={ templatesSearchInput }
      />
      <Container className="cp_component_navigation-bar-templates-container">
        { renderContent() }
      </Container>
    </>
  )
}

export default TemplatesContent
