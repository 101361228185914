import React from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import ListInfoCard from "../ListInfoCard"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector } from "../../state/hooks"

import "./style.sass"
import ListPublicGroupHeaderCard from "../ListPublicGroupHeaderCard"

export default function PublicListGroupOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListGroupOverview" })
  const { listGroup: fetchedListGroup } = useSelector(({ publicListGroup }) => publicListGroup)

  const [ numberOfLists, numberOfAccounts, numberOfFollowers ] = React.useMemo(() => {
    let totalLists = 0
    let totalAccounts = 0
    let totalFollowers = 0

    if (
      fetchedListGroup === "init"
      || fetchedListGroup === "loading"
      || fetchedListGroup.status === "error"
    ) {
      return [ totalAccounts, totalAccounts, totalFollowers ]
    }

    const {
      suggestionLists,
    } = fetchedListGroup.payload.publicSuggestionListGroupByCode

    suggestionLists.forEach((l) => {
      totalLists += 1
      l.suggestionListCategories.forEach((category) => { totalAccounts += category.suggestionListSocialAccounts.length })
      totalFollowers += parseInt(l.reach, 10)
    })
    return [ totalLists, totalAccounts, totalFollowers ]
  }, [ fetchedListGroup ])

  if (fetchedListGroup === "init" || fetchedListGroup === "loading") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
    )
  }
  if (API.isError(fetchedListGroup)) {
    return (
      <div>
        <p>
          { translate("Unable to fetch list group.") }
        </p>
      </div>
    )
  }

  const {
    name,
    suggestionLists,
  } = fetchedListGroup.payload.publicSuggestionListGroupByCode

  return (
    <div className="cp_component_public-list-group-overview">
      <ListPublicGroupHeaderCard
        name={ name }
        numberOfAccounts={ numberOfAccounts }
        numberOfFollowers={ numberOfFollowers }
        numberOfLists={ numberOfLists }
      />
      <div className="cp_component_public-list-group-overview-content">
        { suggestionLists.map((l) => {
          let listNumberOfAccounts = 0
          l.suggestionListCategories
            .forEach((category) => { listNumberOfAccounts += category.suggestionListSocialAccounts.length })
          return (
            <ListInfoCard
              minAge={ l.minAge }
              maxAge={ l.maxAge }
              sexes={ l.sexes }
              description={ l.description }
              listName={ l.name }
              numberOfAccounts={ listNumberOfAccounts }
              platform={ l.network }
              reach={ parseInt(l.reach, 10) }
              publicUrl={ l.publicUrl }
              toggles={ l.toggles }
            />
          )
        }) }
      </div>
    </div>
  )
}
