import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import BasicChart from "../../Audience/BasicChart"
import { ToggleFragment } from "../../../../graphql"

type Props = {
  toggles?: ToggleFragment[]
  displayAgeRange: boolean
  displayIncomeRange: boolean
  displayEducationLevel: boolean
}

export default function BasicChartWrapper({
  toggles, displayAgeRange, displayEducationLevel, displayIncomeRange,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.BasicChart" })
  const {
    audience,
  } = useSelector(({ publicList }) => publicList)
  if (audience === "loading" || audience === "init") {
    return (
      <LoadingIndicatorCard />
    )
  }
  if (audience.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <BasicChart
      toggles={ toggles }
      audienceDemographics={ audience.payload.publicSocialAccount.audienceDemographics }
      displayAgeRange={ displayAgeRange }
      displayIncomeRange={ displayIncomeRange }
      displayEducationLevel={ displayEducationLevel }
    />
  )
}
