import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import SearchIcon from "@mui/icons-material/Search"

import "./vocation.sass"

import { LinearProgress } from "@mui/material"
import Tabs from "../../Tabs"
import Input from "../../Input"
import { AudienceDemographicValue, ToggleFragment } from "../../../graphql"
import { prettyPrintDecimal, shorthandNumber } from "../../../util/miscHelper"
import Tooltip from "../../Tooltip"
import * as GraphQL from "../../../graphql"
import { AUDIENCE_DISPLAY_TOGGLES } from "../../../util/constant"

type TabValue = "occupation" | "industry" | "employer" | "university"

type Props = {
  toggles?: ToggleFragment[]
  audienceDemographics: GraphQL.AudienceDemographicsFragment | undefined | null
  socialProfile: GraphQL.SocialProfileFragment
  displayOccupations?: boolean
  displayIndustries?: boolean
  displayEmployers?: boolean
  displayUniversities?: boolean
}

export default function Vocation({
  toggles,
  audienceDemographics,
  socialProfile,
  displayOccupations = true,
  displayEmployers = true,
  displayIndustries = true,
  displayUniversities = true,
}: Props) {
  const [ query, setQuery ] = React.useState("")
  const [ occupationGroups, setOccupationGroups ] = React.useState<AudienceDemographicValue[]>([])
  const [ industryGroups, setIndustryGroups ] = React.useState<AudienceDemographicValue[]>([])
  const [ employerGroups, setEmployerGroups ] = React.useState<AudienceDemographicValue[]>([])
  const [ universityGroups, setUniversityGroups ] = React.useState<AudienceDemographicValue[]>([])
  const [ tabIndex, setTabIndex ] = React.useState<number>(0)
  const { t: translate } = useTranslation([], { keyPrefix: "component.Vocation" })

  const [ tabs, tabValues ] = useMemo(() => {
    const newTabs = (toggles)
      ? toggles.map((toggle) => {
        switch (toggle.name) {
          case AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS:
            return { label: translate("Occupation") }
          case AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES:
            return { label: translate("Industry") }
          case AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS:
            return { label: translate("Employer") }
          default:
            return { label: translate("University") }
        }
      })
      : []
    const newTabValues: TabValue[] = (toggles)
      ? toggles.map((toggle) => {
        switch (toggle.name) {
          case AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS:
            return "occupation"
          case AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES:
            return "industry"
          case AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS:
            return "employer"
          default:
            return "university"
        }
      })
      : []
    if (!toggles) {
      if (displayOccupations) {
        newTabs.push({ label: translate("Occupation") })
        newTabValues.push("occupation")
      }
      if (displayIndustries) {
        newTabs.push({ label: translate("Industry") })
        newTabValues.push("industry")
      }
      if (displayEmployers) {
        newTabs.push({ label: translate("Employer") })
        newTabValues.push("employer")
      }
      if (displayUniversities) {
        newTabs.push({ label: translate("University") })
        newTabValues.push("university")
      }
    }
    return [ newTabs, newTabValues ]
  }, [ displayOccupations, displayIndustries, displayEmployers, displayUniversities ])

  useEffect(() => {
    setTabIndex(0)
  }, [])

  useEffect(() => {
    const newOccupationGroups: AudienceDemographicValue[] = (
      audienceDemographics?.occupationGroups
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

    const newIndustryGroups: AudienceDemographicValue[] = (
      audienceDemographics?.industryGroups
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

    const newEmployerGroups: AudienceDemographicValue[] = (
      audienceDemographics?.employerGroups
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

    const newUniversityGroups: AudienceDemographicValue[] = (
      audienceDemographics?.universityGroups
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

    setOccupationGroups(newOccupationGroups)
    setIndustryGroups(newIndustryGroups)
    setEmployerGroups(newEmployerGroups)
    setUniversityGroups(newUniversityGroups)
  }, [ query, tabIndex, audienceDemographics ])

  const demoGroup = useMemo(() => {
    if (tabValues[tabIndex] === "occupation") return occupationGroups
    if (tabValues[tabIndex] === "industry") return industryGroups
    if (tabValues[tabIndex] === "employer") return employerGroups
    if (tabValues[tabIndex] === "university") return universityGroups
    return []
  }, [ tabIndex, occupationGroups, industryGroups, universityGroups, employerGroups ])

  return (
    <div className="cp_vocation_component">
      <div className="cp_vocation_component-header">
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Vocation") }
        </h6>
        <Input
          InputProps={ {
            startAdornment: <SearchIcon />,
          } }
          mode="thin"
          onChange={ (e) => setQuery(e.currentTarget.value) }
          placeholder={ translate("Search") }
          value={ query }
        />
      </div>
      <Tabs
        handleChange={ (index) => {
          setTabIndex(index)
        } }
        tabs={ tabs }
        controls={ <div /> }
      />
      <div className="cp_vocation_component-content">
        {
            demoGroup.map(({ name, value }, i) => {
              const followerCount = shorthandNumber(
                Math.floor(value * socialProfile.socialAccountStatistics.followers),
              )
              return (
                <div className="cp_vocation_component-content-demo" key={ name }>
                  <div className="cp_vocation_component-content-demo-header">
                    <p className="cp_vocation_component-content-demo-header-name">
                      { `${ i + 1 }. ${ name }` }
                    </p>
                    <p className="cp_vocation_component-content-demo-header-value">
                      { `${ prettyPrintDecimal(value, 1) }%` }
                    </p>
                  </div>
                  <Tooltip
                    title={ `${ followerCount } ${ translate("FOLLOWERS") }` }
                    arrow={ true }
                    placement="top"
                  >
                    <LinearProgress value={ value * 100 } variant="determinate" />
                  </Tooltip>
                </div>

              )
            })
        }
        {
          demoGroup.length === 0 && (
            <p className="cp_vocation_component-content-demo-no-data">
              { translate("No Data") }
            </p>
          )
        }
      </div>
    </div>
  )
}
