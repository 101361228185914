import React, {
  JSX,
  useCallback,
  useEffect,
} from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { FlagCircle } from "@mui/icons-material"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import EntityInfoRow from "../EntityInfoRow"
import LoadingIndicator from "../LoadingIndicator"
import ModalAddTo from "../ModalAddTo"
import {
  addAccountsToLists,
  fetchLists,
  removeAccountsFromLists,
  setAddAccountsLoading,
  setLists,
  setSelectedAccountIds,
  setSelectedListIds,
  setSelectedNetwork,
  getListsForProfile,
  setListsForProfile,
} from "../../state/modalAddToLists"
import { pushToast } from "../../state/toastSlice"
import { useDispatch, useSelector } from "../../state/hooks"

export default function ModalAddToLists(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalAddToLists" })
  const dispatch = useDispatch()
  const {
    addAccountsLoading,
    lists,
    selectedAccountIds,
    selectedListIds,
    selectedNetwork,
    listsForProfile,
  } = useSelector(({ modalAddToLists }) => modalAddToLists)

  /* When only one account is selected, user should see the lists that account is in
     and be able to add/remove from those lists */
  const isSingleAccount = () => selectedAccountIds.length === 1

  const fetchData = useCallback((e: React.ChangeEvent<HTMLInputElement>) => fetchAvailableLists(e.target.value), [])

  useEffect(() => {
    if (!isSingleAccount()) return
    if (selectedNetwork == null) return
    dispatch(getListsForProfile(selectedAccountIds, [ selectedNetwork ]))
  }, [ selectedAccountIds, selectedNetwork ])

  useEffect(() => {
    if (isSingleAccount() && API.isSuccess(listsForProfile)) {
      const initialSelectedIds = listsForProfile.payload.getSuggestionListsBySocialAccountIds.rows.map((list) => (list.id))
      dispatch(setSelectedListIds(initialSelectedIds))
    }
  }, [ listsForProfile ])

  const cleanup = (): void => {
    dispatch(setAddAccountsLoading(false))
    dispatch(setLists("init"))
    dispatch(setSelectedAccountIds([]))
    dispatch(setSelectedListIds([]))
    dispatch(setSelectedNetwork(null))
    if (isSingleAccount()) {
      dispatch(setListsForProfile("init"))
    }
  }

  const addAllAccountsToLists = async (): Promise<void> => {
    if (isSingleAccount()) {
      let listsToRemove: string[] = []
      let initialSelectedIds: string[] = []
      if (API.isSuccess(listsForProfile)) {
        initialSelectedIds = listsForProfile.payload.getSuggestionListsBySocialAccountIds.rows.map((list) => (list.id))
        listsToRemove = initialSelectedIds.filter((id) => !selectedListIds.includes(id))
      }
      if (listsToRemove.length > 0) {
        dispatch(setAddAccountsLoading(true))
        const listRemoveResults = await removeAccountsFromLists(selectedAccountIds, listsToRemove)
        if (listRemoveResults.some((r) => API.isError(r))) {
          dispatch(pushToast({
            message: translate("Error!"),
            additionalInfo: translate("An error occurred removing accounts from selected lists"),
            type: "error",
          }))
          dispatch(setAddAccountsLoading(false))
        } else {
          dispatch(pushToast({
            message: translate("Success!"),
            additionalInfo: translate("Accounts were successfully removed!"),
            type: "success",
          }))
        }
      }
    }
    if (selectedAccountIds.length !== 0 && selectedListIds.length !== 0) {
      dispatch(setAddAccountsLoading(true))
      const listAddResults = await addAccountsToLists(selectedAccountIds, selectedListIds)

      if (listAddResults.some((r) => API.isError(r))) {
        dispatch(pushToast({
          message: translate("Error!"),
          additionalInfo: translate("An error occurred adding accounts to selected lists"),
          type: "error",
        }))

        dispatch(setAddAccountsLoading(false))
      } else {
        dispatch(pushToast({
          message: translate("Success!"),
          additionalInfo: translate("Accounts were successfully added!"),
          type: "success",
        }))
      }
    }

    cleanup()
  }

  const fetchAvailableLists = (startsWith?: string): void => {
    if (selectedNetwork == null || selectedAccountIds.length === 0) return
    dispatch(fetchLists(selectedNetwork, startsWith))
  }

  const toggleSelectedListSetting = (listId: string): void => {
    if (selectedListIds.includes(listId)) {
      const filteredList = selectedListIds.filter((l) => l !== listId)
      dispatch(setSelectedListIds(filteredList))
      return
    }

    dispatch(setSelectedListIds([ ...selectedListIds, listId ]))
  }

  useEffect(() => fetchAvailableLists(), [ selectedAccountIds ])

  return (
    <ModalAddTo
      title={ translate("Add to List") }
      subtitle={ translate("Select the lists that you would like to add the selected account(s) to") }
      selectedEntityCount={ selectedListIds.length }
      open={ selectedAccountIds.length > 0 && selectedNetwork != null }
      closeAction={ cleanup }
      primaryAction={ addAllAccountsToLists }
      primaryLabel={
        isSingleAccount()
          ? `${ translate("Save") } (${ selectedListIds.length })`
          : `${ translate("Add") } (${ selectedListIds.length })`
      }
      fetchData={ fetchData }
      searchPlaceholderText={ translate("Search List Name") }
      disabled={ (!isSingleAccount() && selectedListIds.length === 0) || addAccountsLoading }
    >
      <div>
        { (lists === "init" || lists === "loading"
        || (isSingleAccount() && (listsForProfile === "init" || listsForProfile === "loading")) || addAccountsLoading) && (
          <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
        ) }
        { !addAccountsLoading && (isSingleAccount() ? API.isSuccess(listsForProfile) : true)
        && API.isSuccess(lists) && lists.payload.searchSuggestionList.rows.map((list) => (
          <EntityInfoRow
            avatarSrc={ list.avatar?.url.address }
            checkboxEnabled={ true }
            checkboxValue={ selectedListIds.includes(list.id) }
            key={ list.id }
            name={ list.name }
            network={ list.network }
            onClick={ () => toggleSelectedListSetting(list.id) }
            rowAdornment={
              list.suggestionListMode === GraphQL.SuggestionListMode.Campaign
                ? <FlagCircle />
                : undefined
            }
            subInfo={ `${ list.creator.username } | ${ dayjs(list.created, "X").format(Constant.LONGFORM_DATE) }` }
          />
        )) }
      </div>
    </ModalAddTo>
  )
}
