import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Container, Typography } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import {
  DeleteOutlined,
  EditOutlined,
  Launch,
} from "@mui/icons-material"
import SlidingPanel from "../../../SlidingPanel"
import "./profileSlidingPanel.sass"
import SearchBar from "../../../SearchBar"
import * as GraphQL from "../../../../graphql"
import LoadingIndicator from "../../../LoadingIndicator"
import * as API from "../../../../util/apiClient"
import Avatar from "../../../Avatar"
import { shorthandNumber } from "../../../../util/miscHelper"
import NetworkIcon from "../../../NetworkIcon"
import {
  PanelState,
  setPanelOpen,
  setProfileSearchInput,
  setProfileSearchToggle,
  togglePanel,
} from "../../../../state/slidingPanelSlice"
import {
  getPersonalities,
  getMorePersonalities,
  handleManageProfileMenuClick,
  handleDeleteProfileMenuClick,
  handleViewInsightsMenuClick,
} from "../../../../state/slidingPanelSlice/profiles"
import ModalManageProfile from "./ModalManageProfile"
import { useDispatch, useSelector } from "../../../../state/hooks"
import ModalDeleteProfile from "./ModalDeleteProfile"
import InfiniteScroll from "../../../InfiniteScroll"
import ContextMenuButton from "../../../DataGrid/RenderCellComponents/ContextMenuButton"
import MenuItem from "../../../MenuItem"
import EmptySplash from "../../../EmptySplash"
import { Scope } from "../../../../util/types"
import { ToggleMenuValue } from "../../../Toggle"
import {
  fetchProfile,
  setEditMode,
  setOpenModal,
} from "../../../../state/profileSlice"

type Props = {
  open: boolean
  onClose: () => void
}

function ProfileSlidingPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, scopes } = useSelector(({ user: currentUser }) => currentUser)

  const {
    profileSearchInput,
    profileSearchToggle,
    profilesContent,
    profilesStatus,
    profileManagementModalOpen,
    profileDeleteModalOpen,

  } = useSelector((state) => state.slidingPanels)

  const toggleOptions: ToggleMenuValue[] = [
    {
      value: "profile", label: translate("Profile"), keyId: uuidv4(),
    },
    {
      value: "vertical", label: translate("Vertical"), keyId: uuidv4(),
    },
  ]

  const [ page, setPage ] = useState(1)

  const hasScopesToManageAccess = useMemo(() => scopes.includes(Scope.INFLUENCER_MANAGER_CREATE)
  && scopes.includes(Scope.INFLUENCER_MANAGER_READ)
  && scopes.includes(Scope.INFLUENCER_MANAGER_UPDATE)
  && scopes.includes(Scope.INFLUENCER_MANAGER_DELETE), [ scopes ])

  const hasScopesToEditProfile = useMemo(() => scopes.includes(Scope.INFLUENCERS_UPDATE)
  && scopes.includes(Scope.INFLUENCERS_READ), [ scopes ])

  useEffect(() => {
    if (open) {
      setPage(1)
      dispatch(getPersonalities(profileSearchInput, profileSearchToggle, 1))
    }
  }, [ profileSearchInput, profileSearchToggle, open ])

  const setInputCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setProfileSearchInput(e.target.value)),
    [],
  )

  const renderContent = () => {
    if (profilesStatus === "init" || profilesStatus === "loading") {
      return (
        <div className="cp_component_sliding-panels_loading-indicator-container">
          <LoadingIndicator />
        </div>
      )
    }

    if (API.isSuccess(profilesStatus) && profilesContent.length === 0) {
      return (
        <EmptySplash bodyText={ translate("No profiles found!") } />
      )
    }

    if (API.isSuccess(profilesStatus) && API.isSuccess(user)) {
      const vanity = user.payload.currentUser?.customer.vanity

      return (
        <div
          className="cp_component_navigation-bar-profiles-container"
        >
          <InfiniteScroll
            dataLength={ profilesContent.length }
            next={ async () => {
              await dispatch(getMorePersonalities(profileSearchInput, profileSearchToggle, page + 1))
              setPage((prev) => prev + 1)
            } }
            hasMore={ profilesStatus.payload.searchPersonalities.totalCount > profilesContent.length }
          >
            { profilesContent.map((row) => {
              // NOTE: Twitter support is being deprecated, but until
              // Twitter accounts are removed from the back end, filter
              // them here explicitly.
              const socialAccounts = row.socialAccounts
                .filter((s) => s.network !== GraphQL.Network.Twitter)

              const activeSocialAccounts = socialAccounts
                .map((account) => account.network)
                // NOTE: Front-end filtering is an antipattern that has the
                // potential to break infinite scroll, not to mention is
                // a general security risk. This filtering needs to happen
                // on the back end. When it does, the filters below may be
                // removed.
                .filter((network) => {
                  if (scopes.includes(Scope.FEATURE_TIKTOK)) return true
                  return network !== GraphQL.Network.Tiktok
                })
                .filter((network) => {
                  if (scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) return true
                  return network !== GraphQL.Network.Snapchat
                })

              if (activeSocialAccounts.length === 0) return <></>

              return (
                <Container
                  key={ `list-search-${ row.id }` }
                  className="cp_component_navigation-bar-profile-container"
                  onClick={ () => {
                    navigate(`/${ vanity }/profile/${ socialAccounts[0].id }/insights`)
                    dispatch(togglePanel(PanelState.CLOSED))
                  } }
                >
                  <Avatar
                    src={ row.avatar.url.address }
                    className="cp_component_navigation-bar-profile-avatar"
                  >
                    { row.name.charAt(0).toUpperCase() }
                  </Avatar>
                  <div className="cp_component_navigation-bar-profile-row-content">
                    <div className="cp_component_navigation-bar-profile-name-container">
                      <Typography className="cp_component_navigation-bar-profile-name">{ row.name }</Typography>
                      <Typography className="cp_component_navigation-bar-profile-follower-count">
                        { `${ shorthandNumber(parseInt(row.totalReach, 10)) } ${ translate("followers") }` }
                      </Typography>
                    </div>
                    <div className="cp_component_navigation-bar-profile-networks">
                      { scopes.includes(Scope.FEATURE_TIKTOK) && (
                        <NetworkIcon
                          network={ GraphQL.Network.Tiktok }
                          isActive={ activeSocialAccounts.includes(GraphQL.Network.Tiktok) }
                        />
                      ) }
                      <NetworkIcon
                        network={ GraphQL.Network.Instagram }
                        isActive={ activeSocialAccounts.includes(GraphQL.Network.Instagram) }
                      />
                      <NetworkIcon
                        network={ GraphQL.Network.Facebook }
                        isActive={ activeSocialAccounts.includes(GraphQL.Network.Facebook) }
                      />
                      <NetworkIcon
                        network={ GraphQL.Network.Youtube }
                        isActive={ activeSocialAccounts.includes(GraphQL.Network.Youtube) }
                      />
                      { scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT) && (
                        <NetworkIcon
                          network={ GraphQL.Network.Snapchat }
                          isActive={ activeSocialAccounts.includes(GraphQL.Network.Snapchat) }
                        />
                      ) }
                    </div>
                    <ContextMenuButton
                      transformOrigin={ { horizontal: "right", vertical: "top" } }
                      anchorOrigin={ { horizontal: "center", vertical: "center" } }
                    >
                      <MenuItem
                        onClick={ (e) => {
                          e.stopPropagation()
                          if (!row.id || socialAccounts.length === 0) return
                          dispatch(handleViewInsightsMenuClick())
                          window.open(`/${ vanity }/profile/${ socialAccounts[0].id }/insights`, "_blank")
                        } }
                      >
                        <Typography>{ translate("View Insights") }</Typography>
                        <Launch />
                      </MenuItem>
                      { hasScopesToEditProfile ? (
                        <MenuItem onClick={ (e) => {
                          e.stopPropagation()
                          dispatch(fetchProfile(row.id))
                          dispatch(setPanelOpen(PanelState.CLOSED))
                          dispatch(setEditMode(true))
                          dispatch(setOpenModal(true))
                        } }
                        >
                          <Typography>{ translate("Edit Profile") }</Typography>
                          <EditOutlined />
                        </MenuItem>
                      ) : <> </> }
                      { hasScopesToManageAccess ? (
                        <MenuItem
                          onClick={ (e) => {
                            e.stopPropagation()
                            dispatch(handleManageProfileMenuClick(row))
                          } }
                        >
                          <Typography>{ translate("Manage Access") }</Typography>
                          <Launch />
                        </MenuItem>
                      ) : <div />
                      }
                      {
                        scopes.includes(Scope.INFLUENCERS_DELETE) ? (
                          <MenuItem
                            onClick={ () => dispatch(handleDeleteProfileMenuClick(row)) }
                          >
                            <Typography>{ translate("Delete Profile") }</Typography>
                            <DeleteOutlined />
                          </MenuItem>
                        ) : <div />
                      }
                    </ContextMenuButton>
                  </div>
                </Container>
              )
            }) }
          </InfiniteScroll>
        </div>
      )
    }

    return (
      <EmptySplash
        bodyText={ translateCommon("An unexpected error occurred!") }
      />
    )
  }

  return (
    <>
      <SlidingPanel
        title={ translate("Profiles") }
        toggleOptions={ toggleOptions }
        toggleValue={ profileSearchToggle }
        setToggleValue={ (value) => dispatch(setProfileSearchToggle(value)) }
        open={ open }
        onClose={ onClose }
        disablePortal={ true }
      >
        <SearchBar
          onChange={ setInputCallback }
          lastSubmittedSearch={ profileSearchInput }
        />
        { renderContent() }
      </SlidingPanel>
      <ModalManageProfile open={ profileManagementModalOpen } />
      <ModalDeleteProfile open={ profileDeleteModalOpen } />
    </>
  )
}

export default ProfileSlidingPanel
