import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import {
  ALL_NETWORKS,
  Status,
  TEAM_MEMBERS_SEARCH_FILTERS,
} from "../../util/types"
import {
  PanelState,
  setPanelOpen,
  setProfileDeleteModalOpen,
  setProfileManagementCurrentProfile,
  setProfileManagementFilterCompare,
  setProfileManagementModalOpen,
  setProfileManagementModalSearchInput,
  setProfileManagementProfilesResult,
  setProfileManagementSelectedIds,
  setProfileSearchInput,
  setProfilesContent,
  setProfilesStatus,
} from "."
import { RootState } from "../store"
import { setSearchResultsAbortController } from "../searchSlice"

// Campaign Interface and Initial State
export interface ProfilesState {
  profilesStatus: Status<GraphQL.SearchPersonalitiesSlidingPanelQuery>
  profilesContent: Array<GraphQL.PersonalityPanelRowFragment>
  profileSearchInput: string
  profileSearchToggle: "profile" | "vertical"
  profileManagementModalOpen: boolean
  profileManagementModalSearchInput: string
  profileManagementProfilesResult: Status<GraphQL.SearchTeamMembersQuery>
  profileManagementFilterCompare: "NAME" | "POSITION"
  profileManagementCurrentProfile: GraphQL.PersonalityPanelRowFragment | null
  profileManagementSelectedIds: Array<string>
  profileDeleteModalOpen: boolean
  searchResultsAbortController: AbortController,
}

export const initialState: ProfilesState = {
  profilesStatus: "init",
  profilesContent: [],
  profileSearchInput: "",
  profileSearchToggle: "profile",
  profileManagementModalOpen: false,
  profileManagementModalSearchInput: "",
  profileManagementProfilesResult: "init",
  profileManagementFilterCompare: "NAME",
  profileManagementCurrentProfile: null,
  profileManagementSelectedIds: [],
  profileDeleteModalOpen: false,
  searchResultsAbortController: new AbortController(),
}

// Reducer actions
export const reducers = {
  setProfilesStatus: (state: any, action: PayloadAction<Status<GraphQL.SearchPersonalitiesSlidingPanelQuery>>) => ({
    ...state,
    profilesStatus: action.payload,
  }),
  setProfilesContent: (state: any, action: PayloadAction<Array<GraphQL.PersonalityPanelRowFragment>>) => ({
    ...state,
    profilesContent: action.payload,
  }),
  setProfileSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    profileSearchInput: action.payload,
  }),
  setProfileSearchToggle: (state: any, action: PayloadAction<string>) => ({
    ...state,
    profileSearchToggle: action.payload,
  }),
  setProfileManagementModalOpen: (state: any, action: PayloadAction<boolean>) => ({
    ...state,
    profileManagementModalOpen: action.payload,
  }),
  setProfileManagementFilterCompare: (state: any, action: PayloadAction<string>) => ({
    ...state,
    profileManagementFilterCompare: action.payload,
  }),
  setProfileManagementModalSearchInput: (state: any, action: PayloadAction<string>) => ({
    ...state,
    profileManagementModalSearchInput: action.payload,
  }),
  setProfileManagementProfilesResult: (state: any, action: PayloadAction<Status<GraphQL.SearchTeamMembersQuery>>) => ({
    ...state,
    profileManagementProfilesResult: action.payload,
  }),
  setProfileManagementCurrentProfile: (state: any, action: PayloadAction<GraphQL.PersonalityPanelRowFragment>) => ({
    ...state,
    profileManagementCurrentProfile: action.payload,
  }),
  setProfileManagementSelectedIds: (state: any, action: PayloadAction<Array<string>>) => ({
    ...state,
    profileManagementSelectedIds: action.payload,
  }),
  toggleSelectedUsers: (
    state: any,
    action: PayloadAction<string>,
  ) => {
    const indexOfID = state.profileManagementSelectedIds.indexOf(action.payload)
    // Remove if already selected
    if (indexOfID !== -1) {
      const newIDs = [ ...state.profileManagementSelectedIds ]
      newIDs.splice(indexOfID, 1)
      return {
        ...state,
        profileManagementSelectedIds: newIDs,
      }
    }
    return {
      ...state,
      profileManagementSelectedIds: [ ...state.profileManagementSelectedIds, action.payload ],
    }
  },
  setProfileDeleteModalOpen: (state: any, action: PayloadAction<boolean>) => ({
    ...state,
    profileDeleteModalOpen: action.payload,
  }),
  setSearchResultsAbortController: (
    state: any,
    action: PayloadAction<AbortController>,
  ) => ({
    ...state,
    searchResultsAbortController: action.payload,
  }),
}

export const getPersonalities = (
  searchInput: string,
  searchToggle: string,
  page: number,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { slidingPanels: { profilesStatus, searchResultsAbortController } } = await getState()
  // Abort any current search requests...
  if (profilesStatus === "loading") {
    searchResultsAbortController.abort()
  }
  const newAbortController = new AbortController()
  dispatch(setSearchResultsAbortController(newAbortController))

  dispatch(setProfilesStatus("loading"))
  const result = await API.fetchPersonalities(
    searchInput,
    page,
    searchToggle === "vertical" ? GraphQL.PersonalityFilterTargetType.Vertical : null,
    50,
    ALL_NETWORKS,
    {
      fetchOptions: {
        signal: newAbortController.signal,
      },
    },
  )

  dispatch(setProfilesStatus(result))

  if (API.isSuccess(result)) {
    dispatch(setProfilesContent(result.payload.searchPersonalities.rows))
  } else {
    dispatch(setProfilesStatus({ status: "error", message: "Something went wrong with the request!" }))
  }
}

export const getMorePersonalities = (
  searchInput: string,
  searchToggle: string,
  page: number,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const {
    slidingPanels: {
      profilesStatus: currentProfileStatus, profilesContent: currentProfilesContent, searchResultsAbortController,
    },
  } = getState()

  // Abort any current search requests...
  if (currentProfileStatus === "loading") {
    searchResultsAbortController.abort()
  }
  const newAbortController = new AbortController()
  dispatch(setSearchResultsAbortController(newAbortController))

  const result = await API.fetchPersonalities(
    searchInput,
    page,
    searchToggle === "vertical" ? GraphQL.PersonalityFilterTargetType.Vertical : null,
    50,
    ALL_NETWORKS,
    {
      fetchOptions: {
        signal: newAbortController.signal,
      },
    },
  )

  if (API.isSuccess(currentProfileStatus) && API.isSuccess(result)) {
    dispatch(setProfilesContent([ ...currentProfilesContent, ...result.payload.searchPersonalities.rows ]))
  }
  dispatch(setProfileSearchInput(searchInput))
}

export const toggleSelectAll = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { profileManagementSelectedIds, profileManagementProfilesResult } } = getState()
  if (API.isSuccess(profileManagementProfilesResult)) {
    if (profileManagementSelectedIds.length === profileManagementProfilesResult.payload.searchUsers.totalCount) {
      dispatch(setProfileManagementSelectedIds([]))
    } else {
      dispatch(setProfileManagementSelectedIds(profileManagementProfilesResult.payload.searchUsers.rows.map((row) => row.id)))
    }
  }
}

export const getTeamMembers = (
  startsWith: string,
  startsWithCompareTo: string,
) => async (dispatch: Dispatch) => {
  dispatch(setProfileManagementProfilesResult("loading"))
  const i = TEAM_MEMBERS_SEARCH_FILTERS.map((v) => v.toString()).findIndex((f) => f === startsWithCompareTo)

  if ((i === -1)) {
    dispatch(setProfileManagementProfilesResult({ status: "error", message: "Invalid filter value!" }))
  } else {
    const result = await API.fetchTeamMembers({
      startsWith, startsWithCompareTo: TEAM_MEMBERS_SEARCH_FILTERS[i], limit: 1000,
    })
    if (API.isSuccess(result)) {
      dispatch(setProfileManagementProfilesResult(result))
    } else {
      dispatch(setProfileManagementProfilesResult({ status: "error", message: "Something went wrong with the request!" }))
    }
  }
}

export const handleViewInsightsMenuClick = () => async (dispatch: Dispatch) => {
  dispatch(setProfileManagementModalSearchInput(""))
  dispatch(setPanelOpen(PanelState.CLOSED))
}

export const handleManageProfileMenuClick = (row: GraphQL.PersonalityPanelRowFragment) => async (dispatch: Dispatch) => {
  dispatch(setProfileManagementModalOpen(true))
  dispatch(setProfileManagementCurrentProfile(row))
  dispatch(setProfileManagementFilterCompare(GraphQL.SearchTeamMembersFilterCompareToOption.Name))
  dispatch(setProfileManagementModalSearchInput(""))
  dispatch(setPanelOpen(PanelState.CLOSED))
  dispatch(setProfileManagementSelectedIds(row.managers.map((m) => m.user.id)))
}

export const handleDeleteProfileMenuClick = (row: GraphQL.PersonalityPanelRowFragment) => async (dispatch: Dispatch) => {
  dispatch(setProfileDeleteModalOpen(true))
  dispatch(setProfileManagementCurrentProfile(row))
  dispatch(setPanelOpen(PanelState.CLOSED))
}

export const submitDeleteProfile = (id: string) => async (dispatch: Dispatch, getState: () => RootState) => {
  const result = await API.deletePersonality({
    id,
  })

  if (API.isSuccess(result)) {
    const { slidingPanels: { profilesContent } } = getState()
    const deletedProfileIdx = profilesContent.findIndex((row) => row.id === id)
    const newProfilesContent = [ ...profilesContent ]
    newProfilesContent.splice(deletedProfileIdx, 1)
    dispatch(setProfilesContent(newProfilesContent))
  }

  return result
}
