import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"

type DescriptionProps = {
  description: string
}

export default function ListV2PageDescription({ description }: DescriptionProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListV2Description" })

  return (
    <div className="cp_page_public-list-page-flex_description">
      <p className="cp_page_public-list-page-flex_description-title">
        { translate("Description") }
      </p>
      <p className="cp_page_public-list-page-flex_description-text">
        { description }
      </p>
    </div>
  )
}
