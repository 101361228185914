import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { CheckCircleOutline } from "@mui/icons-material"
import { useCookies } from "react-cookie"
import { useNavigate, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "../../state/hooks"
import Button from "../../component/Button"
import { SuggestionListAccount } from "./CategoryAccounts"
import { PublicListApprovalStatus } from "../../util/types"
import {
  AccountApprovalStatus,
  approvePublicList,
  setInfoRequiredSubmitCallback,
  setLeftPanelApprovedStatus,
  userCanEdit,
} from "../../state/publicListSlice"
import { isSuccess } from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import LoadingIndicator from "../../component/LoadingIndicator"

interface ApprovalPanelProps {
  account: SuggestionListAccount
}

export default function ApprovalPanel({ account }: ApprovalPanelProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListV2CategoryRightPanel" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listCode } = useParams()

  const {
    leftPanelApprovedStatus,
    nameCookieKey,
    companyCookieKey,
    userVerification,
  } = useSelector((state) => state.publicList)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ nameCookieKey, companyCookieKey ])
  const [ accessCodeRestriction, setAccessCodeRestriction ] = React
    .useState<GraphQL.SuggestionAccessCodeRestriction | null | undefined>()
  const [ userIsValid, setUserIsValid ] = React.useState<boolean>(false)
  const [ submitted, setSubmitted ] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (isSuccess(userVerification)) {
      const { publicVerifySuggestionListAccessCodeByListCode: verification } = userVerification.payload
      setAccessCodeRestriction(verification.suggestionAccessCodeRestriction)
      setUserIsValid(verification.isValid)
    }
  }, [ userVerification ])

  const updateApprovalStatus = (status: PublicListApprovalStatus): AccountApprovalStatus[] => {
    // Rebuild status changing the status for given account
    const statuses = leftPanelApprovedStatus.map((accountStatus) => {
      if (accountStatus.accountId === account.id) {
        return {
          ...accountStatus,
          status,
        }
      }
      return {
        ...accountStatus,
      }
    })

    // Return the updated statuses
    return statuses
  }

  const submitApproval = async () => {
    setSubmitted(true)
    if (listCode) {
      // Check the cookies
      if (cookies[nameCookieKey] && cookies[companyCookieKey] && userCanEdit(accessCodeRestriction, userIsValid)) {
        // Save to database
        dispatch(approvePublicList({
          suggestionListCode: listCode,
          suggestionListSocialAccountId: account.id,
          onSuccess: () => {
            // Update global branch
            dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.Approved)))
          },
        }))
      } else {
        dispatch(setInfoRequiredSubmitCallback(() => {
          // Save to database
          dispatch(approvePublicList({
            suggestionListCode: listCode,
            suggestionListSocialAccountId: account.id,
            onSuccess: () => {
              // Update global branch
              dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.Approved)))
              navigate("../..", { relative: "path" })
            },
          }))
        }))
        navigate(`./requiredInformation/${ account.id }`, { relative: "path" })
      }
    }
  }

  const cancelApproval = () => {
    // Update global branch
    dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.NotApproved)))
  }

  return (
    <div className="cp_component_pl-right-panel-approval-overlay">
      <div className="cp_component_pl-right-panel-approval-overlay-content">
        <div className="cp_component_pl-right-panel-approval-overlay-check-icon">
          <CheckCircleOutline className="checkmark-icon" />
        </div>
        <p>{ translate("Are you sure you want to approve this account for your campaign?") }</p>
        <div className="cp_component_pl-right-panel-approval-overlay-actions">
          <Button
            className="btn-approval"
            label={ (submitted) ? <LoadingIndicator /> : translate("Yes, I Approve") }
            isPrimary={ true }
            onClick={ submitApproval }
          />
          <Button
            className="btn-cancel"
            label={ translate("Cancel") }
            isPrimary={ false }
            onClick={ cancelApproval }
          />
        </div>
      </div>
    </div>
  )
}
