import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import "./style.sass"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import Divider from "../Divider"
import Pill from "../Pill"
import NetworkIcon from "../NetworkIcon"
import { generateDemographicRangeString, shorthandNumber } from "../../util/miscHelper"
import * as GraphQL from "../../graphql"
import Button from "../Button"
import { ToggleFragment } from "../../graphql"

type Props = {
    listName: string
    description?: string | null
    minAge?: number | null
    maxAge?: number | null
    sexes: Array<string>
    numberOfAccounts: number
    reach: number
    platform: GraphQL.Network
    className?: string
    publicUrl: string
    toggles: ToggleFragment[]
}

export default function ListInfoCard({
  listName,
  minAge,
  maxAge,
  sexes,
  description,
  numberOfAccounts,
  platform,
  reach,
  className,
  publicUrl,
  toggles,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListInfoCard" })
  const [ expanded, setExpanded ] = useState(false)
  const [ overflowed, setsetOverflowed ] = useState(false)
  const demographicString = useMemo(() => (generateDemographicRangeString(sexes, minAge, maxAge)), [ sexes, minAge, maxAge ])
  const classes = [ "cp_component_public-list-info-card" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")
  const descriptionRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    const callback = () => {
      if (!descriptionRef.current) return
      const descriptionElement = descriptionRef.current // eslint-disable-next-line no-debugger
      setsetOverflowed(descriptionElement.scrollHeight > descriptionElement.clientHeight)
    }
    const resizeObserver = new ResizeObserver(callback)
    callback()

    return () => {
      if (descriptionRef.current) resizeObserver.unobserve(descriptionRef.current)
    }
  }, [ ])

  const isDemographicConfigured = useMemo(() => toggles.some((toggle) => toggle.name === "ToggleDemographicScore"), [ toggles ])

  return (
    <Container
      className={ newClasses }
      onClick={ () => window.open(publicUrl, "_self") }
    >
      <div className="cp_component_public-list-info-card-main">
        <h2 className="cp_component_public-list-info-card-main-name">{ listName }</h2>
        <Divider className="cp_component_public-list-info-card-main-divider" />
        <p
          ref={ descriptionRef }
          className={ `cp_component_public-list-info-card-main-description${ expanded ? "-expanded" : "" }` }
        >
          { description }
        </p>
        { overflowed && !expanded && (
        <div className="cp_component_public-list-info-card-main-expand-button">
          <Button
            label={ translate("Expand") }
            onClick={ (e) => {
              e.stopPropagation()
              setExpanded(true)
            } }
          />
        </div>
        ) }
        { expanded && (
        <div className="cp_component_public-list-info-card-main-less-button">
          <Button
            label={ translate("Show Less") }
            onClick={ (e) => {
              e.stopPropagation()
              setExpanded(false)
            }
          }
          />
        </div>
        ) }
        { (demographicString && isDemographicConfigured) && (
          <>
            <p className="cp_component_public-list-info-card-main-demographic">{ translate("Demographic") }</p>
            <div className="cp_component_public-list-info-card-main-pill">
              <Pill label={ demographicString } />
            </div>
          </>
        ) }
      </div>
      <Divider className="cp_component_public-list-info-card-divider" />
      <div className="cp_component_public-list-info-card-stats">
        <div className="cp_component_public-list-info-card-stats-info">
          <NetworkIcon
            additionalClasses="cp_component_public-list-info-card-stats-info-network"
            isActive={ true }
            network={ platform }
          />
          <p className="cp_component_public-list-info-card-stats-info-title">{ translate("Platform") }</p>
        </div>
        <div className="cp_component_public-list-info-card-stats-info">
          <h4>{ numberOfAccounts }</h4>
          <p className="cp_component_public-list-info-card-stats-info-title">{ translate("Accounts") }</p>
        </div>
        <div className="cp_component_public-list-info-card-stats-info">
          <h4>{ shorthandNumber(reach) }</h4>
          <p className="cp_component_public-list-info-card-stats-info-title">{ translate("Followers") }</p>
        </div>
      </div>
    </Container>
  )
}
