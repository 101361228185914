import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import {
  AutocompleteRenderOptionState,
  FilterOptionsState,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  PopperProps,
} from "@mui/material"

import Input from "../../../Input"
import Autocomplete from "../../../Autocomplete"
import { GroupMetrics, appendixMetrics as appendixToggles } from "../../constants"
import { copyObject } from "../../../../util/miscHelper"
import AppendixDisplayGroup from "./AppendixDisplayGroup"
import Checkbox from "../../../Checkbox"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  resetState,
  Metric,
  setUpdateListForm,
} from "../../../../state/listConfigurationSlice"
import { SuggestionListToggleGroupType, SuggestionListToggleInput } from "../../../../graphql"

/**
 * HighlightsPopper: A valid popper used for the autocomplete
 * @param props Any properties for the popper
 * @returns The custom popper object
 */
function HighlightsPopper(props: PopperProps) {
  return <Popper { ...props } placement="bottom-start" className="cp_highlights-metrics-dropdown" />
}

/**
 * Highlights: The react component that renders the highlights metrics and search
 * @returns The highlights component render
 */
export default function Appendix() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  // Global state
  const { updateListForm } = useSelector((state) => state.listConfiguration)
  let toggles: SuggestionListToggleInput[] = []
  if (updateListForm?.toggles) {
    toggles = Array.isArray(updateListForm.toggles)
      ? updateListForm.toggles
      : [ updateListForm.toggles ]
  }

  /**
   * Handle state reset when moving from one list to another
   */
  React.useEffect(() => {
    // **** this is temporary, until we are able to save to the database ****
    dispatch(resetState())
    return () => {
      // Reset on unload
      dispatch(resetState())
    }
  }, [])

  /**
   * selectItem: Handles selecting or de-selecting a sub list item
   * @param metric The metric to select/de-select
   */
  const selectItem = (metric: Metric) => {
    if (!updateListForm) return
    if (toggles.some((toggle) => toggle.name === metric.code)) {
      // Check if update list form exists
      // Remove toggle from update list form
      const listForm = copyObject(updateListForm)
      listForm.toggles = (Array.isArray(listForm.toggles))
        ? listForm.toggles.filter((toggle) => toggle.name !== metric.code)
        : [ listForm.toggles ].filter((toggle) => toggle.name !== metric.code)

      dispatch(setUpdateListForm(listForm))
    } else {
      // Add item
      // Add all toggles to update list form
      const listForm = copyObject(updateListForm)
      listForm.toggles = [
        ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
        ...[ {
          name: metric.code, order: 0, type: SuggestionListToggleGroupType.AppendixContentToggles,
        } ],
      ]
      dispatch(setUpdateListForm(listForm))
    }
  }

  /**
   * renderTheOption: Renders an option to be displayed in the autocomplete popper
   * @param option The option dat to be displayed
   * @returns The rendering react component
   */
  const renderTheOption = (option: GroupMetrics, state: AutocompleteRenderOptionState): React.JSX.Element => {
    // Check to see if there is no search
    if (!state.inputValue || state.inputValue === "") {
      // Check to see if title
      if (option.groupName === "Metrics") {
        return <p className="metrics-title">{ translate(option.groupName) }</p>
      }

      // Return the display metric group
      return <AppendixDisplayGroup key={ option.groupName } metricData={ option } />
    }

    // Check to see if the title with search input value
    if (option.groupName === "Metrics") {
      return <p className="metrics-title">{ translate(option.groupName) }</p>
    }

    // Check to see if no metrics were found
    if (option.groupName === "No metrics found!") {
      return <p className="metrics-search-no-result">{ translate(option.groupName) }</p>
    }

    // Return the rendering component for the option
    return (
      <ListItemButton
        className="filtered-metric-button"
        key={ `${ option.groupName }-${ option.metrics[0] }` }
        onClick={ () => selectItem(option.metrics[0]) }
      >
        <ListItemIcon>
          <Checkbox checked={ toggles.some((toggle) => toggle.name === option.metrics[0].code) } />
        </ListItemIcon>
        <ListItemText className="filtered-metric" primary={ translate(option.metrics[0].name) } />
      </ListItemButton>
    )
  }

  /**
   * filterTheOptions: Filters all the metrics to display only what has been typed
   * @param options All the default options
   * @returns Options that have been filtered
   */
  const filterTheOptions = (options: GroupMetrics[], state: FilterOptionsState<GroupMetrics>): GroupMetrics[] => {
    // Base case, no text in the filters
    if (!state.inputValue || state.inputValue === "") return options

    // There is text in the search input
    const filteredOptions: GroupMetrics[] = options.map((option) => {
      // Include the title metric always
      if (option.groupName === "Metrics") return [ option ]

      // Filter out valid metrics
      const validMetrics = option.metrics.filter((metric) => metric.name.toLowerCase().includes(state.inputValue.toLowerCase()))

      // Create single metric values for everything found
      return validMetrics.map((metric): GroupMetrics => ({
        groupName: option.groupName,
        metrics: [ metric ],
      }))
    }).flat()

    // Check to see if there is only the one title
    if (filteredOptions.length === 1) {
      filteredOptions.push({
        groupName: "No metrics found!",
        metrics: [],
      })
    }

    return filteredOptions
  }

  // The conmponent render
  return (
    <Autocomplete
      PopperComponent={ HighlightsPopper }
      id="appendix-autocomplete-input"
      className="cp_component_appendix-autocomplete"
      options={ appendixToggles }
      filterOptions={ (options, state) => filterTheOptions(options, state) }
      getOptionLabel={ (option: string | GroupMetrics) => (typeof option === "string") ? option : option.groupName }
      renderOption={ (_params, option, state) => renderTheOption(option, state) }
      disableCloseOnSelect={ true }
      renderInput={ (params) => (
        <Input
          { ...params }
          className="cp_component_appendix-autocomplete-input"
          placeholder={ translate("Select Metrics") }
        />
      ) }
    />
  )
}
