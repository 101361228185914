import React from "react"
import { useTranslation } from "react-i18next"
import "./customer-user-header.sass"
import { EditOutlined } from "@mui/icons-material"
import dayjs from "dayjs"
import { IconButton } from "@mui/material"
import { CustomerUserFragment } from "../../graphql"
import Avatar from "../Avatar"
import Tooltip from "../Tooltip"
import { MONTH_DAY_ABBR_FORMAT } from "../../util/constant"
import { useDispatch } from "../../state/hooks"
import { openEditCustomerUserModal } from "../../state/customerUserModalSlice"
import { getCustomerUser } from "../../state/customerUserPageSlice"

type Props = {
  user: CustomerUserFragment,
  customerId?: string

}

export default function CustomerUserHeader({
  user, customerId,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerHeader" })

  const dispatch = useDispatch()

  const getAvatarURL = () => {
    if (user.contact?.avatar.url.address.includes("user-avatar.png")) {
      return undefined
    }
    return user.contact?.avatar.url.address || undefined
  }

  const refreshUser = async () => {
    dispatch(getCustomerUser({ userId: user.id }))
  }

  const openEditUser = () => {
    if (!customerId) return

    dispatch(openEditCustomerUserModal({
      userIdToEdit: user.id,
      customerId,
      updateCallback: refreshUser,
    }))
  }

  return (
    <div className="cp_customer-user-overview-header">
      <div className="cp_customer-user-overview-header_info-container">
        <div className="cp_customer-user-overview-header_avatar-container">
          <Avatar size="lg" src={ getAvatarURL() } className="cp_customer-user-overview-header_avatar">
            { user.contact?.name[0] }
          </Avatar>
        </div>
        <div className="cp_customer-user-overview-header_details">
          <p className="cp_customer-user-overview-header_group-name">
            { user.contact?.name }
          </p>
          <p className="cp_customer-user-overview-header_created">
            { `${ translate("Created") } ${ dayjs(user.created * 1000)
              .format(MONTH_DAY_ABBR_FORMAT) }` }
          </p>
        </div>
      </div>
      <div className="cp_customer-user-overview-header_stats">
        <div className="cp_customer-user-overview-header_user-count-container" />
        <Tooltip title={ translate("Edit") }>
          <IconButton id="cp_customer-user-overview-header_edit-btn" size="small" onClick={ openEditUser }>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
