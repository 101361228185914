import React, { useEffect } from "react"

import "./history.sass"
import { useQuery } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LoadingIndicator from "../LoadingIndicator"
import {
  DeliverableHistoryItem,
  DeliverableHistoryOutput,
  DeliverableQueryVariables,
  GET_DELIVERABLE_HISTORY,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import HistoryItem from "./HistoryItem"
import { useSelector } from "../../state/hooks"
import { Scope } from "../../util/types"

function ModalCampaignDeliverableHistory(): React.JSX.Element {
  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableHistory" })

  const {
    modalOpen,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)
  const { scopes } = useSelector(({ user }) => user)
  const {
    data, loading, refetch,
  } = useQuery<DeliverableHistoryOutput, DeliverableQueryVariables>(GET_DELIVERABLE_HISTORY, {
    variables: {
      deliverableId,
    },
    context: {
      apiVersion: "v2",
    },
    skip: !deliverableId,
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (modalOpen && data) {
      refetch()
    }
  }, [ modalOpen ])

  if (data === undefined) return <></>

  if (loading) return <LoadingIndicator />

  if (data === undefined && !loading) return <> </>

  const { deliverableHistoryItems } = data

  if (deliverableHistoryItems.length === 0) return <></>

  return (
    <div className="cp_modal-campaign-deliverable-v2-history-tab_component">
      <p>{ translate("Deliverable History") }</p>
      <div className="deliverable-history-items-container">
        { deliverableHistoryItems.filter((item) => scopes.includes(Scope.CAMPAIGN_MANAGEMENT) || !item.isHidden).map(
          (deliverableHistoryItem: DeliverableHistoryItem) => (
            <HistoryItem
              key={ deliverableHistoryItem.id }
              deliverableHistoryItem={ deliverableHistoryItem }
            />
          ),
        ) }
      </div>
    </div>
  )
}

export default ModalCampaignDeliverableHistory
