import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  PopperProps,
} from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

import Input from "../../../Input"
import Autocomplete from "../../../Autocomplete"
import { highlightMetrics } from "../../constants"
import Checkbox from "../../../Checkbox"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  setHighlightsMetrics,
  Metric,
  setShowHighlightsAudienceForm,
  setUpdateListForm,
} from "../../../../state/listConfigurationSlice"
import { SuggestionListToggleGroupType } from "../../../../graphql"

type HighlightVisualizationMetric = {
  groupName: string
  name: string
  code: string
}

/**
 * HighlightsPopper: A valid popper used for the autocomplete
 * @param props Any properties for the popper
 * @returns The custom popper object
 */
function HighlightsPopper(props: PopperProps) {
  return <Popper { ...props } placement="bottom-start" className="cp_highlights-metrics-dropdown" />
}

/**
 * Highlights: The react component that renders the highlights metrics and search
 * @returns The highlights component render
 */
export default function Highlights() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  // Global state
  const { highlightsMetrics, updateListForm } = useSelector((state) => state.listConfiguration)

  // Local state
  const [ audienceMetricsDisplay, setAudienceMetricsDisplay ] = React.useState<boolean>(false)
  const [ showMetrics, setShowMetrics ] = React.useState<Map<string, boolean>>(
    new Map<string, boolean>(highlightMetrics.map((metric): [string, boolean] => [ metric.groupName, false ])),
  )
  const [ filteredOptions, setFilteredOptions ] = React.useState<HighlightVisualizationMetric[]>([])

  // React Hook to get all the metrics for the autocomplete
  const visualizationMetrics = React.useMemo((): HighlightVisualizationMetric[] => {
    const metrics: HighlightVisualizationMetric[] = []
    highlightMetrics.forEach((group) => {
      if (group.groupName === "Audience Metrics (up to 5)") {
        metrics.push({
          groupName: group.groupName,
          name: "Audience Metrics",
          code: "audience_metrics",
        })
      } else if (group.groupName === "Metrics") {
        metrics.push({
          groupName: group.groupName,
          name: "Metrics",
          code: "metrics",
        })
      } else {
        group.metrics.forEach((metric) => {
          metrics.push({
            groupName: group.groupName,
            name: metric.name,
            code: metric.code,
          })
        })
      }
    })
    setFilteredOptions(metrics)
    return metrics
  }, [])

  /**
   * isChecked: Checks if a metric is checked
   * @param metric The metric to check
   * @returns True if the metric is checked
   */
  const isChecked = (metric: HighlightVisualizationMetric) => (
    highlightsMetrics.map((m) => m.code).includes(metric.code)
  )

  // React Hook to set the audience metrics display
  React.useEffect(() => {
    if (updateListForm) {
      if (Array.isArray(updateListForm.toggles)) {
        const showForm = updateListForm.toggles.some((toggle) => (
          highlightMetrics
            .find((group) => group.groupName === "Audience Metrics (up to 5)")?.metrics
            .find((metric) => metric.code === toggle.name)
        ))

        // Set the display form
        dispatch(setShowHighlightsAudienceForm(showForm))
        setAudienceMetricsDisplay(showForm)
      }
    }
  }, [ updateListForm ])

  /**
   * selectItem: Selects or de-selects a metric
   * @param metric The metric to select or de-select
   */
  const selectItem = (metric: HighlightVisualizationMetric) => {
    // Check a single item
    if (isChecked(metric)) {
      dispatch(setHighlightsMetrics(highlightsMetrics.filter((m) => m.code !== metric.code)))

      // Check if update list form exists
      if (updateListForm) {
        const listForm = { ...updateListForm }
        listForm.toggles = (Array.isArray(listForm.toggles))
          ? listForm.toggles.filter((toggle) => toggle.name !== metric.code)
          : [ listForm.toggles ].filter((toggle) => toggle.name !== metric.code)
        dispatch(setUpdateListForm(listForm))
      }
    } else {
      dispatch(setHighlightsMetrics([ ...highlightsMetrics, { code: metric.code, name: metric.name } ]))

      // Check if update list form exists
      if (updateListForm) {
        const listForm = { ...updateListForm }
        listForm.toggles = [
          ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
          {
            name: metric.code,
            order: 0,
            type: SuggestionListToggleGroupType.OtherToggles,
          },
        ]
        dispatch(setUpdateListForm(listForm))
      }
    }
  }

  /**
   * showAudienceForm: Shows the audience form in the display area
   */
  const showAudienceForm = () => {
    // Update global state to enable showing the form on the display
    dispatch(setShowHighlightsAudienceForm(!audienceMetricsDisplay))

    // Update local to show checkmark value in autocomplete
    setAudienceMetricsDisplay(!audienceMetricsDisplay)
  }

  /**
   * renderGroup: Renders the group of metrics
   * @param key The key for the group
   * @param groupName The name of the group
   * @returns The JSX element for the group
   */
  const renderGroup = (key: string, groupName: string): React.JSX.Element => {
    /**
     * getGroupMetricInfo: Gets various metric information for a group
     * @param group The name of the group
     * @returns An object with the metrics for the group, the codes for the group, and the selected metrics
     */
    const getGroupMetricInfo = (group: string) => ({
      metricsForGroup: visualizationMetrics.filter((option) => option.groupName === group),
      codes: visualizationMetrics.filter((option) => option.groupName === group).map((option) => option.code),
      selectedMetrics: highlightsMetrics
        .filter((metric) => visualizationMetrics
          .filter((option) => option.groupName === group)
          .map((option) => option.code)
          .includes(metric.code)),
    })

    /**
     * allSelectOrDeselect: Selects all or de-selects all sub list items
     * @param group The group to select or de-select
     */
    const allSelectOrDeselect = (group: string) => {
      // Get all the metrics that are selected for this group
      const groupInfo = getGroupMetricInfo(group)

      // Check if all the metrics are selected
      if (groupInfo.selectedMetrics.length === groupInfo.codes.length) {
        // Update global state
        dispatch(setHighlightsMetrics(
          highlightsMetrics.filter((metric) => !groupInfo.selectedMetrics.includes(metric)),
        ))

        // Check if update list form exists
        if (updateListForm) {
          // Remove all toggles from update list form
          const listForm = { ...updateListForm }
          listForm.toggles = (Array.isArray(listForm.toggles))
            ? listForm.toggles.filter((toggle) => !groupInfo.selectedMetrics.some((metric) => metric.code === toggle.name))
            : [ listForm.toggles ].filter((toggle) => !groupInfo.selectedMetrics.some((metric) => metric.code === toggle.name))
          dispatch(setUpdateListForm(listForm))
        }
      } else {
        // reverse filter find what isn't there and then add
        const missing: Metric[] = groupInfo.metricsForGroup
          .filter((met) => !highlightsMetrics.map((metric) => metric.code).includes(met.code))
          .map((met) => ({
            code: met.code,
            name: met.name,
          }))
        const allCheckMetrics = [ ...highlightsMetrics, ...missing ]
        dispatch(setHighlightsMetrics(allCheckMetrics))

        // Check if update list form exists
        if (updateListForm) {
          // Add all toggles to update list form
          const listForm = { ...updateListForm }
          const toggleCodes = (Array.isArray(listForm.toggles))
            ? listForm.toggles.map((toggle) => toggle.name)
            : [ listForm.toggles ].map((toggle) => toggle.name)
          const addList = allCheckMetrics.filter((metric) => !toggleCodes.includes(metric.code))
          listForm.toggles = [
            ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
            ...addList.map((metric) => ({
              name: metric.code,
              order: 0,
              type: SuggestionListToggleGroupType.OtherToggles,
            })),
          ]
          dispatch(setUpdateListForm(listForm))
        }
      }
    }

    /**
     * containsAllMetrics: Checks if all metrics are selected
     * @param group The group to check
     * @param noOfMetrics The number of metrics in the group
     * @returns True if all the metrics for the group are selected
     */
    const containsAllMetrics = (group: string, noOfMetrics: number): boolean => {
      // Get all the metrics that are selected for this group
      const groupInfo = getGroupMetricInfo(group)

      return (groupInfo.selectedMetrics.length === noOfMetrics)
    }

    /**
     * containsSomeMetrics: Checks if some metrics are selected or if group is partially selected
     * @param group The name of the group
     * @param noOfMetrics The number of metrics in the group
     * @returns True if some metrics are selected
     */
    const containsSomeMetrics = (group: string, noOfMetrics: number): boolean => {
      // Get all the metrics that are selected for this group
      const groupInfo = getGroupMetricInfo(group)

      return (groupInfo.selectedMetrics.length > 0 && groupInfo.selectedMetrics.length < noOfMetrics)
    }

    // Set the title
    if (groupName === "Metrics") {
      return <p key={ key } className="metrics-title">{ translate(groupName) }</p>
    }

    // Set the special audience metrics
    if (groupName === "Audience Metrics (up to 5)") {
      return (
        <ListItemButton
          className="audience-metric-button"
          key={ `${ key }` }
          onClick={ showAudienceForm }
        >
          <ListItemIcon>
            <Checkbox className="audience-cb" size="small" checked={ audienceMetricsDisplay } />
          </ListItemIcon>
          <ListItemText className="audience-metric" primary={ translate(groupName) } />
        </ListItemButton>
      )
    }

    // Set the children metrics
    const children = (filteredOptions.length > 0)
      ? filteredOptions
        .filter((option) => option.groupName === groupName)
        .map((option) => renderOption(option))
      : visualizationMetrics
        .filter((option) => option.groupName === groupName)
        .map((option) => renderOption(option))

    // Render the group
    return (
      <>
        <ListItemButton
          key={ key }
          className="group-highlights-parent-list-item"
          onClick={ () => (
            setShowMetrics((prev) => new Map(prev.set(groupName, !showMetrics.get(groupName))))
          ) }
        >
          { (showMetrics.get(groupName))
            ? <ExpandMore className="list-item-expand-contract" />
            : <ExpandLess className="list-item-expand-contract" /> }
          <ListItemIcon>
            <Checkbox
              checked={ containsAllMetrics(groupName, children.length) }
              indeterminate={ containsSomeMetrics(groupName, children.length) }
              onClick={ () => allSelectOrDeselect(groupName) }
            />
          </ListItemIcon>
          <ListItemText
            className="group-highlights-parent-list-item-text"
            primary={ translate(groupName) }
          />
        </ListItemButton>
        <Collapse in={ showMetrics.get(groupName) } timeout="auto" unmountOnExit={ true }>
          <List
            id="plad-metrics"
            className="group-highlights-child-list-item"
            component="div"
            disablePadding={ true }
          >
            { children }
          </List>
        </Collapse>
      </>
    )
  }

  /**
   * renderOption: Renders child option of a group for the autocomplete
   * @param option The option to render
   * @returns The JSX element for the option
   */
  const renderOption = (option: HighlightVisualizationMetric): React.JSX.Element => (
    <ListItemButton
      className="group-highlights-child-list-item-button"
      key={ `${ option.groupName }-${ option.code }` }
      onClick={ () => selectItem(option) }
    >
      <ListItemIcon>
        <Checkbox
          className="group-highlights-child-list-item-cb"
          checked={ isChecked(option) }
        />
      </ListItemIcon>
      <ListItemText
        className="group-highlights-child-list-item-text"
        primary={ translate(option.name) }
      />
    </ListItemButton>
  )

  /**
   * resetHideShow: Resets the hide and show for the metric groups
   */
  const setAllHideShow = (show: boolean) => {
    setShowMetrics(new Map<string, boolean>(
      highlightMetrics.map((metric): [string, boolean] => [ metric.groupName, show ]),
    ))
  }

  const onInputChange = (value: string) => {
    // Base case
    if (value === "") {
      setFilteredOptions(visualizationMetrics)
      setAllHideShow(false)
    } else {
      setFilteredOptions(visualizationMetrics.filter((option) => {
        if (option.groupName.toLowerCase().startsWith(value.toLowerCase())) return true
        if (option.name.toLowerCase().startsWith(value.toLowerCase())) return true
        return false
      }))
      setAllHideShow(true)
    }
  }

  // The component render
  return (
    <Autocomplete
      PopperComponent={ HighlightsPopper }
      id="highlights-autocomplete-input"
      className="cp_component_highlights-autocomplete"
      options={ visualizationMetrics }
      filterOptions={ () => filteredOptions }
      groupBy={ (option) => option.groupName }
      disableCloseOnSelect={ true }
      renderGroup={ ({ key, group }) => renderGroup(key, group) }
      renderInput={ (params) => (
        <Input
          { ...params }
          className="cp_component_highlights-autocomplete-input"
          placeholder={ translate("Select Metrics") }
        />
      ) }
      onInputChange={ (_event, value) => onInputChange(value) }
      onClose={ () => setAllHideShow(false) }
    />
  )
}
