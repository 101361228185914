import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material"
import {
  Add,
  Remove,
  HelpOutline,
  ArrowForwardIos,
} from "@mui/icons-material"
import { useParams } from "react-router-dom"

import Highlights from "./Visualizations/Highlights"
import HighlightsDisplay from "./Visualizations/HighlightsDisplay"
import ModalListAccountsDataPoint from "../ModalListAccountsDataPoint"
import { DefaultSuggestionListToggles } from "../../util/constant"
import Button from "../Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { updateList } from "../../state/listConfigurationSlice"
import { pushToast } from "../../state/toastSlice"
import { isSuccess } from "../../util/apiClient"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import * as GraphQL from "../../graphql"
import Appendix from "./Visualizations/Appendix"
import AppendixDisplay from "./Visualizations/AppendixDisplay"

import "./configuration-option-styles.sass"
import { fetchList } from "../../state/listSlice"
import { AudienceGroup } from "./constants"
import AccountInsightToggles from "./Visualizations/AccountInsights"
import AudienceDisplayToggles from "./Visualizations/AudienceDisplay"
import ContentDisplayToggles from "./Visualizations/ContentDisplay"
import countDisplayAppendixMatches from "./configurationHelpers"

/**
 * ConfigurationVisualizations: Component to render visualization toggles for configuring the view of
 * the account metrics
 * @returns The component rendering
 */
export default function ConfigurationVisualizations() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  const dispatch = useDispatch()
  const { listID } = useParams()

  // Global state
  const list = useSelector((state) => state.list.list)
  const updateListForm = useSelector((state) => state.listConfiguration.updateListForm)

  // Local state
  const [ expanded, setExpanded ] = React.useState(false)
  const [ open, setOpen ] = React.useState<boolean>(false)
  const [ highlightsToggleCount, setHighlightsToggleCount ] = useState<number>(0)
  const [ switchBeingDragged, setSwitchBeingDragged ] = React.useState<AudienceGroup | GraphQL.SuggestionListToggleInput>()

  // Create list of metrics for each section
  const accountInsights = React.useMemo((): GraphQL.SuggestionListToggleInput[] => (
    DefaultSuggestionListToggles.filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.AccountInsightsToggles)
  ), [ ])
  const audienceDisplay = React.useMemo((): GraphQL.SuggestionListToggleInput[] => (
    DefaultSuggestionListToggles.filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.AudienceToggles)
  ), [ ])
  const contentDisplay = React.useMemo((): GraphQL.SuggestionListToggleInput[] => (
    DefaultSuggestionListToggles.filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.ContentToggles)
  ), [ ])

  /**
   * React hook to handle mouse up or drag end event for drag and drop outside
   * the drop zones
   */
  React.useEffect(() => {
    // Function to handle mouse up
    const dragEndHandler = () => {
      // Turn off dragging event
      setSwitchBeingDragged(undefined)
    }

    // Add the event listener
    document.addEventListener("dragend", dragEndHandler)

    // Remove listener when we leave page
    return () => {
      document.removeEventListener("dragend", dragEndHandler)
    }
  }, [ ])

  /**
   * handleToggle:  Open and closes accordions in the list
   */
  const handleToggle = () => {
    setExpanded(!expanded)
  }

  /**
   * isToggleChecked: Checks to see if the given toggle has been checked
   * @param toggleName The name/code of the toggle
   * @returns True if it's been checked, otherwise false
   */
  const isToggleChecked = (toggleName: string): boolean => {
    if (updateListForm && updateListForm.toggles) {
      return (Array.isArray(updateListForm.toggles))
        ? updateListForm.toggles.map((t) => t.name).includes(toggleName)
        : updateListForm.toggles.name === toggleName
    }
    return false
  }

  /**
   * getSectionCounter: Retrieves the counter value for all the toggles in the accordion section
   * @param sectionToggleNames The list of toggle names
   * @returns The number of toggles that have been turned on
   */
  const getSectionCounter = (sectionToggleNames: GraphQL.SuggestionListToggleInput[]): number => {
    let counter = 0
    sectionToggleNames.forEach((toggle) => {
      if (isToggleChecked(toggle.name)) {
        counter += 1
      }
    })

    return counter
  }

  /**
   * saveToggles: Saves the toggle state to the database, which included the order of the
   * toggles.
   */
  const saveToggles = () => {
    // Make the call
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully saved toggles!"),
        }))
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: "Unable to save toggles. Please try again later!",
        }))
      },
    }))
  }

  /**
   * renderHighlights: Renders the highlights
   * @returns JSX Element for rendering highlights
   */
  const renderHighlights = (): React.JSX.Element => {
    if (isSuccess(list)) {
      return (
        <>
          <Highlights />
          <HighlightsDisplay setActiveToggles={ setHighlightsToggleCount } />
        </>
      )
    }

    return <LoadingIndicatorCard />
  }

  // Main return value for rendering visualizations
  return (
    <div className="cp_list-configuration-visualizations cp_lcv">
      <div className="cp_lcv-top">
        <h2 className="cp_lcv-heading">{ translate("VISUALIZATIONS") }</h2>
        <IconButton
          id="open-accounts-data-point-modal-button"
          className="cp_lcv-help-icon"
          onClick={ () => setOpen(true) }
        >
          <HelpOutline fontSize="small" />
        </IconButton>
      </div>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion highlights"
        elevation={ 0 }
        id="cp_lcv-accordion_highlights"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary"
          expandIcon={ expanded ? <Remove className="cp_lcv-accordion-icon-md" /> : <Add className="cp_lcv-accordion-icon-md" /> }
          aria-controls="highlights-content"
          id="highlights-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Highlights") }</h3>
          <span id="highlightsCounter" className="summary-counter">{ highlightsToggleCount }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          { renderHighlights() }
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion account_insights"
        elevation={ 0 }
        id="cp_lcv-accordion_account_insights"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="account-insights-content"
          id="account-insights-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Account Insights") }</h3>
          <span id="acctInsigtsCounter" className="summary-counter">{ getSectionCounter(accountInsights) }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <AccountInsightToggles isDragging={ (switchBeingDragged !== undefined) } setDraggingInfo={ setSwitchBeingDragged } />
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion audience"
        elevation={ 0 }
        id="cp_lcv-accordion_audience"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary  has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="audience-content"
          id="audience-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Audience") }</h3>
          <span id="audienceCounter" className="summary-counter">{ getSectionCounter(audienceDisplay) }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <AudienceDisplayToggles isDragging={ (switchBeingDragged !== undefined) } setDraggingInfo={ setSwitchBeingDragged } />
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion content"
        elevation={ 0 }
        id="cp_lcv-accordion_content"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary  has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="content-content"
          id="content-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Content") }</h3>
          <span id="contentCounter" className="summary-counter">{ getSectionCounter(contentDisplay) }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <ContentDisplayToggles isDragging={ (switchBeingDragged !== undefined) } setDraggingInfo={ setSwitchBeingDragged } />
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion appendix last"
        elevation={ 0 }
        id="cp_lcv-accordion_appendix"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary"
          expandIcon={ expanded ? <Remove className="cp_lcv-accordion-icon-md" /> : <Add className="cp_lcv-accordion-icon-md" /> }
          aria-controls="appendix-content"
          id="appendix-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Appendix") }</h3>
          <span id="contentCounter" className="summary-counter">
            {
            (updateListForm?.toggles && Array.isArray(updateListForm?.toggles))
              ? countDisplayAppendixMatches(updateListForm.toggles)
              : 0
          }
          </span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          <Appendix />
          <AppendixDisplay />
        </AccordionDetails>
      </Accordion>
      <div className="cp_lcv-save-toggles-button">
        <Button
          id="save-toggles-button"
          label={ translate("Save") }
          isPrimary={ true }
          fullWidth={ true }
          onClick={ saveToggles }
        />
      </div>
      <ModalListAccountsDataPoint open={ open } setOpen={ setOpen } />
    </div>
  )
}
