import React, {
  useCallback,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { Add } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import SearchBar from "../SearchBar"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import CustomerUserHeader from "./CustomerUserHeader"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import UserCampaignsTable from "./UserCampaignsTable"
import Button from "../Button"
import { Scope } from "../../util/types"
import {
  searchCustomerUserCampaigns,
  resetAllFields,
  setSearching,
  setCampaignSearchInput,
  searchMoreCustomerUserCampaigns,
  setPage,
} from "../../state/customerUserPageSlice"
import { openBrandManagersPermissionsModal } from "../../state/brandManagersPermissionsModal"

export default function CustomerOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerUserOverview" })
  const dispatch = useDispatch()

  const { customerID, userID } = useParams()

  const { scopes } = useSelector(({ user }) => user)
  const {
    campaignsStatus,
    userStatus,
    campaignSearchInput,
    searching,
    moreCampaigns,
    page,
  } = useSelector(({ customerUserPageSlice }) => customerUserPageSlice)

  useEffect(() => () => {
    dispatch(resetAllFields())
  }, [])

  const setSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setCampaignSearchInput(e.target.value)),
    [],
  )

  const loadQuery = async () => {
    if (!customerID || !userID) return
    dispatch(setSearching(true))
    await dispatch(searchCustomerUserCampaigns({
      customerId: customerID,
      userId: userID,
      startsWith: campaignSearchInput,
      // page: 1,
      // limit: 1000,
    }))
    dispatch(setPage(1))
    dispatch(setSearching(false))
  }

  const fetchMore = async () => {
    if (!customerID || !userID || !API.isSuccess(campaignsStatus)) return
    const initialLength = campaignsStatus.payload.searchCampaignForCustomerUser.rows.length
    const fetchedCount = moreCampaigns.length + initialLength
    if (fetchedCount <= initialLength
    ) {
      dispatch(setSearching(true))
      await dispatch(searchMoreCustomerUserCampaigns({
        customerId: customerID,
        userId: userID,
        startsWith: campaignSearchInput,
        page: page + 1,
        limit: 50,
      }))
      setPage(page + 1)
      dispatch(setSearching(false))
    }
  }

  useEffect(() => {
    loadQuery()
  }, [ campaignSearchInput ])

  const campaigns = useMemo(() => {
    if (!API.isSuccess(campaignsStatus)) return []
    return campaignsStatus.payload.searchCampaignForCustomerUser.rows.concat(moreCampaigns)
  }, [ campaignsStatus, moreCampaigns ])

  const renderHeaderContent = () => {
    if (userStatus === "loading" || userStatus === "init") {
      return <LoadingIndicatorCard size={ 50 } />
    }

    if (userStatus.status === "error") {
      return <p>There was an error!</p>
    }

    const { userForCustomerManagement: user } = userStatus.payload
    return <CustomerUserHeader user={ user } customerId={ customerID } />
  }

  return (
    <div id="cp_customer-user-details_container" className="cp_customer-user-details_container">
      { renderHeaderContent() }
      <div className="cp_customer-user-table-container">
        <SearchBar
          id="cp_customer-user-table-search-bar"
          className="cp_customer-user-table-search-bar"
          searchPlaceholderText={ translate("Search") }
          onChange={ setSearch }
          lastSubmittedSearch={ campaignSearchInput }
        />
        {
          scopes.includes(Scope.DEVELOPMENT)
          && scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT)
          && (
          <Button
            className="cp_customer-user-table-add-campaign-button"
            id="cp_customer-user-table-add-campaign-button"
            label={ (
              <div className="cp_customer-user-table_add-campaign-button-label">
                <Add />
                <p>{ translate("ADD TO CAMPAIGN") }</p>
              </div>
            ) }
            disabled={ !customerID }
            onClick={ () => {
              dispatch(openBrandManagersPermissionsModal())
            } }
          />
          )
    }
      </div>
      <UserCampaignsTable
        campaigns={ campaigns }
        isLoading={ searching }
        fetchMore={ fetchMore }
      />
    </div>
  )
}
