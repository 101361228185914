import React, {
  useCallback,
  useEffect,
  useMemo,
} from "react"
import { Container } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import { useTranslation } from "react-i18next"
import Modal from "../../../../Modal"
import SearchBar from "../../../../SearchBar"
import * as API from "../../../../../util/apiClient"
import * as GraphQL from "../../../../../graphql"
import { useDispatch, useSelector } from "../../../../../state/hooks"
import { getTeamMembers, toggleSelectAll } from "../../../../../state/slidingPanelSlice/profiles"
import LoadingIndicator from "../../../../LoadingIndicator"
import Avatar from "../../../../Avatar"
import {
  setProfileManagementFilterCompare,
  setProfileManagementModalOpen,
  setProfileManagementModalSearchInput,
  toggleSelectedUsers,
} from "../../../../../state/slidingPanelSlice"
import Checkbox from "../../../../Checkbox"
import "./style.sass"
import { pushToast } from "../../../../../state/toastSlice"
import { ToggleMenuValue } from "../../../../Toggle"

type Props = {
    open: boolean
}

export default function ModalManageProfile({ open }: Props) {
  const dispatch = useDispatch()
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalProfileMenu" })

  const toggleOptions: ToggleMenuValue[] = useMemo(() => ([ {
    keyId: uuidv4(),
    label: translate("Name"),
    value: GraphQL.SearchTeamMembersFilterCompareToOption.Name,
  }, {
    keyId: uuidv4(),
    label: translate("Position"),
    value: GraphQL.SearchTeamMembersFilterCompareToOption.Title,
  } ]), [ uuidv4 ])

  const {
    profileManagementModalSearchInput,
    profileManagementProfilesResult,
    profileManagementSelectedIds,
    profileManagementCurrentProfile,
    profileManagementFilterCompare,
  } = useSelector(({ slidingPanels }) => slidingPanels)

  const setSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setProfileManagementModalSearchInput(e.target.value)),
    [],
  )

  useEffect(() => {
    dispatch(getTeamMembers(profileManagementModalSearchInput, profileManagementFilterCompare))
  }, [ profileManagementModalSearchInput, profileManagementFilterCompare ])

  const handleClose = () => {
    dispatch(setProfileManagementModalOpen(false))
  }

  const handleSubmit = async () => {
    handleClose()
    let result
    if (profileManagementCurrentProfile) {
      result = await API.updatePersonalityManagers({
        id: profileManagementCurrentProfile?.id,
        managerIds: profileManagementSelectedIds,
      })
    }

    // If result is not defined, profileManagementCurrentProfile is not defined
    // If either is somehow undefined or there's an API error, result in error toast
    if (!result || API.isError(result)) {
      dispatch(pushToast({
        message: translate("There was an error. Please try again."),
        type: "error",
      }))
    } else {
      dispatch(pushToast({
        message: translate("Managers for this Profile have been updated successfully!"),
        type: "success",
      }))
    }
  }

  const renderContent = () => {
    if (profileManagementProfilesResult === "init" || profileManagementProfilesResult === "loading") {
      return (
        <div className="cp_component_sliding-panels_loading-indicator-container">
          <LoadingIndicator />
        </div>
      )
    }
    if (API.isSuccess(profileManagementProfilesResult)) {
      return (
        <Container className="cp_component_modal-manage-profile_profiles-container">
          { profileManagementProfilesResult.payload.searchUsers.rows.map((row) => (
            <Container
              onClick={ () => { dispatch(toggleSelectedUsers(row.id)) } }
              className="cp_component_modal-manage-profile_profile-container"
            >
              <Checkbox
                checked={ profileManagementSelectedIds.includes(row.id) }
              />
              <Avatar src={ row.contact.avatar.url.address } />
              <div>
                <p>{ row.contact.name }</p>
                <p className="cp_component_modal-manage-profile_sub-info">{ row.contact.email.address }</p>
              </div>
            </Container>
          )) }
        </Container>
      )
    }
    return <p>Error!</p>
  }

  return (
    <Modal
      className="cp_component_modal-manage-profile"
      title={ translate("Manage Access") }
      subtitle={ translate("Select the Users that you would like to have access to this Profile's data.") }
      primaryAction={ handleSubmit }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      primaryLabel={ translate("Update Managers") }
      secondaryLabel={ translate("Cancel") }
      disablePortal={ true }
      open={ open }
    >
      <SearchBar
        searchPlaceholderText={
            profileManagementFilterCompare === GraphQL.SearchTeamMembersFilterCompareToOption.Name
              ? translate("Search by Name")
              : translate("Search by Position") }
        onChange={ setSearch }
        toggleOptions={ toggleOptions }
        toggleValue={ profileManagementFilterCompare }
        setToggleValue={ (option) => dispatch(setProfileManagementFilterCompare(option)) }
      />
      <Container className="cp_component_modal-manage-profile-content">
        <div className="cp_component_modal-manage-profile-header">
          <Checkbox
            onClick={ () => dispatch(toggleSelectAll()) }
            checked={ API.isSuccess(profileManagementProfilesResult)
              && profileManagementSelectedIds.length !== 0
              && profileManagementSelectedIds.length === profileManagementProfilesResult.payload.searchUsers.totalCount }
          />
          <p>{ translate("User Details") }</p>
        </div>
        { renderContent() }
      </Container>
    </Modal>
  )
}
