import React from "react"
import "./style.sass"

import { useParams } from "react-router-dom"

import { SuggestionAccessCodeRestriction } from "../../graphql"
import ListV2PageHeader from "./ListV2PageHeader"
import ListV2PageOverview from "./ListV2PageOverview"
import ListV2PageContent from "./ListV2PageContent"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  fetchAudiences,
  fetchPublicList,
  fetchPublicListSocialProfile,
  fetchTopPosts,
  getInitialPostImageTags,
  getInitialPostKeywords,
  getPostsByImageTag,
  setInfoRequiredModalOpen,
  getSuggestionListPostsByKeyword,
  userCanViewList,
} from "../../state/publicListSlice"
import * as API from "../../util/apiClient"
import ListV2PageDescription from "./ListV2PageDescription"
import ModalInfoRequired from "../PublicList/ModalInfoRequired"

type Props = {
  userIsValid: boolean
  accessCodeRestriction: SuggestionAccessCodeRestriction | undefined | null
}

export default function PublicListV2AuthShell({ userIsValid, accessCodeRestriction }: Props) {
  const { listCode } = useParams()
  const dispatch = useDispatch()

  const {
    selectedListSocialAccount,
    loadedListSocialAccount,
    list,
  } = useSelector(({ publicList }) => publicList)

  const description = React.useMemo((): string | null | undefined => {
    if (API.isSuccess(list)) {
      return list.payload.publicSuggestionListByCode.description
    }
    return undefined
  }, [ list ])

  React.useEffect(() => {
    if (listCode) {
      if (userCanViewList(accessCodeRestriction, userIsValid)) {
        dispatch(fetchPublicList(listCode))
      } else {
        dispatch(setInfoRequiredModalOpen(true))
      }
    }
  }, [ listCode, accessCodeRestriction, userIsValid ])

  React.useEffect(() => {
    if (
      userCanViewList(accessCodeRestriction, userIsValid)
      && listCode
      && selectedListSocialAccount
      && loadedListSocialAccount === "init"
    ) {
      dispatch(fetchPublicListSocialProfile({
        // The field that GraphQL expects implies an ID, but it actually reads a code.
        listId: listCode,
        socialAccountId: selectedListSocialAccount.socialAccount.id,
      }))
    }
  }, [ selectedListSocialAccount ])

  React.useEffect(() => {
    if (API.isSuccess(list) && listCode && selectedListSocialAccount) {
      const { imageTags } = list.payload.publicSuggestionListByCode
      const { keywords } = list.payload.publicSuggestionListByCode
      const { suggestionListCategories: categories } = list.payload.publicSuggestionListByCode
      const alphabetizedImageTags = imageTags.length ? [ ...imageTags ].sort((a, b) => a.name.localeCompare(b.name)) : []
      const alphabetizedKeywordTags = keywords.length ? [ ...keywords ].sort((a, b) => a.name.localeCompare(b.name)) : []
      const socialAccountIds = categories
        .flatMap((category) => category.suggestionListSocialAccounts)
        .map((account) => account.socialAccount.id)
      if (alphabetizedImageTags.length > 0) {
        dispatch(getInitialPostImageTags({
          code: listCode,
          socialAccountId: selectedListSocialAccount.socialAccount.id,
          suggestionListImageTagId: alphabetizedImageTags[0].id,
        }))
      }
      if (alphabetizedKeywordTags.length > 0) {
        dispatch(getInitialPostKeywords({
          code: listCode,
          socialAccountId: selectedListSocialAccount.socialAccount.id,
          keywordId: alphabetizedKeywordTags[0].id,
        }))
      }
      dispatch(fetchAudiences(
        listCode,
        categories
          .map((category) => category.suggestionListSocialAccounts).flat()
          .map((suggestAccount) => suggestAccount.socialAccount.id),
      ))
      dispatch(fetchTopPosts(socialAccountIds, list.payload.publicSuggestionListByCode.id))
      dispatch(getSuggestionListPostsByKeyword(socialAccountIds, list.payload.publicSuggestionListByCode.id))
      dispatch(getPostsByImageTag(socialAccountIds, list.payload.publicSuggestionListByCode.id))
    }
  }, [ selectedListSocialAccount, list ])

  return (
    <div className="cp_page_public-list-page">
      <div className="cp_page_public-list-page-flex">
        <ListV2PageHeader />
        <ListV2PageOverview />
        { description && (
          <ListV2PageDescription description={ description } />
        ) }
        <ListV2PageContent />
      </div>
      <ModalInfoRequired
        codeRestriction={ accessCodeRestriction }
      />
    </div>
  )
}
