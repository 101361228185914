import * as React from "react"
import { useTranslation } from "react-i18next"
import "../style.sass"
import { useSelector } from "../../../../state/hooks"
import * as API from "../../../../util/apiClient"
import "../../AccountInsights/account-insights-details.sass"
import Divider from "../../../Divider"
import {
  Network,
  ToggleFragment,
  SocialAccount,
  SuggestionListToggleGroupType,
} from "../../../../graphql"
import EngagementChartWrapper from "./EngagementChartWrapper"
import EngagementTableWrapper from "./EngagementTableWrapper"
import EngagementMapWrapper from "./EngagementMapWrapper"
import ImageAnalysisWrapper from "./ImageAnalysisWrapper"
import BrandsWrapper from "./BrandsWrapper"
import RecentMediaWrapper from "./RecentMediaWrapper"
import PersonalityTraitsWrapper from "./PersonalityTraitsWrapper"
import RecentPosts from "../../SocialProfileTabs/AccountInsightsDetails/RecentPost"
import { ACCOUNT_INSIGHTS_DISPLAY_TOGGLES, APPENDIX_INSIGHTS_DISPLAY_TOGGLES } from "../../../../util/constant"

type Props = {
  toggles: ToggleFragment[]
  isAppendixTab?: boolean
}

export default function AccountInsightsDetails({ toggles, isAppendixTab }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const {
    listProfile,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const instanceInsightToggles = isAppendixTab ? APPENDIX_INSIGHTS_DISPLAY_TOGGLES : ACCOUNT_INSIGHTS_DISPLAY_TOGGLES

  const socialAccount = React.useMemo(() => API.isSuccess(listProfile)
    ? listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount
    : null, [ listProfile ])

  const shouldDisplayImageAnalysis = React.useMemo(() => {
    if (API.isSuccess(listProfile)) {
      const { socialAccount: { network } } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ listProfile ])

  const shouldDisplayBrands = React.useMemo(() => {
    if (API.isSuccess(listProfile)) {
      const { socialAccount: { network } } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ listProfile ])

  /**
   * insightsToggles: React hook to filter the toggles to only show the account insights toggles
   */
  const insightsToggles = React.useMemo(() => {
    if (isAppendixTab) {
      const codes = Object.values(instanceInsightToggles)
      return toggles.filter((t) => codes.includes(t.name))
    }

    return toggles
      .filter(({ type }) => type === SuggestionListToggleGroupType.AccountInsightsToggles)
      .sort((a, b) => a.order - b.order)
  }, [ toggles ])

  /**
   * rednerWidget: Render the widget based on the toggle value
   * @param toggle The toggle to be rendered
   * @returns The JSX element to be rendered
   */
  const rednerWidget = (toggle: ToggleFragment): React.JSX.Element | null => {
    switch (toggle.name) {
      case instanceInsightToggles.ENGAGEMENT_RATE_OVER_TIME:
        return (
          <div className="cp_account-insights-details_component-widget">
            <EngagementChartWrapper />
          </div>
        )
      case instanceInsightToggles.ENGAGEMENT_RATE_BY_POST_TYPE:
        return (
          <div className="cp_account-insights-details_component-widget">
            <div>
              <h6 className="cp_account-insights-details_component-widget-title">
                { translate("Engagement by Post Type") }
              </h6>
              <Divider />
              <EngagementTableWrapper
                displayPostType={ true }
                displayPostTypeReel={ false }
              />
            </div>
          </div>
        )
      case instanceInsightToggles.ENGAGEMENT_RATE_BY_POST_TYPE_REEL:
        return (
          <div className="cp_account-insights-details_component-widget">
            <div>
              <h6 className="cp_account-insights-details_component-widget-title">
                { translate("Engagement by Post Type") }
              </h6>
              <Divider />
              <EngagementTableWrapper
                displayPostType={ false }
                displayPostTypeReel={ true }
              />
            </div>
          </div>
        )
      case instanceInsightToggles.ENGAGEMENTS_HEAT_MAP:
        return (
          <div className="cp_account-insights-details_component-widget">
            <EngagementMapWrapper />
          </div>
        )
      case instanceInsightToggles.RECENT_MEDIA_AI_IMAGE_ANALYSIS:
        return (
          <div className="cp_account-insights-details_component-row">
            { shouldDisplayImageAnalysis && (
            <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-2">
              <div>
                <h6 className="cp_account-insights-details_component-widget-title">
                  { translate("AI Image Analysis") }
                </h6>
                <Divider />
                <ImageAnalysisWrapper />
              </div>
            </div>
            ) }
            <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-1">
              <div>
                <h6 className="cp_account-insights-details_component-widget-title">
                  { translate("Recent Media") }
                </h6>
                <Divider />
                <RecentMediaWrapper />
              </div>
            </div>
          </div>
        )
      case instanceInsightToggles.RECENT_MEDIA_STORIES:
        if ((socialAccount?.oauthed && socialAccount?.recentStories.length > 0)) {
          return (
            <div className="cp_account-insights-details_component-widget">
              <div>
                <h6 className="cp_account-insights-details_component-widget-title">
                  { translate("Recent Stories") }
                </h6>
                <Divider />
                <RecentPosts socialAccount={ socialAccount as SocialAccount } />
              </div>
            </div>
          )
        }
        return null
      case instanceInsightToggles.BRANDS_MENTIONED_BRANDS_WORKED_WITH:
        if (shouldDisplayBrands) {
          return (
            <div className="cp_account-insights-details_component-widget">
              <h6 className="cp_account-insights-details_component-widget-title">
                { translate("Brands") }
              </h6>
              <Divider />
              <BrandsWrapper
                displayBrandsMentionedWorked={ true }
                displayBrandsDetected={ true }
              />
            </div>
          )
        }
        return null
      case instanceInsightToggles.PI_TRAITS:
        return (
          <div className="cp_account-insights-details_component-widget">
            <PersonalityTraitsWrapper />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="cp_account-insights-details_component">
      { insightsToggles.map((toggle) => rednerWidget(toggle)) }
    </div>
  )
}
