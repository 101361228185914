import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES,
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
} from "../../util/constant"

import Tabs, { TabLabelProps } from "../../component/Tabs"
import { isSuccess } from "../../util/apiClient"
import {
  fetchPublicList,
  loadToggleOrder,
  loadToggles,
} from "../../state/publicListSlice"
import { SuggestionListToggleGroupType, ToggleFragment } from "../../graphql"
import AccountInsightsV2 from "../../component/PublicListAccountV2/Tabs/AccountInsightsV2"
import AudienceV2 from "../../component/PublicListAccountV2/Tabs/AudienceV2"
import LoadingIndicator from "../../component/LoadingIndicator"
import ContentV2 from "../../component/PublicListAccountV2/Tabs/ContentV2"
import AccountAppendixWrapper from "../../component/ProfileTabs/ListSocialProfileTabs/AccountAppendixWrapper"

/**
 * AccountPageTabLabel: The tabs label information
 */
interface AccountPageTabLabel extends TabLabelProps {
  path: AccountPageTab.AccountInsights | AccountPageTab.Audience | AccountPageTab.Content | AccountPageTab.Appendix
}

/**
 * AccountPageTab: Enumerated type to handle switching between tabs
 */
// eslint-disable-next-line no-shadow
enum AccountPageTab {
  AccountInsights = "insights",
  Audience = "audience",
  Content = "content",
  Appendix = "appendix"
}

/**
 * AccountPageTabs: Renders the tabs and content of the tabs
 * @returns The JSX Elements to render the tab data
 */
export default function AccountPageTabs() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.PublicListAccountPage" })
  const {
    listCode, tabPath, contentTabPath,
  } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    list,
    initialPostKeywords,
    initialPostsImageTags,
    loadedListSocialAccount,
    toggles: publicToggles,
  } = useSelector(({ publicList }) => publicList)

  useEffect(() => {
    if (listCode) {
      dispatch(fetchPublicList(listCode))
    }
  }, [ listCode ])

  // loadToggles is a somewhat expensive calculation (n^2).
  // memoized listToggleValue is to make sure it only recalculates when it needs to
  const listToggleValue: ToggleFragment[] | null = React.useMemo(() => {
    if (isSuccess(list)) {
      return list.payload.publicSuggestionListByCode.toggles
    }
    return null
  }, [ list ])

  React.useEffect(() => {
    if (listToggleValue) {
      dispatch(loadToggles(listToggleValue))
      dispatch(loadToggleOrder(listToggleValue, SuggestionListToggleGroupType.AccountInsightsToggles))
      dispatch(loadToggleOrder(listToggleValue, SuggestionListToggleGroupType.AudienceToggles))
      dispatch(loadToggleOrder(listToggleValue, SuggestionListToggleGroupType.ContentToggles))
    }
  }, [ listToggleValue ])

  const checkDisplayAppendixTab = (toggles: string[]) => {
    const ALL_TOGGLE_VALUES = new Set([
      ...Object.values(APPENDIX_AUDIENCE_DISPLAY_TOGGLES),
      ...Object.values(APPENDIX_CONTENT_DISPLAY_TOGGLES),
      ...Object.values(APPENDIX_INSIGHTS_DISPLAY_TOGGLES),
    ])
    return toggles.some((toggle) => ALL_TOGGLE_VALUES.has(toggle))
  }

  const [
    displayImageTagPosts,
    displayKeywordPosts,
    displayTopPosts,
  ] = React.useMemo((): boolean[] => {
    if (isSuccess(loadedListSocialAccount) && publicToggles) {
      const {
        cippusFeatured,
      } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
      const displayFeatured = cippusFeatured.length > 0
      const displayImageTags = isSuccess(initialPostsImageTags)
        ? initialPostsImageTags.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.imageTagsWithPosts.length > 0
        : false
      const displayKeywords = isSuccess(initialPostKeywords)
        ? initialPostKeywords.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.keywordsWithPosts.length > 0
        : false

      return [
        displayImageTags,
        publicToggles.displayKeywords && displayKeywords,
        publicToggles.displayTopPosts && displayFeatured,
        publicToggles.displayKeywords && (displayImageTags || displayKeywords),
      ]
    }
    return new Array(4).fill(false)
  }, [ loadedListSocialAccount, publicToggles, initialPostKeywords, initialPostsImageTags ])

  // React hook to generate all the tabs for the page
  const tabs: AccountPageTabLabel[] = React.useMemo(() => {
    if (!listToggleValue) {
      return []
    }

    const activeToggles = new Set(listToggleValue.map((toggle) => toggle.name))
    const tabDefinitions = [
      {
        toggles: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES, label: "Account Insights", path: AccountPageTab.AccountInsights,
      },
      {
        toggles: AUDIENCE_DISPLAY_TOGGLES, label: "Audience", path: AccountPageTab.Audience,
      },
      {
        toggles: CONTENT_DISPLAY_TOGGLES, label: "Content", path: AccountPageTab.Content,
      },
    ]

    const t: AccountPageTabLabel[] = tabDefinitions
      .filter(({ toggles }) => Object.values(toggles).some((name) => activeToggles.has(name)))
      .map(({ label, path }) => ({ label: translate(label), path }))

    const activeToggleCodes = listToggleValue.map((toggle) => toggle.name)
    if (checkDisplayAppendixTab(activeToggleCodes)) t.push({ label: translate("Appendix"), path: AccountPageTab.Appendix })

    return t
  }, [ listToggleValue ])

  const tabIndex: number = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === tabPath)
    return foundTab ? tabs.indexOf(foundTab) : 0
  }, [ tabPath, tabs ])

  // React hook to generate the content of the selected tab
  const tabContent = React.useMemo(() => {
    if (tabs.length === 0) return null
    const currTabPath: AccountPageTab = tabs[tabIndex].path

    switch (currTabPath) {
      case AccountPageTab.AccountInsights:
        return <AccountInsightsV2 />
      case AccountPageTab.Audience:
        return <AudienceV2 />
      case AccountPageTab.Content:
        return (
          <ContentV2
            displayImageTagPosts={ displayImageTagPosts }
            displayKeywordPosts={ displayKeywordPosts }
            displayTopPosts={ displayTopPosts }
          />
        )
      case AccountPageTab.Appendix:
        return <AccountAppendixWrapper toggles={ listToggleValue || [] } />
      default:
        return <p>Error or this tab does not exist yet</p>
    }
  }, [ tabIndex, tabs ])

  const setTab = (currTabIndex: number) => {
    const newTabPath = tabs[currTabIndex].path
    if (window.location.pathname.includes(`/${ newTabPath }`)) return
    const pathPrefix = contentTabPath ? "../../" : (tabPath ? "../" : "")
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  React.useEffect(() => {
    if (tabs.length === 0) return
    if (tabIndex) {
      setTab(tabIndex)
    } else {
      setTab(0)
    }
  }, [ tabs ])

  if (tabPath === undefined || (!listToggleValue || tabContent === null)) {
    return (
      <div className="cp_component_pl-account-page-content-tabs">
        <LoadingIndicator flexWrapperEnabled={ true } size={ 48 } />
      </div>
    )
  }

  // The JSX Element to be rendered
  return (
    <div className="cp_component_pl-account-page-content-tabs">
      <Tabs
        className="cp_account-page-tabs"
        handleChange={ setTab }
        tabs={ tabs }
        defaultValue={ tabIndex }
        controlledValue={ tabIndex }
        controls={ (
          <>
            <p className="cp_account-page-tabs-hidden-placeholder">
              Placeholder
            </p>
          </>
        ) }
      />
      <div className="cp_account-page-tabs-content">
        { tabContent }
      </div>
    </div>
  )
}
