import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import Location from "../../Audience/Location"
import "../../Audience/index.sass"
import { ToggleFragment } from "../../../../graphql"

type Props = {
  toggles?: ToggleFragment[]
  displayCities: boolean
  displayCountries: boolean
  displayStates: boolean
}

export default function LocationWrapper({
  toggles, displayCities, displayCountries, displayStates,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.Location" })
  const {
    audience,
    list,
    selectedListSocialAccount,
  } = useSelector(({ publicList }) => publicList)
  if (audience === "loading" || audience === "init" || list === "loading" || list === "init" || !selectedListSocialAccount) {
    return (
      <LoadingIndicatorCard />
    )
  }
  if (audience.status === "error" || list.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <Location
      toggles={ toggles }
      audienceDemographics={ audience.payload.publicSocialAccount.audienceDemographics }
      socialProfile={ selectedListSocialAccount.socialAccount }
      displayCities={ displayCities }
      displayCountries={ displayCountries }
      displayStates={ displayStates }
    />
  )
}
