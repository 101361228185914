import React from "react"
import "./style.sass"

// Library imports
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useCookies } from "react-cookie"

// Component imports
import Input from "../../component/Input"
import Button from "../../component/Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { isSuccess } from "../../util/apiClient"
import { setInfoRequiredModalOpen, submitVerification } from "../../state/publicListSlice"
import LoadingIndicatorCard from "../../component/LoadingIndicatorCard"
import LoadingIndicator from "../../component/LoadingIndicator"

/**
 * ReqInfoContainer: The form portion of the page, allowing the user to fill in the
 * required information.
 * @returns JSX Elements for the form
 */
export default function ReqInfoContainer() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.RequiredInformation" })
  const dispatch = useDispatch()
  const { listCode, accountCode } = useParams()

  // Global state variables
  const {
    list,
    userVerification,
    accessCodeCookieKey,
    companyCookieKey,
    nameCookieKey,
    infoRequiredSubmitCallback,
  } = useSelector((state) => state.publicList)

  // Local state and cookies
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ accessCodeCookieKey ])
  const [ listName, setListName ] = React.useState<string>("")
  const [ companyName, setCompanyName ] = React.useState<string>()
  const [ hasClientInformation, setHasClientInformation ] = React.useState<boolean>(false)
  const [ accessCode, setAccessCode ] = React.useState<string>("")
  const [ name, setName ] = React.useState<string>("")
  const [ company, setCompany ] = React.useState<string>("")
  const [ accessCodeError, setAccessCodeError ] = React.useState<boolean>(false)
  const [ submitted, setSubmitted ] = React.useState<boolean>(false)
  const [ nameError, setNameError ] = React.useState<boolean>(false)
  const [ companyError, setCompanyError ] = React.useState<boolean>(false)

  /**
   * React hook to pull the accesscode in order to verify what user places in input
   */
  const requiredAccessCode = React.useMemo((): string | undefined => {
    if (isSuccess(list)) {
      return list.payload.publicSuggestionListByCode.suggestionAccessCode?.accessCode
    }
    return undefined
  }, [ list ])

  /**
   * React hook to load the name of the list and to check and see if there is
   * client information for this list
   */
  React.useEffect(() => {
    if (listCode) {
      if (isSuccess(list)) {
        const { publicSuggestionListByCode: suggestionList } = list.payload
        setListName(suggestionList.name)
        setHasClientInformation(suggestionList.clients.length > 0)
        if (suggestionList.clients.length === 1) {
          setCompanyName(suggestionList.clients[0].company.name)
        }
      }
    }
  }, [ list ])

  /**
   * React hook to get the code restrictions for the current user
   */
  const codeRestriction = React.useMemo(() => {
    if (isSuccess(userVerification)) {
      return userVerification.payload.publicVerifySuggestionListAccessCodeByListCode.suggestionAccessCodeRestriction
    }
    return undefined
  }, [ userVerification ])

  /**
   * verifyForm: Checks the form fields to verify that they are filled in
   * @returns True if valid form, otherwise false
   */
  const verifyForm = (): boolean => {
    // Set default state
    let goodForm = true

    // Check the access code value check to make sure it's visible
    if (requiredAccessCode && accessCode.trim() !== requiredAccessCode) {
      setAccessCodeError(true)
      goodForm = false
    }

    // Check the name field value
    if (name.trim() === "") {
      setNameError(true)
      goodForm = false
    }

    // Check the company name value
    if (company.trim() === "") {
      setCompanyError(true)
      goodForm = false
    }

    // Return form state
    return goodForm
  }

  /**
   * submitForm: function to submit for approval or adding a feedback.
   */
  const submitForm = async () => {
    // Make sure values are filled in
    if (!verifyForm()) return

    // Set form as submitted
    setSubmitted(true)

    // Check the list and account codes exist
    if (listCode && accountCode) {
      // Check to see if there is an issue
      const issue = await dispatch(submitVerification(listCode, accessCode, codeRestriction))

      // If it has client information and there is an issue show the error on the form
      if (hasClientInformation) setAccessCodeError(issue)

      // Check to see if there is an issue
      if (!issue) {
        // Set cookie information that will allow the user to continue to approve and/or
        // leave a feedback when returned to the public list page
        setCookie(accessCodeCookieKey, accessCode, { path: "/" })
        setCookie(companyCookieKey, company, { path: "/" })
        setCookie(nameCookieKey, name, { path: "/" })
        dispatch(setInfoRequiredModalOpen(false))

        // Make the callback
        infoRequiredSubmitCallback()
      }
    }
  }

  // Return the JSX code
  return (
    <div className="required-information-container">
      <div className="required-information-container-content">
        { (list === "init" || list === "loading")
          ? <LoadingIndicatorCard />
          : (
            <>
              <div className="required-information-container-content-header">
                <div className="required-information-container-content-header-logo" />
                <p className="required-information-container-content-header-title">{ listName }</p>
                { (companyName) && (<p className="required-information-container-content-header-sub-title">{ companyName }</p>) }
              </div>
              <div className="required-information-container-content-body">
                <Stack spacing={ 3 } width="381px">
                  { (requiredAccessCode) && (
                    <Input
                      id="access-code-input-field"
                      className="required-information-container-content-body-input"
                      label={ translate("Access Code*") }
                      fullWidth={ true }
                      value={ accessCode }
                      error={ accessCodeError }
                      helperText={ (accessCodeError)
                        ? translate("The access code you entered is incorrect. Please try again.")
                        : undefined
                      }
                      onChange={ (e) => {
                        setAccessCode(e.currentTarget.value)
                        setAccessCodeError(false)
                      } }
                    />
                  ) }
                  <Input
                    id="name-input-field"
                    className="required-information-container-content-body-input"
                    label={ translate("Your Name*") }
                    fullWidth={ true }
                    value={ name }
                    onChange={ (e) => { setName(e.currentTarget.value) } }
                    error={ nameError }
                    helperText={ (nameError)
                      ? translate("The name is a required field. Please fill in!")
                      : undefined
                    }
                  />
                  <Input
                    id="company-input-field"
                    className="required-information-container-content-body-input"
                    label={ translate("Company*") }
                    fullWidth={ true }
                    value={ company }
                    onChange={ (e) => { setCompany(e.currentTarget.value) } }
                    error={ companyError }
                    helperText={ (companyError)
                      ? translate("The company is a required field. Please fill in!")
                      : undefined
                    }
                  />
                </Stack>
              </div>
              <div className="required-information-container-content-footer">
                <Button
                  className="required-information-container-content-footer-button"
                  label={ (submitted) ? <LoadingIndicator /> : translate("View List") }
                  isPrimary={ true }
                  fullWidth={ true }
                  onClick={ submitForm }
                />
                <p className="required-information-container-content-footer-assistance-text">
                  { translate("If you need assistance accessing the list, please contact your representative.") }
                </p>
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}
