import React, { useCallback, useEffect } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import EntityInfoRow from "../EntityInfoRow"
import LoadingIndicator from "../LoadingIndicator"
import ModalAddTo from "../ModalAddTo"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  closeSelectCampaignModal,
  getCampaigns,
  toggleSelectedCampaign,
  setSubmitLoading,
} from "../../state/selectCampaignModalSlice"
import { openNewDeliverableModal } from "../../state/campaignDeliverableModalSlice"

export default function ModalSelectCampaign() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalSelectCampaign" })
  const dispatch = useDispatch()
  const {
    openSelectDeliverableModal,
    campaignsQuery,
    selectedCampaigns,
    submitCallback,
    submitLoading,
  } = useSelector(({ selectCampaignModalSlice }) => selectCampaignModalSlice)

  useEffect(() => {
    if (openSelectDeliverableModal) dispatch(getCampaigns(""))
  }, [ openSelectDeliverableModal ])

  const fetchData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(getCampaigns(e.target.value)),
    [],
  )

  const renderContent = () => {
    if (campaignsQuery === "init" || campaignsQuery === "loading") {
      return (
        <div className="cp_component-modal-add-to-buffer-container">
          <LoadingIndicator />
        </div>
      )
    }
    if (API.isSuccess(campaignsQuery)) {
      const idsAlreadyAdded = selectedCampaigns.map((row) => row.id)
      const campaigns = campaignsQuery.payload.searchCampaign.rows
      return campaigns.map((campaign) => {
        const alreadyAdded = idsAlreadyAdded.includes(campaign.id)
        const checked = alreadyAdded
        const disabled = selectedCampaigns.length >= 1 && !checked
        const className = [ "cp_component-modal-add-to-entity-row" ]
        if (alreadyAdded) className.push("row-disabled")
        return (
          <EntityInfoRow
            key={ campaign.id }
            onClick={ () => {
              if (!disabled) dispatch(toggleSelectedCampaign(campaign))
            } }
            checkboxEnabled={ true }
            checkboxDisabled={ disabled }
            checkboxValue={ checked }
            name={ campaign.name }
            avatarSrc={ campaign.thumbnail?.url.address }
            subInfo={ `${ campaign.creator.username } | ${ dayjs(campaign.created, "X")
              .format(Constant.LONGFORM_DATE) }` }
          />
        )
      })
    }
    return <p className="cp_component-modal-add-to-error">Error!</p>
  }
  return (
    <ModalAddTo
      title={ translate("Select a Campaign") }
      subtitle={ translate("Choose a campaign for your deliverable.") }
      selectedEntityCount={ selectedCampaigns.length }
      open={ openSelectDeliverableModal }
      submitLoading={ submitLoading }
      closeAction={ () => dispatch(closeSelectCampaignModal()) }
      primaryAction={ async () => {
        if (submitCallback) await submitCallback(selectedCampaigns)

        dispatch(setSubmitLoading(true))

        const result = await API.fetchCampaign({
          campaignId: selectedCampaigns[0].id,
        })

        dispatch(setSubmitLoading(false))

        if (API.isSuccess(result)) {
          dispatch(closeSelectCampaignModal())
          dispatch(openNewDeliverableModal({
            socialAccounts: result.payload.campaign.campaignNetworkAccounts,
          }))
        }
      } }
      fetchData={ fetchData }
      disabled={ selectedCampaigns.length === 0 }
    >
      { renderContent() }
    </ModalAddTo>
  )
}
