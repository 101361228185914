import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../util/apiClient"
import * as Constant from "../../../../util/constant"
import AudienceAuthenticityCharts from "../../SocialProfileTabs/AccountAudienceDetails/AudienceAuthenticityCharts"
import AffinitiesWrapper from "./AffinitiesWrapper"
import BasicChartWrapper from "./BasicChartWrapper"
import Divider from "../../../Divider"
import EthnicityChartWrapper from "./EthnicityChartWrapper"
import FamilyChartWrapper from "./FamilyChartWrapper"
import FollowersChartWrapper from "./FollowersChartWrapper"
import GenderChartWrapper from "./GenderChartWrapper"
import LanguagesChartWrapper from "./LanguagesChartWrapper"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import LocationWrapper from "./LocationWrapper"
import MaritalChartWrapper from "./MaritalChartWrapper"
import ReligionsChartWrapper from "./ReligionsChartWrapper"
import VocationWrapper from "./VocationWrapper"
import { AUDIENCE_DISPLAY_TOGGLES, APPENDIX_AUDIENCE_DISPLAY_TOGGLES } from "../../../../util/constant"
import {
  SuggestionListToggle,
  SuggestionListToggleGroupType,
  ToggleFragment,
} from "../../../../graphql"
import { fetchAudience } from "../../../../state/listSocialProfileSlice"
import { getAudienceQualityScores } from "../../../../state/socialProfileSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"

import "../../Audience/index.sass"
import {
  AudienceGroup,
  getGroupName,
  isMemberOfGroup,
} from "../../../ListConfiguration/constants"

type Props = {
  toggles: ToggleFragment[]
  isAppendixTab?: boolean
}

export default function AudienceDetails({ toggles, isAppendixTab }: Props) {
  const { listProfile, audience } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const audienceQualityScore = useSelector(({ socialProfile }) => socialProfile.audienceQualityScore)
  const instanceAudienceToggles = isAppendixTab ? APPENDIX_AUDIENCE_DISPLAY_TOGGLES : AUDIENCE_DISPLAY_TOGGLES
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceDetails" })
  const dispatch = useDispatch()

  useEffect(() => {
    if (listProfile === "init" || listProfile === "loading" || API.isError(listProfile)) return
    dispatch(fetchAudience({
      socialAccountId: listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount.id,
    }))
    if (audienceQualityScore === "init") {
      dispatch(getAudienceQualityScores(listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount.id))
    }
  }, [ listProfile, audienceQualityScore ])

  /**
   * audienceToggles: Creates a list of widgets based on the toggles to display
   */
  const audienceToggles = React.useMemo((): (Omit<SuggestionListToggle, "id"> | AudienceGroup)[] => {
    let filteredToggles: Omit<SuggestionListToggle, "id">[] = []
    if (isAppendixTab) {
      const codes = Object.values(instanceAudienceToggles)
      filteredToggles = toggles.filter((t) => codes.includes(t.name))
    } else {
      filteredToggles = toggles
        .filter(({ type }) => type === SuggestionListToggleGroupType.AudienceToggles)
        .sort((a, b) => a.order - b.order)
    }

    const widgets: (Omit<SuggestionListToggle, "id"> | AudienceGroup)[] = []
    if (filteredToggles.length > 0) {
      // Build array of switches
      filteredToggles.forEach((toggle, index) => {
        if (isMemberOfGroup(toggle)) {
          // Get the name of the group
          const groupName = getGroupName(toggle)

          // Check to see if the group already exists
          const group = widgets.find((g) => g.name === groupName) as AudienceGroup | undefined
          if (group) {
            // Add toggle to group
            group.toggles.push(toggle)
          } else {
            // Create new group
            widgets.push({
              name: groupName,
              order: index,
              toggles: [ toggle ],
            })
          }
        } else {
          // Add the toggle to the list
          widgets.push(toggle)
        }
      })
    }

    return widgets
  }, [ ])

  const genderWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(
      audience.payload.socialAccount.audienceDemographics?.genderStatistics?.female
      && audience.payload.socialAccount.audienceDemographics?.genderStatistics?.male,
    )
  }, [ audience ])
  const maritalWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.familyStatistics?.married
      && audience.payload.socialAccount.audienceDemographics?.familyStatistics?.single)
  }, [ audience ])
  const parentalWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.familyStatistics?.parents)
  }, [ audience ])
  const basicWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.ageGroups.length
      || audience.payload.socialAccount.audienceDemographics?.incomeGroups.length
      || audience.payload.socialAccount.audienceDemographics?.educationLevel.length)
  }, [ audience ])

  const locationWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.cityGroups.length
      || audience.payload.socialAccount.audienceDemographics?.countryGroups.length
      || audience.payload.socialAccount.audienceDemographics?.stateGroups.length)
  }, [ audience ])

  const affinitiesWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.brandAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.interestAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.influenceAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.mediaAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.musicAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.sportAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.hashtagAffinities.length)
  }, [ audience ])

  const vocationWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.occupationGroups.length
      || audience.payload.socialAccount.audienceDemographics?.industryGroups.length
      || audience.payload.socialAccount.audienceDemographics?.employerGroups.length
      || audience.payload.socialAccount.audienceDemographics?.universityGroups.length)
  }, [ audience ])

  const ethnicityWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.ethnicityGroups.length)
  }, [ audience ])
  const languagesWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.languageGroups.length)
  }, [ audience ])
  const religionWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.religionGroups.length)
  }, [ audience ])

  const socialAccount = useMemo(
    () => API.isSuccess(listProfile) ? listProfile.payload
      .suggestionListSocialAccountByListIdSocialAccountId.socialAccount : null,
    [ listProfile ],
  )

  const showAudienceAuthenticity = useMemo(
    () => socialAccount
    && Constant.AUDIENCE_AUTHENTICITY_NETWORKS.includes(socialAccount.network)
    && toggles.some(({ name }) => name === instanceAudienceToggles.AUTHENTICITY),
    [ socialAccount ],
  )

  /**
   * renderWidget: Renders the audience widget
   * @param widget The widget toggle to create
   * @returns The JSX Element
   */
  const renderWidget = (widget: Omit<SuggestionListToggle, "id"> | AudienceGroup): React.JSX.Element | null => {
    switch (widget.name) {
      case instanceAudienceToggles.FOLLOWERS_OVER_TIME:
        return (
          <div className="cp_audience-details_component-widget">
            <FollowersChartWrapper />
          </div>
        )
      case instanceAudienceToggles.AUTHENTICITY:
        if (showAudienceAuthenticity) {
          return <AudienceAuthenticityCharts context="lists" />
        }
        return null
      case instanceAudienceToggles.GENDER:
        if (genderWidgetHasData) {
          return (
            <div className="cp_audience-details_component-widget cp_audience-details_component-column-1">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Gender") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <GenderChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case instanceAudienceToggles.FAMILY_STATUS:
        if (maritalWidgetHasData || parentalWidgetHasData) {
          return (
            <div className="cp_audience-details_component-widget cp_audience-details_component-column-2">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Family") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                { audience === "init" || audience === "loading"
                  ? <LoadingIndicatorCard />
                  : (
                    <>
                      { maritalWidgetHasData ? <MaritalChartWrapper /> : null }
                      { parentalWidgetHasData ? <FamilyChartWrapper /> : null }
                    </>
                  )
                }
              </div>
            </div>
          )
        }
        return null
      case instanceAudienceToggles.ETHNICITIES:
        if (ethnicityWidgetHasData) {
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Ethnicity") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <EthnicityChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case instanceAudienceToggles.LANGUAGES:
        if (languagesWidgetHasData) {
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Languages") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <LanguagesChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case instanceAudienceToggles.RELIGIONS:
        if (religionWidgetHasData) {
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Religion") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <ReligionsChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case instanceAudienceToggles.AFFINITIES:
        if (affinitiesWidgetHasData) {
          return (
            <div className="cp_audience-details_component-widget">
              <div className="cp_audience-details_component">
                <AffinitiesWrapper />
              </div>
            </div>
          )
        }
        return null
      case "Basic":
        if (basicWidgetHasData) {
          const group = widget as AudienceGroup
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Basic") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <BasicChartWrapper
                  toggles={ group.toggles }
                  displayAgeRange={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.AGE_RANGE) }
                  displayIncomeRange={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.INCOME_RANGE) }
                  displayEducationLevel={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.EDUCATION_LEVEL) }
                />
              </div>
            </div>
          )
        }
        return null
      case "Location":
        if (locationWidgetHasData) {
          const group = widget as AudienceGroup
          return (
            <div className="cp_audience-details_component-widget">
              <div className="cp_audience-details_component">
                <h6 className="cp_audience-details_component-widget-title">
                  { translate("Location") }
                </h6>
                <LocationWrapper
                  toggles={ group.toggles }
                  displayCities={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.CITIES) }
                  displayCountries={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.COUNTRIES) }
                  displayStates={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.STATES) }
                />
              </div>
            </div>
          )
        }
        return null
      case "Vocation":
        if (vocationWidgetHasData) {
          const group = widget as AudienceGroup
          return (
            <div className="cp_audience-details_component-widget">
              <div className="cp_audience-details_component">
                <VocationWrapper
                  toggles={ group.toggles }
                  displayOccupations={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.OCCUPATIONS) }
                  displayIndustries={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.INDUSTRIES) }
                  displayEmployers={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.EMPLOYERS) }
                  displayUniversities={ group.toggles.map((t) => t.name).includes(instanceAudienceToggles.UNIVERSITIES) }
                />
              </div>
            </div>
          )
        }
        return null
      default:
        return null
    }
  }

  return (
    <div className="cp_audience-details_component">
      { audienceToggles.map((widget) => renderWidget(widget)) }
    </div>
  )
}
