import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"
import {
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES,
} from "../../util/constant"
import {
  ListTogglesType,
  PublicListApprovalStatus,
  Status,
  defaultListToggles,
} from "../../util/types"
import {
  SocialAccountAudienceDemographics,
  SocialAccountFetchedTopics,
  SocialAccountTaggedTopics,
  TagPosts,
} from "../listConfigurationSlice"
import {
  AudienceGroup,
  getGroupName,
  isMemberOfGroup,
} from "../../component/ListConfiguration/constants"

export type FeedbackComment = {
  author: string
  company: string
  comment: string
  created: number
}

export type AccountApprovalStatus = {
  accountId: string
  status: PublicListApprovalStatus
  comments: FeedbackComment[]
}

// eslint-disable-next-line max-len
export type PublicListSocialAccount = GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQuery["publicSuggestionListSocialAccountByListCodeSocialAccountId"]

export type DetectedBrands = {
  socialAccountId: string
  data: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>
}

export type MentionedBrands = {
  socialAccountId: string
  data: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>
}

export type WorkedWithBrands = {
  socialAccountId: string
  data: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>
}

// List Group Slice Interface and Initial State
export interface PublicListState {
  list: Status<GraphQL.GetPublicListByCodeQuery>
  listNameForInfoModal: Status<GraphQL.GetPublicListNameByCodeQuery>
  // TODO: Change selectedListSocialAccount to just be selectedSocialAccountId, then fill data with loadedListSocialAccount
  selectedListSocialAccount: GraphQL.SuggestionListSocialAccountForPublicListFragment | null | undefined
  loadedListSocialAccount: Status<GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQuery>
  audience: Status<GraphQL.GetPublicAudienceDemographicsQuery>
  userVerification: Status<GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQuery>
  leftPanelStatus: "closed" | "default" | "expanded"
  rightPanelStatus: "closed" | "approve" | "feedback"
  infoRequiredModalOpen: boolean
  accessCodeCookieKey: string
  nameCookieKey: string
  companyCookieKey: string
  dailyEngagementRate: Status<GraphQL.FetchPublicHourlyEngagementSummaryQuery>
  mentionedBrands: MentionedBrands[]
  workedWithBrands: WorkedWithBrands[]
  detectedBrands: DetectedBrands[]
  mentionedPostsStatus: Status<GraphQL.GetPublicMentionedPostsQuery>
  mentionedPostsContent: Array<GraphQL.MentionedPostFragment>
  toggles: ListTogglesType
  topPosts: Status<GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQuery>
  postsByKeyword: Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>
  postsByImageId: Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>
  activeKeywordId: string | null | undefined
  activeImageId: string | null | undefined
  initialPostKeywords: Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>
  initialPostsImageTags: Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>
  postKeywordTags: GraphQL.SuggestionListKeyword[]
  postImageTags: GraphQL.SuggestionListImageTag[]
  infoRequiredSubmitCallback: () => any
  iScores: Status<GraphQL.GetSocialAccountsIScoreQuery>
  audiences: SocialAccountAudienceDemographics[]
  contentTopPosts: SocialAccountFetchedTopics[]
  contentPostKeywords: GraphQL.SuggestionListKeyword[]
  contentKeywordPosts: SocialAccountTaggedTopics[]
  contentPostImageTags: GraphQL.SuggestionListImageTag[]
  contentImageTagPosts: SocialAccountTaggedTopics[]
  leftPanelAccountSelected: number
  leftPanelApprovedStatus: AccountApprovalStatus[]
  leaveFeedback: boolean
  feedbackComment?: string
  selectedCategoryTab: number
  accountInsightToggleOrder: GraphQL.ToggleFragment[]
  audienceToggleOrder: (GraphQL.ToggleFragment | AudienceGroup)[]
  contentToggleOrder: GraphQL.ToggleFragment[]
}

const initialState: PublicListState = {
  list: "init",
  listNameForInfoModal: "init",
  selectedListSocialAccount: undefined,
  loadedListSocialAccount: "init",
  audience: "init",
  userVerification: "init",
  leftPanelStatus: "default",
  rightPanelStatus: "closed",
  infoRequiredModalOpen: false,
  accessCodeCookieKey: "",
  nameCookieKey: "",
  companyCookieKey: "",
  dailyEngagementRate: "init",
  mentionedBrands: [],
  workedWithBrands: [],
  detectedBrands: [],
  mentionedPostsStatus: "init",
  mentionedPostsContent: [],
  toggles: defaultListToggles,
  topPosts: "init",
  postsByKeyword: "init",
  postsByImageId: "init",
  activeKeywordId: null,
  activeImageId: null,
  initialPostKeywords: "init",
  initialPostsImageTags: "init",
  postImageTags: [],
  postKeywordTags: [],
  infoRequiredSubmitCallback: () => {},
  iScores: "init",
  audiences: [],
  contentTopPosts: [],
  contentPostKeywords: [],
  contentKeywordPosts: [],
  contentPostImageTags: [],
  contentImageTagPosts: [],
  leftPanelAccountSelected: 0,
  leftPanelApprovedStatus: [],
  leaveFeedback: false,
  selectedCategoryTab: 0,
  accountInsightToggleOrder: [],
  audienceToggleOrder: [],
  contentToggleOrder: [],
}

// List Group Slice
export const ListSlice = createSlice({
  name: "PublicList",
  initialState,
  reducers: {
    setList: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicListByCodeQuery>>,
    ) => ({
      ...state,
      list: action.payload,
    }),
    setListNameForInfoModal: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicListNameByCodeQuery>>,
    ) => ({
      ...state,
      listNameForInfoModal: action.payload,
    }),
    setSelectedListSocialAccount: (
      state,
      action: PayloadAction<GraphQL.SuggestionListSocialAccountForPublicListFragment | null>,
    ) => ({
      ...state,
      selectedListSocialAccount: action.payload,
    }),
    setLoadedListSocialAccount: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQuery>>,
    ) => ({
      ...state,
      loadedListSocialAccount: action.payload,
    }),
    setAudience: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicAudienceDemographicsQuery>>,
    ) => ({
      ...state,
      audience: action.payload,
    }),
    setUserVerification: (
      state,
      action: PayloadAction<Status<GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQuery>>,
    ) => ({
      ...state,
      userVerification: action.payload,
    }),
    setLeftPanelStatus: (
      state,
      action: PayloadAction<"closed" | "default" | "expanded">,
    ) => ({
      ...state,
      leftPanelStatus: action.payload,
    }),
    setRightPanelStatus: (
      state,
      action: PayloadAction<"closed" | "approve" | "feedback">,
    ) => ({
      ...state,
      rightPanelStatus: action.payload,
    }),
    setInfoRequiredModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      infoRequiredModalOpen: action.payload,
    }),
    setAccessCodeCookieKey: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      accessCodeCookieKey: action.payload,
    }),
    setNameCookieKey: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      nameCookieKey: action.payload,
    }),
    setCompanyCookieKey: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      companyCookieKey: action.payload,
    }),
    setDailyEngagementRate: (
      state,
      action: PayloadAction<Status<GraphQL.FetchPublicHourlyEngagementSummaryQuery>>,
    ) => ({
      ...state,
      dailyEngagementRate: action.payload,
    }),
    setMentionedBrands: (
      state,
      action: PayloadAction<MentionedBrands[]>,
    ) => ({
      ...state,
      mentionedBrands: action.payload,
    }),
    setWorkedWithBrands: (
      state,
      action: PayloadAction<WorkedWithBrands[]>,
    ) => ({
      ...state,
      workedWithBrands: action.payload,
    }),
    setDetectedBrands: (
      state,
      action: PayloadAction<DetectedBrands[]>,
    ) => ({
      ...state,
      detectedBrands: action.payload,
    }),
    setMentionedPostsStatus: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicMentionedPostsQuery>>,
    ) => ({
      ...state,
      mentionedPostsStatus: action.payload,
    }),
    setMentionedPostsContent: (
      state,
      action: PayloadAction<Array<GraphQL.MentionedPostFragment>>,
    ) => ({
      ...state,
      mentionedPostsContent: action.payload,
    }),
    setToggles: (
      state,
      action: PayloadAction<ListTogglesType>,
    ) => ({
      ...state,
      toggles: action.payload,
    }),
    setTopPosts: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQuery>>,
    ) => ({
      ...state,
      topPosts: action.payload,
    }),
    setPostsByKeyword: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>>,
    ) => ({
      ...state,
      postsByKeyword: action.payload,
    }),
    setPostsByImageId: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>>,
    ) => ({
      ...state,
      postsByImageId: action.payload,
    }),
    setActiveImageId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      activeImageId: action.payload,
    }),
    setActiveKeywordId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      activeKeywordId: action.payload,
    }),
    setInitialPostsKeywords: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>>,
    ) => ({
      ...state,
      initialPostKeywords: action.payload,
    }),
    setInitialPostsImageTags: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>>,
    ) => ({
      ...state,
      initialPostsImageTags: action.payload,
    }),
    setPostImageTags: (
      state,
      action: PayloadAction<GraphQL.SuggestionListImageTag[]>,
    ) => ({
      ...state,
      postImageTags: action.payload,
    }),
    setPostKeywordTags: (
      state,
      action: PayloadAction<GraphQL.SuggestionListKeyword[]>,
    ) => ({
      ...state,
      postKeywordTags: action.payload,
    }),
    setInfoRequiredSubmitCallback: (
      state,
      action: PayloadAction<() => any>,
    ) => ({
      ...state,
      infoRequiredSubmitCallback: action.payload,
    }),
    setIScores: (state, action: PayloadAction<Status<GraphQL.GetSocialAccountsIScoreQuery>>) => ({
      ...state,
      iScores: action.payload,
    }),
    setAudienceDemographics: ((state, action: PayloadAction<SocialAccountAudienceDemographics[]>) => ({
      ...state,
      audiences: action.payload,
    })),
    setContentTopPosts: ((
      state,
      action: PayloadAction<SocialAccountFetchedTopics[]>,
    ) => ({
      ...state,
      contentTopPosts: action.payload,
    })),
    setContentPostKeywords: ((state, action: PayloadAction<GraphQL.SuggestionListKeyword[]>) => ({
      ...state,
      contentPostKeywords: action.payload,
    })),
    setContentKeywordPosts: ((
      state,
      action: PayloadAction<SocialAccountTaggedTopics[]>,
    ) => ({
      ...state,
      contentKeywordPosts: action.payload,
    })),
    setContentPostImageTags: ((state, action: PayloadAction<GraphQL.SuggestionListImageTag[]>) => ({
      ...state,
      contentPostImageTags: action.payload,
    })),
    setContentImageTagPosts: ((
      state,
      action: PayloadAction<SocialAccountTaggedTopics[]>,
    ) => ({
      ...state,
      contentImageTagPosts: action.payload,
    })),
    setLeftPanelAccountSelected: (state, action: PayloadAction<number>) => ({
      ...state,
      leftPanelAccountSelected: action.payload,
    }),
    setLeftPanelApprovedStatus: (state, action: PayloadAction<AccountApprovalStatus[]>) => ({
      ...state,
      leftPanelApprovedStatus: action.payload,
    }),
    setLeaveFeedback: (state, action: PayloadAction<boolean>) => ({
      ...state,
      leaveFeedback: action.payload,
    }),
    setFeedbackComment: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      feedbackComment: action.payload,
    }),
    setSelectedCategoryTab: (state, action: PayloadAction<number>) => ({
      ...state,
      selectedCategoryTab: action.payload,
    }),
    setAccountInsightToggleOrder: (state, action: PayloadAction<GraphQL.ToggleFragment[]>) => ({
      ...state,
      accountInsightToggleOrder: action.payload,
    }),
    setAudienceToggleOrder: (state, action: PayloadAction<(GraphQL.ToggleFragment | AudienceGroup)[]>) => ({
      ...state,
      audienceToggleOrder: action.payload,
    }),
    setContentToggleOrder: (state, action: PayloadAction<GraphQL.ToggleFragment[]>) => ({
      ...state,
      contentToggleOrder: action.payload,
    }),
  },
})

export const {
  setList,
  setListNameForInfoModal,
  setSelectedListSocialAccount,
  setLoadedListSocialAccount,
  setUserVerification,
  setLeftPanelStatus,
  setRightPanelStatus,
  setInfoRequiredModalOpen,
  setAccessCodeCookieKey,
  setCompanyCookieKey,
  setNameCookieKey,
  setDetectedBrands,
  setMentionedBrands,
  setMentionedPostsContent,
  setMentionedPostsStatus,
  setWorkedWithBrands,
  setDailyEngagementRate,
  setAudience,
  setToggles,
  setTopPosts,
  setPostsByKeyword,
  setPostsByImageId,
  setActiveImageId,
  setActiveKeywordId,
  setInitialPostsImageTags,
  setInitialPostsKeywords,
  setPostImageTags,
  setPostKeywordTags,
  setInfoRequiredSubmitCallback,
  setIScores,
  setAudienceDemographics,
  setContentTopPosts,
  setContentPostKeywords,
  setContentKeywordPosts,
  setContentPostImageTags,
  setContentImageTagPosts,
  setLeftPanelAccountSelected,
  setLeftPanelApprovedStatus,
  setLeaveFeedback,
  setFeedbackComment,
  setSelectedCategoryTab,
  setAccountInsightToggleOrder,
  setAudienceToggleOrder,
  setContentToggleOrder,
} = ListSlice.actions
export default ListSlice.reducer

// List Group Slice Thunks
export const fetchPublicList = (
  code: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setList("loading"))
  const listResult = await API.fetchPublicListByCode({ code })
  if (API.isSuccess(listResult)) {
    const listCategories = listResult.payload.publicSuggestionListByCode.suggestionListCategories

    // Sometimes suggestionListCategories have no social accounts.
    // Iterate to find first list social account to set to default
    let firstSocialAccount
    let i = 0
    let j = 0
    while (i < listCategories.length) {
      const listCategory = listCategories[i]
      while (j < listCategory.suggestionListSocialAccounts.length) {
        const socialAccount = listCategory.suggestionListSocialAccounts[j]
        if (socialAccount) {
          firstSocialAccount = socialAccount
          j = listCategory.suggestionListSocialAccounts.length
          i = listCategories.length
        }
        j += 1
      }
      i += 1
    }
    if (firstSocialAccount) {
      dispatch(setSelectedListSocialAccount(firstSocialAccount))
    } else {
      dispatch(setSelectedListSocialAccount(null))
    }

    // Create input for gathering IScore values
    const input: GraphQL.GetSocialAccountsIScoreQueryVariables = {
      input: {
        socialAccountIds: listResult.payload.publicSuggestionListByCode.suggestionListCategories
          .map((category) => category.suggestionListSocialAccounts
            .map((account) => account.socialAccount.id)).flat(),
        sexes: listResult.payload.publicSuggestionListByCode.sexes,
        minAge: listResult.payload.publicSuggestionListByCode.minAge,
        maxAge: listResult.payload.publicSuggestionListByCode.maxAge,
        contextualRelevancy: listResult.payload.publicSuggestionListByCode.contextualRelevancy,
      },
    }

    // Query to get the iscores
    const iscores = await API.fetchAccountsIScore(input)

    // Update state
    dispatch(setIScores(iscores))

    // Set image tag names and keywords
    dispatch(setContentPostKeywords(listResult.payload.publicSuggestionListByCode.keywords))
    dispatch(setContentPostImageTags(listResult.payload.publicSuggestionListByCode.imageTags))
  }
  dispatch(setList(listResult))
}

export const fetchPublicListSocialProfile = (
  params: GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setLoadedListSocialAccount("loading"))
  const result = await API.fetchPublicListSocialAccount(params)
  dispatch(setLoadedListSocialAccount(result))
}

export const fetchPublicListName = (
  code: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListNameForInfoModal("loading"))
  const listName = await API.fetchPublicListNameByCode({ code })
  dispatch(setListNameForInfoModal(listName))
}

export const fetchAudience = (
  params: GraphQL.GetPublicAudienceDemographicsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setAudience("loading"))

  const result = await API.fetchPublicListAudienceByCode(params)
  dispatch(setAudience(result))
}
export const approvePublicList = (
  params: {
    suggestionListCode: string,
    suggestionListSocialAccountId: string,
    onSuccess: () => any
  },
) => async () => {
  const result = await API.publicApproveSuggestionListSocialAccount({
    suggestionListCode: params.suggestionListCode,
    suggestionListSocialAccountId: params.suggestionListSocialAccountId,
  })
  if (result.status === "success") {
    params.onSuccess()
  }
}

export const createFeedback = (
  suggestionListCode: string,
  suggestionListSocialAccountId: string,
  message: string,
  onSuccess: () => void,
) => async () => {
  const result = await API.publicCreateSuggestionListSocialAccountFeedback({
    suggestionListCode, suggestionListSocialAccountId, message,
  })
  if (API.isSuccess(result)) {
    // Continue on
    onSuccess()
  }
}

export const createFeedbackV2 = (
  suggestionListCode: string,
  suggestionListSocialAccountId: string,
  message: string,
  onSuccess: () => void,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const result = await API.publicCreateSuggestionListSocialAccountFeedback({
    suggestionListCode, suggestionListSocialAccountId, message,
  })
  if (API.isSuccess(result)) {
    // Pull state for approval status
    const { leftPanelApprovedStatus } = getState().publicList

    if (result.payload) {
      const { publicCreateSuggestionListSocialAccountFeedback: feedback } = result.payload

      // Copy comment to state
      const statuses: AccountApprovalStatus[] = leftPanelApprovedStatus.map((status) => {
        if (status.accountId === suggestionListSocialAccountId) {
          return {
            ...status,
            comments: [ ...status.comments, {
              author: (feedback.author.__typename === "AnonymousUser")
                ? feedback.author.name
                : (feedback.author.__typename === "User")
                  ? feedback.author.contact.name
                  : "",
              company: (feedback.author.__typename === "AnonymousUser")
                ? feedback.author.company
                : (feedback.author.__typename === "User")
                  ? feedback.author.customer.company?.name || ""
                  : "",
              comment: feedback.content,
              created: feedback.created,
            } ],
          } as AccountApprovalStatus
        }
        return status
      })

      // Update the state
      dispatch(setLeftPanelApprovedStatus(statuses))
    }
    // Continue on
    onSuccess()
  }
}

export const verifyPublicListByCode = (
  publicListCode: string,
  accessCode: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setUserVerification("loading"))
  const result = await API.verifyPublicListAccessCodeByListCode({
    code: publicListCode,
    accessCode,
  })
  dispatch(setUserVerification(result))

  return result
}

export const submitVerification = (
  publicListCode: string,
  accessCode: string,
  codeRestriction: GraphQL.SuggestionAccessCodeRestriction | null | undefined,
) => async (
  dispatch: Dispatch,
) => {
  if ((codeRestriction !== null || undefined)) {
    const result = await API.verifyPublicListAccessCodeByListCode({
      code: publicListCode,
      accessCode,
    })
    if (API.isError(result)) {
      dispatch(setUserVerification(result))
      return false
    }
    if (API.isSuccess(result)) {
      dispatch(setUserVerification(result))
      return !result.payload.publicVerifySuggestionListAccessCodeByListCode.isValid
    }
  }
  return false
}

export const getDailyHourlyEngagementRate = (
  variables: GraphQL.FetchPublicHourlyEngagementSummaryQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDailyEngagementRate("loading"))

  const result = await API.getPublicDailyHourlyEngagementSummary(variables)

  dispatch(setDailyEngagementRate(result))
}

export const fetchMentionedBrands = (
  params: Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  // Create new mentioned brand object for account
  const mentioned: MentionedBrands = {
    socialAccountId: params.networkAccountId,
    data: await API.getPublicMentionedSocialAccounts({
      ...params,
      mentionType: GraphQL.MentionType.Brand,
    }),
  }

  // Save to list
  const { mentionedBrands } = getState().publicList
  dispatch(setMentionedBrands([ ...mentionedBrands, mentioned ]))
}

export const fetchWorkedWithBrands = (
  params: Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  // Create new mentioned brand object for account
  const mentioned: MentionedBrands = {
    socialAccountId: params.networkAccountId,
    data: await API.getPublicMentionedSocialAccounts({
      ...params,
      mentionType: GraphQL.MentionType.BrandWorkedWith,
    }),
  }

  // Save to list
  const { workedWithBrands } = getState().publicList
  dispatch(setWorkedWithBrands([ ...workedWithBrands, mentioned ]))
}

export const fetchDetectedBrands = (
  params: Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  // Create new mentioned brand object for account
  const mentioned: MentionedBrands = {
    socialAccountId: params.networkAccountId,
    data: await API.getPublicMentionedSocialAccounts({
      ...params,
      mentionType: GraphQL.MentionType.BrandLogoDetection,
    }),
  }

  // Save to list
  const { detectedBrands } = getState().publicList
  dispatch(setDetectedBrands([ ...detectedBrands, mentioned ]))
}

export const getMentionedPosts = (
  params: GraphQL.GetPublicMentionedPostsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedPostsStatus("loading"))
  const result = await API.fetchPublicMentionedPosts({
    ...params,
  })

  if (API.isSuccess(result)) {
    dispatch(setMentionedPostsStatus(result))
    dispatch(setMentionedPostsContent(result.payload.publicGetMentionedCippus.rows))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const getMoreMentionedPosts = (
  params: GraphQL.GetPublicMentionedPostsQueryVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const result = await API.fetchPublicMentionedPosts(params)

  if (API.isSuccess(result)) {
    const { listSocialProfile: { mentionedPostsContent: currentContent } } = getState()
    dispatch(setMentionedPostsContent([ ...currentContent, ...result.payload.publicGetMentionedCippus.rows ]))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const loadToggles = (
  toggles: GraphQL.ToggleFragment[],
) => (
  dispatch: Dispatch,
) => {
  dispatch(setToggles({
    displayInsightsEngagementRateOverTime: toggles
      .some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME),
    displayInsightsEngagementsByPostType: toggles
      .some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE),
    displayInsightsEngagementsByPostTypeReel: toggles
      .some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL),
    displayInsightsEngagementsHeatMap: toggles.some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP),
    displayInsightsRecentMediaAIImageAnalysis: toggles
      .some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS),
    displayInsightsRecentMediaStories: toggles.some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES),
    displayInsightsBrandsMentionedBrandsWorkedWith: toggles
      .some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH),
    displayInsightsBrandsDetected: toggles.some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED),
    displayInsightsPITraits: toggles.some(({ name }) => name === ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS),
    displayAudienceAuthenticity: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY),
    displayAudienceBaseline: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.BASELINE),
    displayFollowersOverTime: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME),
    displayGender: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.GENDER),
    displayFamilyStatus: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS),
    displayAgeRange: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE),
    displayIncomeRange: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE),
    displayEducationLevel: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL),
    displayEthnicities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES),
    displayLanguages: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.LANGUAGES),
    displayReligions: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.RELIGIONS),
    displayCountries: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.COUNTRIES),
    displayStates: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.STATES),
    displayCities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.CITIES),
    displayOccupations: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS),
    displayIndustries: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES),
    displayEmployers: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS),
    displayUniversities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES),
    displayAffinities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AFFINITIES),
    displayTopPosts: toggles.some(({ name }) => name === CONTENT_DISPLAY_TOGGLES.TOP_POSTS),
    displayKeywords: toggles.some(({ name }) => name === CONTENT_DISPLAY_TOGGLES.KEYWORDS),
  }))
}

/**
 * loadToggleOrder: Creates structures that are in order for displaying widgets on the public list page
 * @param toggles The toggles from the database
 * @param type The tab/group type
 * @returns An array of toggle order structure
 */
export const loadToggleOrder = (
  toggles: GraphQL.ToggleFragment[],
  type: GraphQL.SuggestionListToggleGroupType,
) => (dispatch: Dispatch) => {
  // Set type
  const ordered = toggles.filter((toggle) => toggle.type === type).sort((a, b) => a.order - b.order)

  // Check to see if account insights
  if (type === GraphQL.SuggestionListToggleGroupType.AccountInsightsToggles) {
    dispatch(setAccountInsightToggleOrder(ordered))
  } else if (type === GraphQL.SuggestionListToggleGroupType.AudienceToggles) { // Check audience toggles
    // Loop through the selected toggles and build results
    const audienceOrder: (GraphQL.ToggleFragment | AudienceGroup)[] = []
    if (ordered.length > 0) {
      // Build array of switches
      ordered.forEach((toggle, index) => {
        if (isMemberOfGroup(toggle)) {
          // Get the name of the group
          const groupName = getGroupName(toggle)

          // Check to see if the group already exists
          const group = audienceOrder.find((g) => g.name === groupName) as AudienceGroup | undefined
          if (group) {
            // Add toggle to group
            group.toggles.push(toggle)
          } else {
            // Create new group
            audienceOrder.push({
              name: groupName,
              order: index,
              toggles: [ toggle ],
            })
          }
        } else {
          // Add the toggle to the list
          audienceOrder.push(toggle)
        }
      })
    }

    // Save to global state
    dispatch(setAudienceToggleOrder(audienceOrder))
  } else if (type === GraphQL.SuggestionListToggleGroupType.ContentToggles) { // Check content toggles
    dispatch(setContentToggleOrder(ordered))
  }
}

export const getTopPosts = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setTopPosts("loading"))
  const result = await API.fetchPublicListGroupByCodeForContentTab(params)
  dispatch(setTopPosts(result))
}

export const getInitialPostKeywords = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setInitialPostsKeywords("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByKeyword(params)
  dispatch(setInitialPostsKeywords(result))
}

export const getInitialPostImageTags = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setInitialPostsImageTags("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByImageId(params)
  dispatch(setInitialPostsImageTags(result))
}

export const getPostsByKeyword = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setPostsByKeyword("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByKeyword(params)
  dispatch(setPostsByKeyword(result))
}

export const getPostsByImageId = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setPostsByImageId("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByImageId(params)
  dispatch(setPostsByImageId(result))
}

export const initializeAccess = (listCode: string, accessCode: string) => async (
  dispatch: Dispatch,
) => {
  const result = await verifyPublicListByCode(listCode, accessCode)(dispatch)
  if (API.isSuccess(result)) {
    const {
      suggestionAccessCodeRestriction,
    } = result.payload.publicVerifySuggestionListAccessCodeByListCode
    submitVerification(listCode, accessCode, suggestionAccessCodeRestriction)(dispatch)
  }
}

// Non-thunk helper functions
export const userCanViewList = (
  accessCodeRestriction: GraphQL.SuggestionAccessCodeRestriction | undefined | null,
  userIsValid: boolean | null | undefined,
) => {
  if (userIsValid) {
    return true
  }
  if (!accessCodeRestriction || accessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToView) {
    return false
  }
  if (accessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit) {
    return true
  }

  return false
}

export const userCanEdit = (
  accessCodeRestriction: GraphQL.SuggestionAccessCodeRestriction | undefined | null,
  userIsValid: boolean | null | undefined,
) => {
  if (userIsValid) {
    return true
  }
  if (accessCodeRestriction === null || accessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit) {
    return false
  }
  return false
}

export const checkDisplayAudienceTab = (toggles: ListTogglesType) => {
  const values = [
    toggles.displayAudienceBaseline,
    toggles.displayFollowersOverTime,
    toggles.displayGender,
    toggles.displayFamilyStatus,
    toggles.displayAgeRange,
    toggles.displayIncomeRange,
    toggles.displayEducationLevel,
    toggles.displayEthnicities,
    toggles.displayLanguages,
    toggles.displayReligions,
    toggles.displayCountries,
    toggles.displayStates,
    toggles.displayCities,
    toggles.displayOccupations,
    toggles.displayIndustries,
    toggles.displayEmployers,
    toggles.displayUniversities,
    toggles.displayAffinities,
  ]
  return values.includes(true)
}

export const checkDisplayAccountDetails = (toggles: ListTogglesType) => {
  const values = [
    toggles.displayInsightsEngagementRateOverTime,
    toggles.displayInsightsEngagementsByPostType,
    toggles.displayInsightsEngagementsByPostTypeReel,
    toggles.displayInsightsEngagementsHeatMap,
    toggles.displayInsightsRecentMediaAIImageAnalysis,
    toggles.displayInsightsBrandsMentionedBrandsWorkedWith,
    toggles.displayInsightsBrandsDetected,
    toggles.displayInsightsPITraits,
  ]
  return values.includes(true)
}

export const fetchAudiences = (
  listCode: string,
  socialAccountIds: string[],
) => async (dispatch: Dispatch) => {
  // Fetch all the audience demographics for given social accounts
  const promises = socialAccountIds.map((id) => API.fetchPublicListAudienceByCode({ code: listCode, socialAccountId: id }))
  const results = await Promise.all(promises)

  // Save all the audience demographics to state
  dispatch(setAudienceDemographics(socialAccountIds.map((id, index) => {
    const audResult = results[index]
    if (API.isSuccess(audResult)) {
      return {
        socialAccountId: id,
        demographics: audResult.payload.publicSocialAccount.audienceDemographics,
      }
    }
    return { socialAccountId: id, demographics: undefined }
  })))
}

export const fetchTopPosts = (
  socialAccountIds: string[],
  listId: string,
) => async (dispatch: Dispatch): Promise<void> => {
  // Fetch all the top posts
  const promises = socialAccountIds.map((id) => API.fetchTopPostsForContentTab(listId, id))
  const results = await Promise.all(promises)

  // Loop through and set results
  const posts: SocialAccountFetchedTopics[] = []
  results.forEach((result, index) => {
    if (API.isSuccess(result)) {
      const accountPosts = result.payload.suggestionListSocialAccountByListIdSocialAccountId.cippusFeatured
        .map((item) => item.post)
      posts.push({
        socialAccountId: socialAccountIds[index],
        posts: accountPosts,
      })
    }
  })

  dispatch(setContentTopPosts(posts))
}

export const getSuggestionListPostsByKeyword = (
  socialAccountIds: string[],
  listId: string,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    listConfiguration: {
      postKeywords,
    },
  } = getState()

  // Get all the keywords
  const postPromises: Promise<Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQuery>>[] = []
  const posts: TagPosts[] = []
  const socialIdToPromise: string[] = []
  socialAccountIds.forEach((socialAccountId) => {
    postKeywords.forEach((keyword) => {
      postPromises.push(API.getSuggestionListPostsByKeyword({
        socialAccountId,
        listId,
        keywordId: keyword.id,
      }))
      posts.push({
        tagId: keyword.id,
        posts: [],
      })
      socialIdToPromise.push(socialAccountId)
    })
  })

  // Process and wait for all to complete processing
  const postResults: Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQuery>[] = await Promise.all(postPromises)

  // Looop through and grap first post of for each keyword
  const map = new Map<string, TagPosts[]>()
  postResults.forEach((post, index) => {
    if (API.isSuccess(post)) {
      // Extract the posts
      const { cippusRelevant } = post.payload.suggestionListSocialAccountByListIdSocialAccountId
      const allKewordPosts = cippusRelevant.map((item) => item.post)

      // Set in map
      if (map.has(socialIdToPromise[index])) {
        map.get(socialIdToPromise[index])?.push({
          tagId: posts[index].tagId,
          posts: allKewordPosts,
        })
      } else {
        map.set(socialIdToPromise[index], [ {
          tagId: posts[index].tagId,
          posts: allKewordPosts,
        } ])
      }
    }
  })

  // Create resultant array
  const keywordPosts: SocialAccountTaggedTopics[] = []
  socialAccountIds.forEach((socialAccountId) => keywordPosts.push({
    socialAccountId,
    tagPosts: map.get(socialAccountId) || [],
  }))

  // Save the posts to list
  dispatch(setContentKeywordPosts(keywordPosts))
}

export const getPostsByImageTag = (
  socialAccountIds: string[],
  listId: string,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  // Grab list from list state
  const {
    listConfiguration: {
      postImageTags,
    },
  } = getState()

  // Find all the posts
  const imageTagsPostsPromises: Promise<Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQuery>>[] = []
  const posts: TagPosts[] = []
  const socialAccountIdToPromise: string[] = []
  socialAccountIds.forEach((socialAccountId) => {
    postImageTags.forEach((tag) => {
      imageTagsPostsPromises.push(API.getSuggestionListPostsByImageTag({
        socialAccountId,
        listId,
        suggestionListImageTagId: tag.id,
      }))
      posts.push({
        tagId: tag.id,
        posts: [],
      })
      socialAccountIdToPromise.push(socialAccountId)
    })
  })

  // Wait for all queries to resolve and then process
  // eslint-disable-next-line max-len
  const imageTagPostResults: Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQuery>[] = await Promise.all(imageTagsPostsPromises)

  // Process results
  const imageTagMap = new Map<string, TagPosts[]>()
  imageTagPostResults.forEach((imageTagPost, index) => {
    if (API.isSuccess(imageTagPost)) {
      const { cippusImageTags } = imageTagPost.payload.suggestionListSocialAccountByListIdSocialAccountId
      const allImageTagPosts = cippusImageTags.map((item) => item.post)

      // Set in map
      if (imageTagMap.has(socialAccountIdToPromise[index])) {
        imageTagMap.get(socialAccountIdToPromise[index])?.push({
          tagId: posts[index].tagId,
          posts: allImageTagPosts,
        })
      } else {
        imageTagMap.set(socialAccountIdToPromise[index], [ {
          tagId: posts[index].tagId,
          posts: allImageTagPosts,
        } ])
      }
    }
  })

  // Create resultant array
  const imageTagPosts: SocialAccountTaggedTopics[] = []
  socialAccountIds.forEach((socialAccountId) => imageTagPosts.push({
    socialAccountId,
    tagPosts: imageTagMap.get(socialAccountId) || [],
  }))

  // Save the posts to list
  dispatch(setContentImageTagPosts(imageTagPosts))
}
