/* eslint-disable max-len */
import React from "react"
import { useTranslation } from "react-i18next"

import { ReactComponent as EngagementRateOverTimeIcon } from "../../../asset/visualizations/LIST_ENGAGEMENT_RATE_EX.svg"
import { ReactComponent as EngagementByPostTypeOSIcon } from "../../../asset/visualizations/LIST_ENG_POST_TYPE_EX.svg"
import { ReactComponent as EngagementByPostTypeRNIcon } from "../../../asset/visualizations/LIST_ENG_POST_TYPE_REEL_EX.svg"
import { ReactComponent as EngagementHeatMapIcon } from "../../../asset/visualizations/LIST_ENG_HEATMAP_EX.svg"
import { ReactComponent as RecentMediaAiAnalysisIcon } from "../../../asset/visualizations/LIST_RECENT_MEDIA_AI_EX.svg"
import { ReactComponent as BrandsMentionedIcon } from "../../../asset/visualizations/LIST_BRANDS_MENTIONED_EX.svg"
import { ReactComponent as RecentMedialStoriesIcon } from "../../../asset/visualizations/LIST_RECENT_STORIES_EX.svg"
import { ReactComponent as PersonalityTraitsIcon } from "../../../asset/visualizations/LIST_PERSONALITY_TRAITS_EX.svg"
import { ReactComponent as ContentTabsVisualization } from "../../../asset/visualizations/CONTENT_TABS.svg"

import Divider from "../../Divider"
import EmptySplash from "../../EmptySplash"
import {
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
} from "../../../util/constant"
import AudienceHelper, { hasAnyToggle } from "./AudienceHelper"
import { useSelector } from "../../../state/hooks"
import { SuggestionListToggleInput } from "../../../graphql"

export default function AppendixVisualizations() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  const { updateListForm } = useSelector(({ listConfiguration }) => listConfiguration)

  let activeAppendixsMetrics: SuggestionListToggleInput[] = []
  if (updateListForm?.toggles) {
    activeAppendixsMetrics = Array.isArray(updateListForm.toggles)
      ? updateListForm.toggles
      : [ updateListForm.toggles ]
  }

  const hasAnyRelevantToggles = React.useMemo(() => {
    const toggleGroups = [
      APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
      APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
      APPENDIX_CONTENT_DISPLAY_TOGGLES,
    ]

    return toggleGroups.some((group) => hasAnyToggle(activeAppendixsMetrics, group))
  }, [ activeAppendixsMetrics ])

  if (!hasAnyRelevantToggles) {
    return (
      <div className="cp_lcp-preview_example-visualizations appendix">
        <EmptySplash headlineText={ translate("You have no filters selected!") } />
      </div>
    )
  }

  return (
    <div className="cp_lcp-preview_example-visualizations appendix">
      <h2>Account Insights</h2>
      <Divider />
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME)) && (
        <EngagementRateOverTimeIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE)) && (
      <EngagementByPostTypeOSIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL)) && (
        <EngagementByPostTypeRNIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP)) && (
        <EngagementHeatMapIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS)) && (
        <RecentMediaAiAnalysisIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH)) && (
        <BrandsMentionedIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES)) && (
        <RecentMedialStoriesIcon />
      ) }
      { (activeAppendixsMetrics.map((t) => t.name).includes(APPENDIX_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS)) && (
        <PersonalityTraitsIcon />
      ) }
      { hasAnyToggle(activeAppendixsMetrics, APPENDIX_AUDIENCE_DISPLAY_TOGGLES) && (
      <>
        <h2>Audience</h2>
        <Divider />
        <AudienceHelper toggles={ activeAppendixsMetrics } />
      </>
      ) }
      { hasAnyToggle(activeAppendixsMetrics, APPENDIX_CONTENT_DISPLAY_TOGGLES) && (
      <>
        <h2>Content</h2>
        <Divider />
        <ContentTabsVisualization />
      </>
      ) }
    </div>
  )
}
