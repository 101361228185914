import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import * as API from "../../../../util/apiClient"
import * as Constant from "../../../../util/constant"
import AffinitiesWrapper from "./AffinitiesWrapper"
import AudienceAuthenticityCharts from "../../SocialProfileTabs/AccountAudienceDetails/AudienceAuthenticityCharts"
import BasicChartWrapper from "./BasicChartWrapper"
import Divider from "../../../Divider"
import EthnicityChartWrapper from "./EthnicityChartWrapper"
import FamilyChartWrapper from "./FamilyChartWrapper"
import FollowersChartWrapper from "./FollowersChartWrapper"
import GenderChartWrapper from "./GenderChartWrapper"
import LanguagesChartWrapper from "./LanguagesChartWrapper"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import LocationWrapper from "./LocationWrapper"
import MaritalChartWrapper from "./MaritalChartWrapper"
import ReligionsChartWrapper from "./ReligionsChartWrapper"
import VocationWrapper from "./VocationWrapper"
import { ListTogglesType } from "../../../../util/types"
import { fetchAudience } from "../../../../state/publicListSlice"
import { getAudienceQualityScores } from "../../../../state/socialProfileSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"

import "../../Audience/index.sass"
import { ToggleFragment } from "../../../../graphql"
import { AudienceGroup } from "../../../ListConfiguration/constants"

type Props = {
  toggles: ListTogglesType
}

export default function AccountAudienceDetails({ toggles }: Props) {
  const {
    audience,
    selectedListSocialAccount,
    audienceToggleOrder,
  } = useSelector(({ publicList }) => publicList)
  const audienceQualityScore = useSelector(({ socialProfile }) => socialProfile.audienceQualityScore)
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceDetails" })
  const dispatch = useDispatch()
  const { listCode } = useParams()

  useEffect(() => {
    if (!selectedListSocialAccount || !listCode) return
    dispatch(fetchAudience({
      code: listCode,
      socialAccountId: selectedListSocialAccount.socialAccount.id,
    }))
    if (audienceQualityScore === "init") {
      dispatch(getAudienceQualityScores(selectedListSocialAccount.socialAccount.id))
    }
  }, [ selectedListSocialAccount, audienceQualityScore ])

  // Memoized widget booleans check if each widget has data to display. If not, hide the widget.
  const showGenderWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(
      audience.payload.publicSocialAccount.audienceDemographics?.genderStatistics?.female
      && audience.payload.publicSocialAccount.audienceDemographics?.genderStatistics?.male,
    )
  }, [ audience ])
  const showMaritalWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.familyStatistics?.married
      && audience.payload.publicSocialAccount.audienceDemographics?.familyStatistics?.single)
  }, [ audience ])
  const showParentalWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.familyStatistics?.parents)
  }, [ audience ])
  const showBasicWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.ageGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.incomeGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.educationLevel.length)
  }, [ audience ])

  const showLocationWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.cityGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.countryGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.stateGroups.length)
  }, [ audience ])

  const showAffinitiesWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.brandAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.interestAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.influenceAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.mediaAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.musicAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.sportAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.hashtagAffinities.length)
  }, [ audience ])

  const showVocationWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.occupationGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.industryGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.employerGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.universityGroups.length)
  }, [ audience ])

  const showEthnicityWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.ethnicityGroups.length)
  }, [ audience ])
  const showLanguagesWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.languageGroups.length)
  }, [ audience ])
  const showReligionWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.religionGroups.length)
  }, [ audience ])

  const socialAccount = useMemo(
    () => selectedListSocialAccount != null
      ? selectedListSocialAccount.socialAccount : null,
    [ selectedListSocialAccount ],
  )

  const renderWidget = (widget: ToggleFragment | AudienceGroup): React.JSX.Element | null => {
    switch (widget.name) {
      case Constant.AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME:
        return (
          <div className="cp_audience-details_component-widget">
            <FollowersChartWrapper />
          </div>
        )
      case Constant.AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY:
        if (socialAccount && Constant.AUDIENCE_AUTHENTICITY_NETWORKS.includes(socialAccount.network)) {
          return <AudienceAuthenticityCharts context="publicLists" />
        }
        return null
      case Constant.AUDIENCE_DISPLAY_TOGGLES.GENDER:
        if (showGenderWidget) {
          return (
            <div className="cp_audience-details_component-widget cp_audience-details_component-column-1">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Gender") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <GenderChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case Constant.AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS:
        if (showMaritalWidget || showParentalWidget) {
          return (
            <div className="cp_audience-details_component-widget cp_audience-details_component-column-2">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Family") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                { audience === "init" || audience === "loading"
                  ? <LoadingIndicatorCard />
                  : (
                    <>
                      { showMaritalWidget && <MaritalChartWrapper /> }
                      { showParentalWidget && <FamilyChartWrapper /> }
                    </>
                  )
                }
              </div>
            </div>
          )
        }
        return null
      case Constant.AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES:
        if (showEthnicityWidget) {
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Ethnicity") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <EthnicityChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case Constant.AUDIENCE_DISPLAY_TOGGLES.LANGUAGES:
        if (showLanguagesWidget) {
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Languages") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <LanguagesChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case Constant.AUDIENCE_DISPLAY_TOGGLES.RELIGIONS:
        if (showReligionWidget) {
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Religion") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <ReligionsChartWrapper />
              </div>
            </div>
          )
        }
        return null
      case Constant.AUDIENCE_DISPLAY_TOGGLES.AFFINITIES:
        if (showAffinitiesWidget) {
          return (
            <div className="cp_audience-details_component-widget">
              <div className="cp_audience-details_component">
                <AffinitiesWrapper />
              </div>
            </div>
          )
        }
        return null
      case "Basic": {
        if (showBasicWidget) {
          const group = widget as AudienceGroup
          return (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Basic") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <BasicChartWrapper
                  toggles={ group.toggles }
                  displayAgeRange={ toggles.displayAgeRange }
                  displayIncomeRange={ toggles.displayIncomeRange }
                  displayEducationLevel={ toggles.displayEducationLevel }
                />
              </div>
            </div>
          )
        }
        return null
      }
      case "Location": {
        if (showLocationWidget) {
          const group = widget as AudienceGroup
          return (
            <div className="cp_audience-details_component-widget">
              <div className="cp_audience-details_component">
                <h6 className="cp_audience-details_component-widget-title">
                  { translate("Location") }
                </h6>
                <LocationWrapper
                  toggles={ group.toggles }
                  displayCities={ toggles.displayCities }
                  displayCountries={ toggles.displayCountries }
                  displayStates={ toggles.displayStates }
                />
              </div>
            </div>
          )
        }
        return null
      }
      case "Vocation": {
        if (showVocationWidget) {
          const group = widget as AudienceGroup
          return (
            <div className="cp_audience-details_component-widget">
              <div className="cp_audience-details_component">
                <VocationWrapper
                  toggles={ group.toggles }
                  displayOccupations={ toggles.displayOccupations }
                  displayIndustries={ toggles.displayIndustries }
                  displayEmployers={ toggles.displayEmployers }
                  displayUniversities={ toggles.displayUniversities }
                />
              </div>
            </div>
          )
        }
        return null
      }
      default:
        return null
    }
  }

  return (
    <div className="cp_audience-details_component">
      { audienceToggleOrder.map((widget) => renderWidget(widget)) }
    </div>
  )
}
