/* eslint-disable max-len */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import {
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  getGridBooleanOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro"

import { Avatar, InputAdornment } from "@mui/material"
import { Search as SearchIcon, Delete } from "@mui/icons-material"
import Tooltip from "../../Tooltip"

import "./campaign-deliverables-table.sass"
import { useSelector, useDispatch } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import { shorthandNumber } from "../../../util/miscHelper"
import { openNewDeliverableModal } from "../../../state/campaignDeliverableModalSlice"
import Pill from "../../Pill"
import DataGrid from "../../DataGrid"
import EntityInfoRow from "../../EntityInfoRow"
import ContextMenu from "./CampaignDeliverableRowContextMenuV2"
import Button from "../../Button"
import Input from "../../Input"
import LoadingIndicator from "../../LoadingIndicator"
import EmptySplash from "../../EmptySplash"
import { isError } from "../../../util/apiClient"
import { fetchCampaign, setCampaignDeliverablesStartsWithFilter } from "../../../state/campaignSlice"
import { Scope } from "../../../util/types"
import IconButton from "../../IconButton"
import Toggle from "../../Toggle"
import { openDeliverableContentModal } from "../../../state/campaignDeliverableContentModalV2Slice"
import HoverableCopyableText from "./CampaignOverviewDetails/HoverableCopyableText"
import StatusDeliverableV2 from "../../StatusDeliverable/StatusDeliverableV2"
import { Network } from "../../../graphql"

const statusOrder = {
  [GraphQL.EnhancedDeliverableStatus.Draft]: 0,
  [GraphQL.EnhancedDeliverableStatus.PendingFeedback]: 1,
  [GraphQL.EnhancedDeliverableStatus.PendingRevision]: 2,
  [GraphQL.EnhancedDeliverableStatus.Finalized]: 3,
  [GraphQL.EnhancedDeliverableStatus.Uploaded]: 4,
  [GraphQL.EnhancedDeliverableStatus.Live]: 5,
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      { ...props }
      slots={ {
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
      } }
    />
  )
}

interface AccountsStartsWith {
  currValue: string,
  handleValueChange: (e: string) => void
}

function AccountStartsWith({ currValue, handleValueChange }: AccountsStartsWith) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchResults" })
  return (
    <Input
      className="cp_component_search-results-search-input"
      placeholder={ translate("Search") }
      value={ currValue }
      onChange={ (e) => { handleValueChange(e.target.value) } }
      InputProps={ {
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              className="cp_component_search-results-search-icon"
            />
          </InputAdornment>
        ),
      } }
    />
  )
}

export default function CampaignDeliverablesTableV2() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverablesTab" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const {
    campaign,
    campaignDeliverablesStartsWithFilter: startsWithFilter,
  } = useSelector(({ campaignPage }) => campaignPage)
  const { modalOpen } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)
  const { deliverableSummaryList } = useSelector(({ campaignV2 }) => campaignV2.campaignDetails)
  const { scopes } = useSelector(({ user }) => user)
  const [ deliverables, setDeliverables ] = useState<GraphQL.DeliverableFragment[]>([])
  const [ filter, setFilter ] = useState("account")

  useEffect(() => {
    if (API.isSuccess(campaign)) {
      setDeliverables(campaign.payload.campaign.deliverables)
    }
  }, [ campaign ])

  useEffect(() => {
    if (!API.isSuccess(campaign)) {
      setDeliverables([])
      return
    }
    if (!startsWithFilter.length) {
      setDeliverables(campaign.payload.campaign.deliverables)
      return
    }
    const filteredDeliverables = campaign.payload.campaign.deliverables.filter((deliverable) => {
      if (filter === "account") {
        return deliverable
          .campaignNetworkAccount
          .socialAccount
          .userName?.toLowerCase()
          .includes(startsWithFilter.toLowerCase())
      }
      return deliverable.name.toLowerCase().includes(startsWithFilter)
    })
    setDeliverables(filteredDeliverables)
  }, [ startsWithFilter, filter ])

  // Note: Handles when the user direct links to an opened deliverable
  React.useEffect(() => {
    if (!searchParams.has("deliverableId") || modalOpen) return
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return
    dispatch(openDeliverableContentModal())
  }, [ deliverables, searchParams, modalOpen ])

  const NoDeliverablesOverlay = React.useCallback(() => (
    <div className="cp_campaign_component-tabs-content-controls empty">
      <EmptySplash
        headlineText={ translate("No deliverables") }
      />
    </div>
  ), [ translate ])

  const COLUMNS: GridColDef[] = [
    {
      field: "__check__",
      headerName: "",
      sortable: false,
      filterable: false,
      renderHeader: () => "",
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
      maxWidth: 50,
      align: "center",
    },
    {
      field: "account",
      headerName: translate("Account"),
      sortable: true,
      filterable: true,
      filterOperators: getGridStringOperators(),
      align: "left",
      headerClassName: "account-header",
      valueGetter(params) {
        return params.row.account
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          network={ params.row.network }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "network",
      headerName: translate("Network"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        {
          label: translate("Facebook"),
          value: GraphQL.Network.Facebook,
        },
        {
          label: translate("Instagram"),
          value: GraphQL.Network.Instagram,
        },
        {
          label: translate("Snapchat"),
          value: GraphQL.Network.Snapchat,
        },
        {
          label: translate("TikTok"),
          value: GraphQL.Network.Tiktok,
        },
        {
          label: translate("Youtube"),
          value: GraphQL.Network.Youtube,
        },
      ],
      filterOperators: getGridSingleSelectOperators(),
      valueGetter(params) {
        return params.row.network
      },
      renderCell: (params) => (
        params.row.network
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "title",
      headerName: translate("Title"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "string",
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.name
      },
      renderCell: (params) => {
        const { name } = params.row
        if (!name || typeof name !== "string") return null
        return (
          <HoverableCopyableText
            text={ name }
            containerClass="table-title"
            contentClass="table-title-content"
          />
        )
      },
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "caption",
      headerName: translate("Post Caption"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      renderCell: (params) => {
        const { caption } = params.row
        if (!caption || typeof caption !== "string") return null

        return (
          <HoverableCopyableText
            text={ caption }
            containerClass="table-caption"
            contentClass="table-caption-content"
          />
        )
      },
      flex: 0.7,
      maxWidth: 250,
      align: "left",
    },
    {
      field: "media",
      headerName: translate("Media"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const media = params.row.media || undefined
        if (!media) return null
        return (
          <Avatar
            className="table-media"
            variant="rounded"
            src={ media || "" }
          />
        )
      },
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "reviewerGroupName",
      headerName: translate("Review Group"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.reviewerGroupName
      },
      renderCell: (params) => (
        <Tooltip
          classes={ { tooltip: "cp-tooltip cp_campaign-deliverables-table_component-table-tooltip" } }
          title={ params.row.reviewerGroupName || "" }
          placement="top"
          arrow={ true }
          disableHoverListener={ !params.row.reviewerGroupName }
        >
          <p className="table-review-group-content">
            { params.row.reviewerGroupName
          }
          </p>
        </Tooltip>
      ),
      flex: 0.7,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "reviewerGroupStatus",
      headerName: translate("Review Group Status"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.reviewerGroupStatus
      },
      renderCell: (params) => (
        <p className="table-review-group-status-content">
          { params.row.reviewerGroupName && params.row.reviewerGroupStatus ? translate(params.row.reviewerGroupStatus) : ""
      }
        </p>
      ),
      flex: 1,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "feedback",
      headerName: translate("Feedback"),
      sortable: true,
      filterable: true,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.feedback || 0
      },
      disableColumnMenu: false,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.feedback) } />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "deliverableStatus",
      headerName: translate("Status"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      sortComparator: (v1: GraphQL.EnhancedDeliverableStatus, v2: GraphQL.EnhancedDeliverableStatus) => statusOrder[v1] - statusOrder[v2],
      valueOptions: [
        GraphQL.EnhancedDeliverableStatus.Draft,
        GraphQL.EnhancedDeliverableStatus.PendingFeedback,
        GraphQL.EnhancedDeliverableStatus.PendingRevision,
        GraphQL.EnhancedDeliverableStatus.Finalized,
        GraphQL.EnhancedDeliverableStatus.Live,
        GraphQL.EnhancedDeliverableStatus.Uploaded,
      ],
      filterOperators: getGridSingleSelectOperators(),
      renderCell: (params) => (
        <StatusDeliverableV2
          deliverableStatus={ params.row.deliverableStatus }
          postTracker={ params.row.status.postTracker }
        />
      ),
      flex: 1,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "tiktok",
      headerName: translate("TCM Campaign"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.tiktok || translate("NOT LINKED")
      },
      renderCell: (params) => (
        <Tooltip
          classes={ { tooltip: "cp-tooltip cp_campaign-deliverables-table_component-table-tooltip" } }
          title={ params.row.tiktok || "" }
          placement="top"
          arrow={ true }
          disableHoverListener={ params.row.network !== Network.Tiktok }
        >
          <p className="table-ttcm-content">
            { params.row.network === Network.Tiktok
              ? (params.row.tiktok || translate("NOT LINKED"))
              : ""
            }
          </p>
        </Tooltip>
      ),
      flex: 0.7,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="cp_campaign-accounts-table_component-column-context">
          <ContextMenu deliverableSummary={ params.row.deliverable } />
        </div>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ]

  const handleSearchUpdate = (inputValue: string) => {
    dispatch(setCampaignDeliverablesStartsWithFilter(inputValue))
  }
  const availableAccounts = React.useMemo(() => {
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return []
    return campaign.payload.campaign.campaignNetworkAccounts
  }, [ campaign ])

  const filteredColumns = React.useMemo(() => {
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return COLUMNS
    let filteredCols = COLUMNS

    if (
      !campaign.payload.campaign.enableTiktok
      || !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) filteredCols = filteredCols.filter((column) => column.field !== "tiktok")

    if (
      !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) filteredCols = filteredCols.filter((column) => column.field !== "feedback" && column.field !== "ellipsisMenu")

    return filteredCols
  }, [ campaign, scopes ])

  if (campaign === "init" || campaign === "loading") {
    return (
      <div className="cp_campaign_component-tabs-content-controls loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }
  if (API.isError(campaign)) {
    return (
      <div className="cp_campaign_component-tabs-content-controls empty">
        <EmptySplash
          headlineText={ translateCommon("No results found") }
          bodyText={ translateCommon("An unexpected error occurred!") }
        />
      </div>
    )
  }

  return (
    <div className="cp_campaign-deliverables-table_component-container">
      <div className="cp_campaign_component-tabs-content-controls deliverables">
        <div className="cp_campaign_component-tabs-content-controls-left">
          <AccountStartsWith currValue={ startsWithFilter } handleValueChange={ handleSearchUpdate } />
          <Toggle
            toggleOptions={ [
              {
                value: "account",
                label: translate("Account"),
                keyId: "account",
              },
              {
                value: "title",
                label: translate("Title"),
                keyId: "title",
              },
            ] }
            value={ filter }
            setValue={ setFilter }
          />
        </div>
        {
          scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? (
            <div className="cp_campaign_component-tabs-content-controls-right">
              <IconButton
                variant="filled"
                size="small"
              >
                <Delete />
              </IconButton>
              <Button
                label={ translate("Add Deliverable") }
                onClick={ () => {
                  dispatch(openNewDeliverableModal({
                    socialAccounts: availableAccounts,
                    createCallback: () => dispatch(fetchCampaign({ campaignId: campaign.payload.campaign.id })),
                  }))
                } }
                size="small"
              />
            </div>
          ) : null }
      </div>
      <DataGrid
        className="cp_campaign-deliverables-table_component-table"
        columnVisibilityModel={ {
          network: false,
        } }
        getRowId={ (row) => row.id }
        slots={ {
          columnMenu: CustomColumnMenu,
          noRowsOverlay: NoDeliverablesOverlay,
        } }
        checkboxSelection={ true }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ filteredColumns }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={
          {
            left: [ "__check__", "account" ],
            right: [ "ellipsisMenu" ],
          }
        }
        rows={ deliverableSummaryList.map(({
          id,
          caption,
          name,
          mediaUrl,
          socialAccount,
          feedbackCount,
          deliverableStatus,
          postTracker,
          ttcmCampaignName,
          reviewerGroupName,
          reviewerGroupStatus,
        }, i) => ({
          account: socialAccount.username,
          profilePictureUrl: socialAccount.profilePictureUrl,
          followers: socialAccount.followerCount,
          network: socialAccount.networkType,
          id,
          name,
          caption: caption || "",
          media: mediaUrl,
          deliverableStatus,
          status: { contentStatus: deliverableStatus, postTracker },
          deliverable: deliverableSummaryList[i],
          feedback: feedbackCount,
          tiktok: ttcmCampaignName,
          reviewerGroupName,
          reviewerGroupStatus,
        })) || [] }
        loading={ false }
        onRowClick={ (params) => {
          setSearchParams({ deliverableId: params.row.deliverable.id })
          dispatch(openDeliverableContentModal())
        } }
        scrollEndThreshold={ 200 }
        autoHeight={ true }
      />
    </div>
  )
}
