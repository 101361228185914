import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

import { useDispatch, useSelector } from "../../../../state/hooks"
import { Metric, setUpdateListForm } from "../../../../state/listConfigurationSlice"
import Checkbox from "../../../Checkbox"
import { GroupMetrics } from "../../constants"
import { copyObject } from "../../../../util/miscHelper"
import { SuggestionListToggleInput, SuggestionListToggleGroupType } from "../../../../graphql"

interface GroupMetricsProps {
  metricData: GroupMetrics
}

export default function AppendixDisplayGroup({ metricData }: GroupMetricsProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  const { updateListForm } = useSelector((state) => state.listConfiguration)
  let toggles: SuggestionListToggleInput[] = []
  if (updateListForm?.toggles) {
    toggles = Array.isArray(updateListForm.toggles)
      ? updateListForm.toggles
      : [ updateListForm.toggles ]
  }
  const AppendixTogglesOnly = toggles.filter((item) => item.name.startsWith("ToggleAppendix"))

  // Local state
  const [ expanded, setExpanded ] = React.useState(false)

  /**
   * allSelectOrDeselect: Selects all or de-selects all sub list items
   * @param e The event object
   */
  const allSelectOrDeselect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!updateListForm) return
    if (AppendixTogglesOnly.length === metricData.metrics.length) {
      // Check if update list form exists
      // Remove all toggles from update list form
      const listForm = copyObject(updateListForm)
      listForm.toggles = (Array.isArray(listForm.toggles))
        ? listForm.toggles.filter((toggle) => !metricData.metrics.some((metric) => metric.code === toggle.name))
        : [ listForm.toggles ].filter((toggle) => !metricData.metrics.some((metric) => metric.code === toggle.name))
      dispatch(setUpdateListForm(listForm))
    } else {
      // Check if update list form exists
      // Add all toggles to update list form
      const listForm = copyObject(updateListForm)
      const listFormToggles = (Array.isArray(updateListForm.toggles)) ? updateListForm.toggles : [ updateListForm.toggles ]
      const addList = listFormToggles.filter(
        (toggle) => metricData.metrics.some((metric) => metric.code === toggle.name),
      )
      listForm.toggles = [
        ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
        ...addList,
      ]
      dispatch(setUpdateListForm(listForm))
    }
    e.stopPropagation()
  }

  /**
   * selectItem: Handles selecting or de-selecting a sub list item
   * @param metric The metric to select/de-select
   */
  const selectItem = (metric: Metric) => {
    if (!updateListForm) return
    if (toggles.some((toggle) => toggle.name === metric.code)) {
      // Check if update list form exists
      // Remove toggle from update list form
      const listForm = copyObject(updateListForm)
      listForm.toggles = (Array.isArray(listForm.toggles))
        ? listForm.toggles.filter((toggle) => toggle.name !== metric.code)
        : [ listForm.toggles ].filter((toggle) => toggle.name !== metric.code)

      dispatch(setUpdateListForm(listForm))
    } else {
      // Add item
      // Add all toggles to update list form
      const listForm = copyObject(updateListForm)
      listForm.toggles = [
        ...((Array.isArray(listForm.toggles)) ? listForm.toggles : [ listForm.toggles ]),
        ...[ {
          name: metric.code, order: 0, type: SuggestionListToggleGroupType.AppendixContentToggles,
        } ],
      ]
      dispatch(setUpdateListForm(listForm))
    }
  }

  /**
   * containsAllMetrics: Checks to see if all metrics are saved
   * @returns True if all are there, otherwise false
   */
  const containsAllMetrics = (): boolean => metricData.metrics
    .every((metric) => toggles.some((toggle) => toggle.name === metric.code))

  /**
   * containsSomeMetrics: Checks to see how many metrics are saved
   * @returns True if there are some, but not all, otherwise false
   */
  // eslint-disable-next-line max-len
  const containsSomeMetrics = (): boolean => AppendixTogglesOnly.some((metric) => metricData.metrics.some((m) => m.code === metric.name))

  // Render the component
  return (
    <>
      <ListItemButton
        className="group-highlights-parent-list-item"
        onClick={ () => setExpanded(!expanded) }
      >
        { (expanded)
          ? <ExpandMore className="list-item-expand-contract" />
          : <ExpandLess className="list-item-expand-contract" /> }
        <ListItemIcon>
          <Checkbox
            checked={ containsAllMetrics() && metricData.metrics.length !== 0 }
            indeterminate={ containsSomeMetrics() && !(containsAllMetrics() && metricData.metrics.length !== 0) }
            onClick={ (e) => allSelectOrDeselect(e) }
          />
        </ListItemIcon>
        <ListItemText
          className="group-highlights-parent-list-item-text"
          primary={ translate(metricData.groupName) }
        />
      </ListItemButton>
      <Collapse in={ expanded } timeout="auto" unmountOnExit={ true }>
        <List
          id="plad-metrics"
          className="group-highlights-child-list-item"
          component="div"
          disablePadding={ true }
        >
          { metricData.metrics.map((item) => (
            <ListItemButton
              className="group-highlights-child-list-item-button"
              key={ item.code }
              onClick={ () => selectItem(item) }
            >
              <ListItemIcon>
                <Checkbox
                  checked={ toggles.some((toggle) => toggle.name === item.code) }
                />
              </ListItemIcon>
              <ListItemText
                className="group-highlights-child-list-item-text"
                primary={ translate(item.name) }
              />
            </ListItemButton>
          )) }
        </List>
      </Collapse>
    </>
  )
}
