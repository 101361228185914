import * as React from "react"
import { useTranslation } from "react-i18next"
import "../style.sass"
import { useSelector } from "../../../../state/hooks"
import "../../AccountInsights/account-insights-details.sass"
import Divider from "../../../Divider"
import {
  Network,
  SocialAccount,
  ToggleFragment,
} from "../../../../graphql"
import EngagementChartWrapper from "./EngagementChartWrapper"
import EngagementTableWrapper from "./EngagementTableWrapper"
import EngagementMapWrapper from "./EngagementMapWrapper"
import ImageAnalysisWrapper from "./ImageAnalysisWrapper"
import BrandsWrapper from "./BrandsWrapper"
import RecentMediaWrapper from "./RecentMediaWrapper"
import PersonalityTraitsWrapper from "./PersonalityTraitsWrapper"
import { ListTogglesType } from "../../../../util/types"
import RecentPosts from "../../SocialProfileTabs/AccountInsightsDetails/RecentPost"
import { ACCOUNT_INSIGHTS_DISPLAY_TOGGLES } from "../../../../util/constant"

type Props = {
  toggles: ListTogglesType
}

export default function AccountInsightsDetails({ toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const {
    selectedListSocialAccount,
    accountInsightToggleOrder,
  } = useSelector(({ publicList }) => publicList)

  const socialAccount = React.useMemo(() => selectedListSocialAccount?.socialAccount, [ selectedListSocialAccount ])

  const shouldDisplayImageAnalysis = React.useMemo(() => {
    if (selectedListSocialAccount) {
      const { socialAccount: { network } } = selectedListSocialAccount
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ selectedListSocialAccount ])

  const shouldDisplayBrands = React.useMemo(() => {
    if (selectedListSocialAccount) {
      const { socialAccount: { network } } = selectedListSocialAccount
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ selectedListSocialAccount ])

  /**
   * renderWidget: Render the widget based on the toggle option shown
   * @param toggle THe toggle
   * @returns The widget or null
   */
  const renderWidget = (toggle: ToggleFragment): React.JSX.Element | null => {
    switch (toggle.name) {
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME:
        return (
          <div className="cp_account-insights-details_component-widget">
            <EngagementChartWrapper />
          </div>
        )
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS:
        return (
          <div className="cp_account-insights-details_component-widget">
            <PersonalityTraitsWrapper />
          </div>
        )
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP:
        return (
          <div className="cp_account-insights-details_component-widget">
            <EngagementMapWrapper />
          </div>
        )
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE:
        return (
          <div className="cp_account-insights-details_component-widget">
            <div>
              <h6 className="cp_account-insights-details_component-widget-title">
                { translate("Engagement by Post Type") }
              </h6>
              <Divider />
              <EngagementTableWrapper
                displayPostType={ toggles.displayInsightsEngagementsByPostType }
                displayPostTypeReel={ false }
              />
            </div>
          </div>
        )
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL:
        return (
          <div className="cp_account-insights-details_component-widget">
            <div>
              <h6 className="cp_account-insights-details_component-widget-title">
                { translate("Engagement by Post Type") }
              </h6>
              <Divider />
              <EngagementTableWrapper
                displayPostType={ false }
                displayPostTypeReel={ toggles.displayInsightsEngagementsByPostTypeReel }
              />
            </div>
          </div>
        )
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES:
        if (socialAccount?.oauthed && socialAccount?.recentStories.length > 0) {
          return (
            <div className="cp_account-insights-details_component-widget">
              <div>
                <h6 className="cp_account-insights-details_component-widget-title">
                  { translate("Recent Stories") }
                </h6>
                <Divider />
                <RecentPosts socialAccount={ socialAccount as SocialAccount } />
              </div>
            </div>
          )
        }
        return null
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS:
        return (
          <div className="cp_account-insights-details_component-row">
            { shouldDisplayImageAnalysis && (
            <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-2">
              <div>
                <h6 className="cp_account-insights-details_component-widget-title">
                  { translate("AI Image Analysis") }
                </h6>
                <Divider />
                <ImageAnalysisWrapper />
              </div>
            </div>
            ) }
            <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-1">
              <div>
                <h6 className="cp_account-insights-details_component-widget-title">
                  { translate("Recent Media") }
                </h6>
                <Divider />
                <RecentMediaWrapper />
              </div>
            </div>
          </div>
        )
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH:
        if (shouldDisplayBrands) {
          return (
            <div className="cp_account-insights-details_component-widget">
              <h6 className="cp_account-insights-details_component-widget-title">
                { translate("Brands") }
              </h6>
              <Divider />
              <BrandsWrapper
                displayBrandsMentionedWorked={ toggles.displayInsightsBrandsMentionedBrandsWorkedWith }
                displayBrandsDetected={ toggles.displayInsightsBrandsDetected }
              />
            </div>
          )
        }
        return null
      default:
        return null
    }
  }

  return (
    <div className="cp_account-insights-details_component">
      { accountInsightToggleOrder.map((widget) => renderWidget(widget)) }
    </div>
  )
}
