import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import Pill from "../../../Pill"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  setHighlightsMetrics,
  Metric,
  populateState,
  setUpdateListForm,
} from "../../../../state/listConfigurationSlice"
import { highlightMetrics as metrics } from "../../constants"
import DemocraphicScoreForm from "./DemographicScoreForm"
import ContextualRelevancyForm from "./ContextualRelevancyForm"
import { isSuccess } from "../../../../util/apiClient"
import AudienceMetricsForm from "./AudienceMetricsForm"
import RelevantPostsForm from "./RelevantPostsForm"

interface HighlightsDisplayProps {
  setActiveToggles: (count: number) => void
}

export default function HighlightsDisplay({ setActiveToggles }: HighlightsDisplayProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  const {
    highlightsMetrics,
    updateListForm,
    showHighlightsAudienceForm,
  } = useSelector((state) => state.listConfiguration)
  const list = useSelector((state) => state.list.list)

  React.useEffect(() => {
    if (isSuccess(list)) {
      dispatch(populateState())
    }
  }, [ list ])

  const publicAccountDetailMetrics: Metric[] = React.useMemo(() => (
    highlightsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Public List Account Details")?.metrics.includes(metric))
  ), [ highlightsMetrics ])

  const scoresEngagementRateMetrics: Metric[] = React.useMemo(() => (
    highlightsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Scores & Engagement Rate")?.metrics.includes(metric))
  ), [ highlightsMetrics ])

  const accountInsightMetrics: Metric[] = React.useMemo(() => (
    highlightsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Account Insights")?.metrics.includes(metric))
  ), [ highlightsMetrics ])

  const contentMetrics: Metric[] = React.useMemo(() => (
    highlightsMetrics
      .filter((metric) => metrics.find((group) => group.groupName === "Content")?.metrics.includes(metric))
  ), [ highlightsMetrics ])

  React.useEffect(() => {
    setActiveToggles(highlightsMetrics.length)
  }, [ highlightsMetrics ])

  /**
   * deleteHighlightsMetric: Removes the metrics from global state
   * @param metric The metric to be removed
   */
  const deleteHighlightsMetric = (metric: Metric) => {
    // Remove the metric from global state
    dispatch(setHighlightsMetrics(highlightsMetrics.filter((stateMetric) => stateMetric.name !== metric.name)))

    // Check if update list form exists
    if (updateListForm) {
      // Remove the metric from the update list form
      const listForm = { ...updateListForm }
      listForm.toggles = (Array.isArray(listForm.toggles))
        ? listForm.toggles.filter((toggle) => toggle.name !== metric.code)
        : [ listForm.toggles ].filter((toggle) => toggle.name !== metric.code)
      dispatch(setUpdateListForm(listForm))
    }
  }

  return (
    <div className="cp_lcv-accordion-details_display">
      { (publicAccountDetailMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Public List Account Details") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { publicAccountDetailMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteHighlightsMetric(metric) } }
                deleteIcon={ <CloseIcon /> }
              />
            )) }
          </div>
        </div>
      ) }
      { (scoresEngagementRateMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Scores & Engagement Rates") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { scoresEngagementRateMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteHighlightsMetric(metric) } }
                deleteIcon={ <CloseIcon /> }
              />
            )) }
          </div>
          { (scoresEngagementRateMetrics.some((metric) => metric.name === "Demographic Score*"))
            ? <DemocraphicScoreForm />
            : null
          }
          { (scoresEngagementRateMetrics.some((metric) => metric.name === "Ad Council Score"))
            ? <ContextualRelevancyForm heading="Ad Council Score*" /> : null
          }
          { (scoresEngagementRateMetrics.some((metric) => metric.name === "I-Score"))
            ? <ContextualRelevancyForm heading="I-Score*" /> : null
          }
        </div>
      ) }
      { (accountInsightMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Account Insights") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { accountInsightMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteHighlightsMetric(metric) } }
                deleteIcon={ <CloseIcon /> }
              />
            )) }
          </div>
        </div>
      ) }
      { showHighlightsAudienceForm && (
        <div className="cp_lcv-accordion-details_display_group">
          <AudienceMetricsForm heading="Audience Metrics (up to 5)*" />
        </div>
      ) }
      { (contentMetrics.length > 0) && (
        <div className="cp_lcv-accordion-details_display_group">
          <p className="cp_lcv-accordion-details_display_group_header">{ translate("Content") }</p>
          <div className="cp_lcv-accordion-details_display_group_metric-pills">
            { contentMetrics.map((metric) => (
              <Pill
                className="cp_lcv-accordion-details_display_group_metric-pills_pill"
                label={ translate(metric.name) }
                onDelete={ () => { deleteHighlightsMetric(metric) } }
                deleteIcon={ <CloseIcon /> }
              />
            )) }
          </div>
          { (contentMetrics.some((metric) => metric.name === "Relevant Posts"))
            ? <RelevantPostsForm /> : null
          }
        </div>
      ) }
    </div>
  )
}
