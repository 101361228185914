import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { CheckCircleOutlined, RemoveCircleOutline } from "@mui/icons-material"
import Button from "../Button"
import EntityInfoRow from "../EntityInfoRow"

import "./post-preview.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import { setAssignReviewerModalOpen } from "../../state/campaignDeliverableAssignReviewerModalSlice"
import {
  DeliverableUserGroupOutput,
  DeliverableUserGroupQueryVariables,
  GET_DELIVERABLE_USER_GROUP,
  ReviewerGroupPermission,
  UserVote,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import LoadingIndicatorCard from "../LoadingIndicatorCard"

import "./assign-reviewer.sass"
import { Scope } from "../../util/types"

function AssignReviewer() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const [ searchParams ] = useSearchParams()

  const {
    scopes,
  } = useSelector(({ user }) => user)

  const {
    deliverable,
    modalOpen,
  } = useSelector(({
    campaignDeliverableContentModalV2,
  }) => campaignDeliverableContentModalV2)
  const deliverableId = searchParams.get("deliverableId") || ""

  const statusMap = {
    UNDER_REVIEW: "In Review",
    FEEDBACK_COMPLETE: "Feedback Complete",
  }

  const statusText = deliverable?.currentGroupStatus
    ? statusMap[deliverable?.currentGroupStatus]
    : ""

  const dispatch = useDispatch()

  const {
    data: deliverableUserGroupData, loading, refetch,
  } = useQuery<DeliverableUserGroupOutput, DeliverableUserGroupQueryVariables>(
    GET_DELIVERABLE_USER_GROUP,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
    },
  )

  useEffect(() => {
    if (modalOpen && deliverableUserGroupData) refetch()
  }, [ modalOpen ])

  const getUserVoteIcon = (userVote?: UserVote) => {
    switch (userVote) {
      case UserVote.APPROVED:
        return <CheckCircleOutlined className="approve-icon" />
      case UserVote.APPROVED_WITH_SUGGESTIONS:
        return <CheckCircleOutlined className="approve-icon" />
      case UserVote.REQUEST_REVISION:
        return <RemoveCircleOutline className="request-revision-icon" />
      default:
        return null
    }
  }

  const getUserSubInfo = (permission: ReviewerGroupPermission) => {
    switch (permission) {
      case ReviewerGroupPermission.APPROVE:
        return translate("Approve")
      case ReviewerGroupPermission.FINALIZE:
        return translate("Finalize")
      case ReviewerGroupPermission.COMMENT:
        return translate("Comment")
      default:
        return ""
    }
  }

  const renderAssignedUsers = () => {
    if (loading) return <LoadingIndicatorCard />
    if (
      deliverableUserGroupData
      && deliverableUserGroupData.currentDeliverableUserGroup
      && deliverableUserGroupData.currentDeliverableUserGroup.userAssignments.length > 0
    ) {
      return (
        <div className="cp_component_modal-campaign-deliverable-v2-assign-reviewer-body">
          { deliverableUserGroupData.currentDeliverableUserGroup.userAssignments.map((userAssignment) => (
            <div className="user-row" key={ userAssignment.id }>
              { getUserVoteIcon(userAssignment.userVote) }
              <EntityInfoRow
                className="assign-reviewers-entity-row"
                copyDisabled={ true }
                key={ userAssignment.userId }
                name={ userAssignment.user.fullName }
                subInfo={ getUserSubInfo(userAssignment.permission) }
                avatarSrc={ userAssignment.user.avatarUrl }
              />
            </div>
          )) }
        </div>
      )
    }
    return (
      <div className="cp_component_modal-campaign-deliverable-v2-assign-reviewer-body-empty">
        <div>
          <span>{ translate("No Reviewer Group Assigned") }</span>
        </div>
        {
        scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          ? (
            <Button
              className="cp_btn_component_highlight"
              label={ translate("Assign Reviewer Group") }
              isPrimary={ false }
              isEnabled={ true }
              variant="text"
              onClick={ () => dispatch(setAssignReviewerModalOpen(true)) }
            />
          )
          : null
      }
      </div>
    )
  }

  return (
    <div className="cp_component_modal-campaign-deliverable-v2-assign-reviewer">
      <div className="cp_component_modal-campaign-deliverable-v2-assign-reviewer-header">
        <p className="body_large">
          { deliverableUserGroupData?.currentDeliverableUserGroup?.displayName || translate("Current Reviewer Group") }
        </p>
        { deliverableUserGroupData?.currentDeliverableUserGroup && (
          <p className="body_small">
            { statusText }
          </p>
        ) }
      </div>
      { renderAssignedUsers() }
    </div>
  )
}
export default AssignReviewer
