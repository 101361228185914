import { SuggestionListToggleInput } from "../../graphql"
import {
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
} from "../../util/constant"

const countDisplayAppendixMatches = (toggles: SuggestionListToggleInput[]) => {
  const ALL_TOGGLE_VALUES = new Set([
    ...Object.values(APPENDIX_AUDIENCE_DISPLAY_TOGGLES),
    ...Object.values(APPENDIX_CONTENT_DISPLAY_TOGGLES),
    ...Object.values(APPENDIX_INSIGHTS_DISPLAY_TOGGLES),
  ])
  return toggles.reduce((count, toggle) => count + (ALL_TOGGLE_VALUES.has(toggle.name) ? 1 : 0), 0)
}

export default countDisplayAppendixMatches
