import React, { useMemo, useEffect } from "react"
import Highcharts, { Options, Point } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useTranslation } from "react-i18next"

import "./basic-chart.sass"

import { prettyPrintDecimal } from "../../../util/miscHelper"
import { AUDIENCE_DISPLAY_TOGGLES, BASIC_CHART_BASE_COLOR } from "../../../util/constant"
import Tabs from "../../Tabs"
import * as GraphQL from "../../../graphql"
import { ToggleFragment } from "../../../graphql"

const baseOptions: Options = {
  chart: {
    type: "column",
    plotShadow: false,
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
  plotOptions: {
    series: {
      borderWidth: 0,
    },
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormatter(this: Point) {
      // eslint-disable-next-line max-len
      return `<span style="color:${ this.color }">\u25CF</span> <b>${ prettyPrintDecimal((this.y || 0) / 100) }%</b><br/>`
    },
  },
  credits: {
    enabled: false,
  },
}

type TabValue = "age" | "income" | "educationLevel"

type Props = {
  toggles?: ToggleFragment[]
  audienceDemographics: GraphQL.AudienceDemographicsFragment | undefined | null
  displayAgeRange?: boolean
  displayIncomeRange?: boolean
  displayEducationLevel?: boolean
}

export default function BasicChart({
  toggles,
  audienceDemographics,
  displayAgeRange = true,
  displayEducationLevel = true,
  displayIncomeRange = true,
}: Props) {
  const [ selectedTab, selectTab ] = React.useState<TabValue | null>(null)

  const { t: translate } = useTranslation([], { keyPrefix: "component.BasicChart" })

  const ageGroups = audienceDemographics?.ageGroups || []
  const incomeGroups = audienceDemographics?.incomeGroups || []
  const educationLevelGroups = audienceDemographics?.educationLevel || []

  const ageOptions = useMemo(() => {
    const options = { ...baseOptions }
    const ageMax = Math.round(Math.max(...ageGroups.map(({ value }) => value * 100)))

    options.legend = {
      enabled: false,
    }
    options.series = []
    options.yAxis = {
      title: {
        text: translate("% OF FOLLOWERS"),
      },
      allowDecimals: false,
      min: 0,
      tickInterval: 10,
      max: ageMax + 10,
      labels: {
        format: "{value}%",
      },
    }
    options.xAxis = {
      categories: ageGroups.map(({ name }) => (name.replace("Age", ""))),
    }
    options.series.push({
      name: translate("Age"),
      type: "column",
      data: ageGroups.map(({ name, value }) => ({ name, y: value * 100 })),
      color: BASIC_CHART_BASE_COLOR,
    })

    return options
  }, [ audienceDemographics ])

  const incomeOptions = useMemo(() => {
    const incomeMax = Math.round(Math.max(...incomeGroups.map(({ value }) => value * 100)))
    const options = { ...baseOptions }
    options.legend = {
      enabled: false,
    }
    options.series = []
    options.yAxis = {
      title: {
        text: translate("% OF FOLLOWERS"),
      },
      allowDecimals: false,
      min: 0,
      tickInterval: 10,
      max: incomeMax + 10,
      labels: {
        format: "{value}%",
      },
    }
    options.xAxis = {
      categories: incomeGroups.map(({ name }) => (name)),
    }
    options.series.push({
      name: translate("Income"),
      type: "column",
      data: incomeGroups.map(({ name, value }) => ({ name, y: value * 100 })),
      color: BASIC_CHART_BASE_COLOR,
    })

    return options
  }, [ audienceDemographics ])

  const educationOptions = useMemo(() => {
    const educationMax = Math.round(Math.max(...educationLevelGroups.map(({ value }) => value * 100)))
    const options = { ...baseOptions }
    options.legend = {
      enabled: false,
    }
    options.series = []
    options.yAxis = {
      title: {
        text: translate("% OF FOLLOWERS"),
      },
      allowDecimals: false,
      min: 0,
      tickInterval: 10,
      max: educationMax + 10,
      labels: {
        format: "{value}%",
      },
    }
    options.xAxis = {
      categories: educationLevelGroups.map(({ name }) => (name)),
    }
    options.series.push({
      name: translate("Education Level"),
      type: "column",
      data: educationLevelGroups.map(({ name, value }) => ({ name, y: value * 100 })),
      color: BASIC_CHART_BASE_COLOR,
    })

    return options
  }, [ audienceDemographics ])

  const [ tabs, tabValues ] = useMemo(() => {
    const newTabs = (toggles)
      ? toggles.map((toggle) => {
        switch (toggle.name) {
          case AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE:
            return { label: translate("") }
          case AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE:
            return { label: translate("") }
          default:
            return { label: translate("") }
        }
      })
      : []
    const newTabValues: TabValue[] = (toggles)
      ? toggles.map((toggle) => {
        switch (toggle.name) {
          case AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE:
            return "age"
          case AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE:
            return "income"
          default:
            return "educationLevel"
        }
      })
      : []
    if (!toggles) {
      if (displayAgeRange) {
        newTabs.push({ label: translate("Age") })
        newTabValues.push("age")
      }
      if (displayIncomeRange) {
        newTabs.push({ label: translate("Income") })
        newTabValues.push("income")
      }
      if (displayEducationLevel) {
        newTabs.push({ label: translate("Education Level") })
        newTabValues.push("educationLevel")
      }
    }
    return [ newTabs, newTabValues ]
  }, [ displayAgeRange, displayEducationLevel, displayIncomeRange ])

  const handleSelectTab = (idx: number) => {
    selectTab(tabValues[idx])
  }

  useEffect(() => {
    if (tabValues && tabValues.length) {
      selectTab(tabValues[0])
    }
  }, [ tabValues ])

  return (
    <div className="cp_basic-chart_component-container">
      <Tabs
        handleChange={ handleSelectTab }
        tabs={ tabs }
        controls={ <div /> }
      />
      <div className="cp_basic-chart_component">
        { selectedTab && (
          <div className="cp_basic-chart_component-chart">
            { selectedTab === "age" && <HighchartsReact highcharts={ Highcharts } options={ ageOptions } /> }
            { selectedTab === "income" && <HighchartsReact highcharts={ Highcharts } options={ incomeOptions } /> }
            { selectedTab === "educationLevel" && <HighchartsReact highcharts={ Highcharts } options={ educationOptions } /> }
          </div>
        ) }
      </div>
    </div>
  )
}
