import { DefaultContext } from "@apollo/client"
import * as API from "./index"
import * as GraphQL from "../../graphql"
import { Status } from "../types"

export const getAvailableCampaigns = async (
  variables: GraphQL.GetAvailableCampaignsQueryVariables,
) => (
  API.query<
    GraphQL.GetAvailableCampaignsQuery,
    GraphQL.GetAvailableCampaignsQueryVariables
  >(GraphQL.GetAvailableCampaignsDocument, variables)
)

export const getDailyHourlyEngagementSummary = async (
  variables: GraphQL.GetDailyHourlyEngagementSummaryQueryVariables,
) => (
  API.query<
    GraphQL.GetDailyHourlyEngagementSummaryQuery,
    GraphQL.GetDailyHourlyEngagementSummaryQueryVariables
  >(
    GraphQL.GetDailyHourlyEngagementSummaryDocument,
    variables,
  )
)

export const getPublicDailyHourlyEngagementSummary = async (
  variables: GraphQL.FetchPublicHourlyEngagementSummaryQueryVariables,
) => (
  API.query<
    GraphQL.FetchPublicHourlyEngagementSummaryQuery,
    GraphQL.FetchPublicHourlyEngagementSummaryQueryVariables
  >(
    GraphQL.FetchPublicHourlyEngagementSummaryDocument,
    variables,
  )
)

export const getCommunicationGroup = async (
  variables: GraphQL.GetCommunicationGroupPageQueryVariables,
) => (
  API.query<
    GraphQL.GetCommunicationGroupPageQuery,
    GraphQL.GetCommunicationGroupPageQueryVariables
  >(
    GraphQL.GetCommunicationGroupPageDocument,
    variables,
  )
)

export const fetchAdCampaigns = async (
  params: GraphQL.SearchAdCampaignsQueryVariables,
) => (
  API.query<
    GraphQL.SearchAdCampaignsQuery,
    GraphQL.SearchAdCampaignsQueryVariables
  >(GraphQL.SearchAdCampaignsDocument, params)
)

export const fetchCurrentUser = async () => API.query<
  GraphQL.CurrentUserQuery
>(GraphQL.CurrentUserDocument)

export const fetchCurrentUserWithoutRedirect = async () => API.query<
  GraphQL.CurrentUserQuery
>(
  GraphQL.CurrentUserDocument,
  undefined,
  undefined,
  undefined,
  true,
)

export const fetchContentAlerts = async () => API.query<
  GraphQL.GetContentAlertsQuery
>(GraphQL.GetContentAlertsDocument)

export const fetchContentAlertsByString = async (params: GraphQL.SearchContentAlertsQueryVariables) => API.query<
  GraphQL.SearchContentAlertsQuery,
  GraphQL.SearchContentAlertsQueryVariables
>(GraphQL.SearchContentAlertsDocument, params)

export const fetchAdCampaign = async (params: GraphQL.GetAdCampaignQueryVariables) => API.query<
  GraphQL.GetAdCampaignQuery,
  GraphQL.GetAdCampaignQueryVariables
>(GraphQL.GetAdCampaignDocument, params)

export const fetchAffinities = async (
  startsWith: string,
): Promise<Status<GraphQL.SearchAffinitiesQuery>> => (
  API.query<
    GraphQL.SearchAffinitiesQuery,
    GraphQL.SearchAffinitiesQueryVariables
  >(
    GraphQL.SearchAffinitiesDocument,
    { startsWith },
  )
)

export const fetchAffinitiesForList = async (
  startsWith: string,
  networkFilter?: GraphQL.Network,
): Promise<Status<GraphQL.SearchAffinitiesForListQuery>> => (
  API.query<
    GraphQL.SearchAffinitiesForListQuery,
    GraphQL.SearchAffinitiesForListQueryVariables
  >(
    GraphQL.SearchAffinitiesForListDocument,
    { startsWith, networkFilter },
  )
)

export const fetchAudienceLocations = async (
  startsWith: string,
): Promise<Status<GraphQL.SearchAudienceLocationsQuery>> => (
  API.query<
    GraphQL.SearchAudienceLocationsQuery,
    GraphQL.SearchAudienceLocationsQueryVariables
  >(
    GraphQL.SearchAudienceLocationsDocument,
    { startsWith },
  )
)

export const fetchBrands = async (
  startsWith: string | null,
): Promise<Status<GraphQL.SearchBrandsQuery>> => (
  API.query<
    GraphQL.SearchBrandsQuery,
    GraphQL.SearchBrandsQueryVariables
  >(
    GraphQL.SearchBrandsDocument,
    { startsWith },
  )
)

export const fetchSocialAccounts = async (
  startsWith: string,
): Promise<Status<GraphQL.SearchSocialAccountsQuery>> => (
  API.query<
    GraphQL.SearchSocialAccountsQuery,
    GraphQL.SearchSocialAccountsQueryVariables
  >(
    GraphQL.SearchSocialAccountsDocument,
    { startsWith },
  )
)

export const fetchCampaigns = async (
  {
    startsWith,
    column,
    direction,
    filter,
    page,
    limit,
  }: GraphQL.SearchCampaignsQueryVariables,
): Promise<Status<GraphQL.SearchCampaignsQuery>> => (API.query<
    GraphQL.SearchCampaignsQuery,
    GraphQL.SearchCampaignsQueryVariables
    >(GraphQL.SearchCampaignsDocument, {
      startsWith,
      column,
      direction,
      filter,
      page,
      limit,
    }))

export const fetchCampaignsExtended = async (
  {
    startsWith,
    column,
    direction,
    filter,
    page,
    limit,
  }: GraphQL.SearchCampaignsQueryVariables,
): Promise<Status<GraphQL.SearchCampaignsExtendedQuery>> => (API.query<
    GraphQL.SearchCampaignsExtendedQuery,
    GraphQL.SearchCampaignsExtendedQueryVariables
    >(GraphQL.SearchCampaignsExtendedDocument, {
      startsWith,
      column,
      direction,
      filter,
      page,
      limit,
    }))

export const fetchCampaignsExtendedV2 = async (
  {
    startsWith,
    column,
    direction,
    filter,
    page,
    limit,
  }: GraphQL.SearchCampaignsQueryVariables,
): Promise<Status<GraphQL.SearchCampaignsExtendedV2Query>> => (API.query<
        GraphQL.SearchCampaignsExtendedV2Query,
        GraphQL.SearchCampaignsExtendedV2QueryVariables
        >(GraphQL.SearchCampaignsExtendedV2Document, {
          startsWith,
          column,
          direction,
          filter,
          page,
          limit,
        }))

export const fetchCampaignUsers = async (
  params: GraphQL.SearchCampaignUsersQueryVariables,
) => (
  API.query<
    GraphQL.SearchCampaignUsersQuery,
    GraphQL.SearchCampaignUsersQueryVariables
  >(GraphQL.SearchCampaignUsersDocument, params)
)

export const fetchCampaignsByNetworkIds = async (
  {
    networkAccountIds, networkFilter, limit, page,
  }: GraphQL.SearchCampaignsByNetworkAccountIdsQueryVariables,
) => (API.query<
  GraphQL.SearchCampaignsByNetworkAccountIdsQuery,
  GraphQL.SearchCampaignsByNetworkAccountIdsQueryVariables>(
    GraphQL.SearchCampaignsByNetworkAccountIdsDocument,
    {
      networkAccountIds,
      networkFilter,
      limit,
      page,
    },
    "network-only",
  )
)

export const fetchCommunications = async (
  params: GraphQL.SearchCommunicationGroupsQueryVariables,
) => (
  API.query<
    GraphQL.SearchCommunicationGroupsQuery,
    GraphQL.SearchCommunicationGroupsQueryVariables
    >(GraphQL.SearchCommunicationGroupsDocument, params)
)

export const fetchCommunicationsByNetworkAccountIds = async (
  {
    networkAccountIds, networkFilter, limit, page,
  }: GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQueryVariables,
) => (API.query<
    GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQuery,
    GraphQL.SearchCommunicationGroupsByNetworkAccountIdsQueryVariables
  >(GraphQL.SearchCommunicationGroupsByNetworkAccountIdsDocument, {
    networkAccountIds,
    networkFilter,
    limit,
    page,
  }, "network-only"))

export const fetchCommunicationTemplates = async (
  startsWith: string,
  direction: GraphQL.SortDirection,
  column: GraphQL.SearchCommunicationTemplatesSort,
  page: number,
  limit: number,
) => (
  API.query<
    GraphQL.SearchCommunicationTemplatesQuery,
    GraphQL.SearchCommunicationTemplatesQueryVariables
    >(GraphQL.SearchCommunicationTemplatesDocument, {
      startsWith,
      direction,
      column,
      page,
      limit,
    })
)

export const fetchCustomer = async (
  params: GraphQL.GetCustomerQueryVariables,
) => (
  API.query<
  GraphQL.GetCustomerQuery,
  GraphQL.GetCustomerQueryVariables
  >(GraphQL.GetCustomerDocument, params))

export const searchConversationThreads = async (
  params: GraphQL.SearchConversationThreadsQueryVariables,
) => (
  API.query<
  GraphQL.SearchConversationThreadsQuery,
  GraphQL.SearchConversationThreadsQueryVariables
  >
)(GraphQL.SearchConversationThreadsDocument, params)

export const searchTeamMembersForCustomer = async (
  params: GraphQL.SearchTeamMembersForCustomerQueryVariables,
) => (
  API.query<
  GraphQL.SearchTeamMembersForCustomerQuery,
  GraphQL.SearchTeamMembersForCustomerQueryVariables
  >
)(GraphQL.SearchTeamMembersForCustomerDocument, params)

export const searchTeamMembersByTitleForCustomer = async (
  params: GraphQL.SearchTeamMembersForCustomerByTitleQueryVariables,
) => (
  API.query<
  GraphQL.SearchTeamMembersForCustomerByTitleQuery,
  GraphQL.SearchTeamMembersForCustomerByTitleQueryVariables
  >
)(GraphQL.SearchTeamMembersForCustomerByTitleDocument, params)

export const searchNetworkAccountsByCommGroupId = async (
  params: GraphQL.SearchNetworkAccountsByCommGroupIdQueryVariables,
) => (
  API.query<
    GraphQL.SearchNetworkAccountsByCommGroupIdQuery,
    GraphQL.SearchNetworkAccountsByCommGroupIdQueryVariables
  >(GraphQL.SearchNetworkAccountsByCommGroupIdDocument, params)
)

export const searchGroupAccountsByCommGroupId = async (
  params: GraphQL.SearchGroupAccountsByCommGroupIdQueryVariables,
) => (
  API.query<
    GraphQL.SearchGroupAccountsByCommGroupIdQuery,
    GraphQL.SearchGroupAccountsByCommGroupIdQueryVariables
  >(GraphQL.SearchGroupAccountsByCommGroupIdDocument, params)
)

export const fetchCustomers = async (
  params: GraphQL.SearchCustomersQueryVariables,
) => (
  API.query<
  GraphQL.SearchCustomersQuery,
  GraphQL.SearchCustomersQueryVariables
  >(GraphQL.SearchCustomersDocument, params))

export const fetchCustomersByUser = async (params: GraphQL.SearchCustomersByUserQueryVariables) => (
  API.query<
  GraphQL.SearchCustomersByUserQuery,
  GraphQL.SearchCustomersByUserQueryVariables
  >(GraphQL.SearchCustomersByUserDocument, params))

export const fetchImageTags = async (
  startsWith: string | null,
): Promise<Status<GraphQL.SearchImageTagsQuery>> => (
  API.query<
    GraphQL.SearchImageTagsQuery,
    GraphQL.SearchImageTagsQueryVariables
  >(
    GraphQL.SearchImageTagsDocument,
    { startsWith },
  )
)

export const fetchListsBySocialAccountIds = async (
  params: GraphQL.SearchListsBySocialAccountIdsQueryVariables,
) => (API.query<
    GraphQL.SearchListsBySocialAccountIdsQuery,
    GraphQL.SearchListsBySocialAccountIdsQueryVariables
  >(GraphQL.SearchListsBySocialAccountIdsDocument, params))

export const fetchListGroupById = async (
  params: GraphQL.SearchListGroupByIdQueryVariables,
) => (API.query<
  GraphQL.SearchListGroupByIdQuery,
  GraphQL.SearchListGroupByIdQueryVariables
  >(GraphQL.SearchListGroupByIdDocument, params))

export const fetchPublicListGroupByCode = async (
  params: GraphQL.FetchPublicListGroupByCodeQueryVariables,
) => (API.query<
    GraphQL.FetchPublicListGroupByCodeQuery,
    GraphQL.FetchPublicListGroupByCodeQueryVariables
  >(GraphQL.FetchPublicListGroupByCodeDocument, params))

export const fetchPublicListSocialAccount = async (
  params: GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQueryVariables,
) => (API.query<
    GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQuery,
    GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQueryVariables
  >(GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsDocument, params))

export const fetchPublicListGroupByCodeForContentTab = async (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQueryVariables,
) => (API.query<
    GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQuery,
    GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQueryVariables
  >(GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabDocument, params))

export const fetchPublicListSocialAccountForPostsByKeyword = async (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQueryVariables,
) => (API.query<
    GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery,
    GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQueryVariables
  >(GraphQL.PublicSuggestionListSocialAccountByCodeForPostsDocument, params))

export const fetchPublicListSocialAccountForPostsByImageId = async (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQueryVariables,
) => (API.query<
  GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery,
  GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQueryVariables
  >(GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdDocument, params))

export const fetchLists = async (
  {
    startsWith,
    networkFilter,
    page,
    limit,
  }: GraphQL.SearchListsQueryVariables,
) => (API.query<
  GraphQL.SearchListsQuery,
  GraphQL.SearchListsQueryVariables
  >(GraphQL.SearchListsDocument, {
    startsWith,
    networkFilter,
    page,
    limit,
  }))

export const fetchListsAndListGroups = async (
  direction: GraphQL.SortDirection,
  column: GraphQL.SuggestionListAndGroupColumn,
  filter: string,
  networkFilter: GraphQL.Network[],
  page: number,
  limit: number,
  typeFilter: GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter | null,
) => (API.query<
  GraphQL.SearchListsAndListGroupsQuery,
  GraphQL.SearchListsAndListGroupsQueryVariables
  >(GraphQL.SearchListsAndListGroupsDocument, {
    direction,
    column,
    filter,
    networkFilter,
    page,
    limit,
    typeFilter,
  }))

export const fetchListsAndListGroupsByTag = async (
  params: GraphQL.SearchListsAndListGroupsByVerticalQueryVariables,
) => (API.query<
  GraphQL.SearchListsAndListGroupsByVerticalQuery,
  GraphQL.SearchListsAndListGroupsByVerticalQueryVariables
  >(GraphQL.SearchListsAndListGroupsByVerticalDocument, params))

export const fetchLocations = async (
  startsWith: string,
): Promise<Status<GraphQL.SearchLocationsQuery>> => (
  API.query<
    GraphQL.SearchLocationsQuery,
    GraphQL.SearchLocationsQueryVariables
  >(
    GraphQL.SearchLocationsDocument,
    { startsWith },
  )
)

export const fetchOccupationsForDemographicScore = async (
  startsWith: string,
): Promise<Status<GraphQL.SearchOccupationsForDemographicScoreQuery>> => (
  API.query<
    GraphQL.SearchOccupationsForDemographicScoreQuery,
    GraphQL.SearchOccupationsForDemographicScoreQueryVariables
  >(
    GraphQL.SearchOccupationsForDemographicScoreDocument,
    { startsWith },
  )
)

export const fetchAffinitiesForDemographicScore = async (
  startsWith: string,
): Promise<Status<GraphQL.SearchAffinitiesForDemographicScoreQuery>> => (
  API.query<
    GraphQL.SearchAffinitiesForDemographicScoreQuery,
    GraphQL.SearchAffinitiesForDemographicScoreQueryVariables
  >(GraphQL.SearchAffinitiesForDemographicScoreDocument, { filter: startsWith })
)

export const fetchPersonalities = async (
  startsWith: string,
  page: number,
  personalityFilterTarget: GraphQL.PersonalityFilterTargetType | null,
  limit: number,
  networkFilter: GraphQL.Network[],
  context: DefaultContext,
) => (API.query<
  GraphQL.SearchPersonalitiesSlidingPanelQuery,
  GraphQL.SearchPersonalitiesSlidingPanelQueryVariables
  >(GraphQL.SearchPersonalitiesSlidingPanelDocument, {
    startsWith,
    page,
    personalityFilterTarget,
    limit,
    networkFilter,
  }, "network-only", context))

export const fetchPersonalitySocialAccountCountByCustomer = async () => (API.query<
  GraphQL.GetPersonalitySocialAccountCountByCustomerQuery,
  GraphQL.GetPersonalitySocialAccountCountByCustomerQueryVariables
  >(GraphQL.GetPersonalitySocialAccountCountByCustomerDocument, {}))

export const fetchPublicListByCode = async (
  params: GraphQL.GetPublicListByCodeQueryVariables,
) => (API.query<
    GraphQL.GetPublicListByCodeQuery,
    GraphQL.GetPublicListByCodeQueryVariables
  >(GraphQL.GetPublicListByCodeDocument, params))

export const fetchPublicListAudienceByCode = async (
  params: GraphQL.GetPublicAudienceDemographicsQueryVariables,
) => (API.query<
    GraphQL.GetPublicAudienceDemographicsQuery,
    GraphQL.GetPublicAudienceDemographicsQueryVariables
  >(GraphQL.GetPublicAudienceDemographicsDocument, params))

export const fetchPublicListNameByCode = async (
  params: GraphQL.GetPublicListNameByCodeQueryVariables,
) => (API.query<
    GraphQL.GetPublicListNameByCodeQuery,
    GraphQL.GetPublicListNameByCodeQueryVariables
  >(GraphQL.GetPublicListNameByCodeDocument, params))

export const fetchTeamMembers = async (
  params: GraphQL.SearchTeamMembersQueryVariables,
) => (API.query<
    GraphQL.SearchTeamMembersQuery,
    GraphQL.SearchTeamMembersQueryVariables
  >(GraphQL.SearchTeamMembersDocument, params))

export const fetchSocialProfile = async (
  id: string,
) => (
  API.query<
    GraphQL.GetSocialProfileQuery,
    GraphQL.GetSocialProfileQueryVariables
  >(GraphQL.GetSocialProfileDocument, { id })
)

export const fetchListSocialProfileToggles = (
  params: GraphQL.SearchListSocialProfileTogglesQueryVariables,
) => (
  API.query<
    GraphQL.SearchListSocialProfileTogglesQuery,
    GraphQL.SearchListSocialProfileTogglesQueryVariables
  >(GraphQL.SearchListSocialProfileTogglesDocument, params)
)

export const fetchListSocialProfile = (
  params: GraphQL.SearchListSocialProfileQueryVariables,
) => (
  API.query<
    GraphQL.SearchListSocialProfileQuery,
    GraphQL.SearchListSocialProfileQueryVariables
  >(GraphQL.SearchListSocialProfileDocument, params)
)
export const fetchSocialProfileForNotes = async (id: string) => (
  API.query<
    GraphQL.SearchSocialProfileForNotesQuery,
    GraphQL.SearchSocialProfileForNotesQueryVariables
  >(GraphQL.SearchSocialProfileForNotesDocument, { socialAccountId: id })
)

export const fetchVerticals = async () => (
  API.query<
    GraphQL.GetSuggestionListVerticalsQueryQuery,
    GraphQL.GetSuggestionListVerticalsQueryQueryVariables
    >(GraphQL.GetSuggestionListVerticalsQueryDocument)
)

export const fetchUsersForSubscription = async (startsWith?: string) => (
  API.query<
    GraphQL.SearchUsersForSubscriptionQuery,
    GraphQL.SearchUsersForSubscriptionQueryVariables
    >(GraphQL.SearchUsersForSubscriptionDocument, { startsWith })
)

export const fetchMentionedPosts = async (
  params: GraphQL.GetMentionedPostsQueryVariables,
) => (API.query<
    GraphQL.GetMentionedPostsQuery,
    GraphQL.GetMentionedPostsQueryVariables
  >(GraphQL.GetMentionedPostsDocument, params))

export const fetchPublicMentionedPosts = async (
  params: GraphQL.GetPublicMentionedPostsQueryVariables,
) => (API.query<
    GraphQL.GetPublicMentionedPostsQuery,
    GraphQL.GetPublicMentionedPostsQueryVariables
  >(GraphQL.GetPublicMentionedPostsDocument, params))

export const getMentionedSocialAccounts = async (
  params: GraphQL.GetMentionedSocialAccountsQueryVariables,
) => (
  API.query<
    GraphQL.GetMentionedSocialAccountsQuery,
    GraphQL.GetMentionedSocialAccountsQueryVariables
    >(GraphQL.GetMentionedSocialAccountsDocument, params)
)

export const getPublicMentionedSocialAccounts = async (
  params: GraphQL.PublicGetMentionedSocialAccountsQueryVariables,
) => (
  API.query<
    GraphQL.PublicGetMentionedSocialAccountsQuery,
    GraphQL.PublicGetMentionedSocialAccountsQueryVariables
    >(GraphQL.PublicGetMentionedSocialAccountsDocument, params)
)

export const getDetailedSuggestionList = async (
  params: GraphQL.SearchSuggestionListByIdQueryVariables,
) => (
  API.query<
    GraphQL.SearchSuggestionListByIdQuery,
    GraphQL.SearchSuggestionListByIdQueryVariables
    >(GraphQL.SearchSuggestionListByIdDocument, params)
)

export const fetchAccountsIScore = async (
  params: GraphQL.GetSocialAccountsIScoreQueryVariables,
) => (
  API.query<
    GraphQL.GetSocialAccountsIScoreQuery,
    GraphQL.GetSocialAccountsIScoreQueryVariables
  >(GraphQL.GetSocialAccountsIScoreDocument, params)
)

export const getSuggestionListById = async (
  params: GraphQL.GetSuggestionListQueryVariables,
) => (
  API.query<
    GraphQL.GetSuggestionListQuery,
    GraphQL.GetSuggestionListQueryVariables
  >(GraphQL.GetSuggestionListDocument, params)
)

export const fetchSocialProfileAudience = async (
  params: GraphQL.AudienceDemographicsQueryVariables,
) => (
  API.query<
    GraphQL.AudienceDemographicsQuery,
    GraphQL.AudienceDemographicsQueryVariables
    >(GraphQL.AudienceDemographicsDocument, params)
)

export const getUpdateList = async (id: string) => (
  API.query<
    GraphQL.GetSuggestionListQuery,
    GraphQL.GetSuggestionListQueryVariables
    >(GraphQL.GetSuggestionListDocument, { id })
)

export const searchSocialNetwork = async (
  params: GraphQL.SearchProfileSocialZombieQueryVariables,
) => (
  API.query<
      GraphQL.SearchProfileSocialZombieQuery,
      GraphQL.SearchProfileSocialZombieQueryVariables
      >(GraphQL.SearchProfileSocialZombieDocument, params)
)

export const verifyPublicListAccessCodeByListCode = async (
  params: GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQueryVariables,
) => (
  API.query<
    GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQuery,
    GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQueryVariables
  >(GraphQL.PublicVerifySuggestionListAccessCodeByListCodeDocument, params)
)

export const fetchTopPostsForContentTab = async (listId: string, socialAccountId: string) => (
  API.query<
    GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdForContentTabQuery,
    GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdForContentTabQueryVariables
  >(GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdForContentTabDocument, { listId, socialAccountId })
)

export const getConversations = async (
  params: GraphQL.GetConversationsQueryVariables,
) => (
  API.query<
    GraphQL.GetConversationsQuery,
    GraphQL.GetConversationsQueryVariables
  >(GraphQL.GetConversationsDocument, params)
)

export const getSuggestionListKeywords = async (
  params: GraphQL.SuggestionListKeywordsQueryVariables,
) => (
  API.query<
    GraphQL.SuggestionListKeywordsQuery,
    GraphQL.SuggestionListKeywordsQueryVariables
  >(GraphQL.SuggestionListKeywordsDocument, params)
)

export const getSuggestionListImageTags = async (
  params: GraphQL.SuggestionListQueryQueryVariables,
) => (
  API.query<
    GraphQL.SuggestionListQueryQuery,
    GraphQL.SuggestionListQueryQueryVariables
  >(GraphQL.SuggestionListQueryDocument, params)
)

export const getSuggestionListPostsByKeyword = async (
  params: GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQueryVariables,
) => (
  API.query<
    GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQuery,
    GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQueryVariables
  >(GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdDocument, params)
)

export const getSuggestionListPostsByImageTag = async (
  params: GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQueryVariables,
) => (
  API.query<
    GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQuery,
    GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQueryVariables
  >(GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagDocument, params)
)

export const fetchCampaign = async (
  params: GraphQL.CampaignQueryVariables,
) => (
  API.query<
    GraphQL.CampaignQuery,
    GraphQL.CampaignQueryVariables
  >(GraphQL.CampaignDocument, params)
)

export const fetchCampaignCommunicationSettings = async (
  params: GraphQL.GetCampaignCommunicationSettingsQueryVariables,
) => (API.query<
  GraphQL.GetCampaignCommunicationSettingsQuery,
  GraphQL.GetCampaignCommunicationSettingsQueryVariables
  >(GraphQL.GetCampaignCommunicationSettingsDocument, params))

export const fetchCampaignManagers = async () => (
  API.query<
    GraphQL.GetCampaignManagersQuery,
    GraphQL.GetCampaignManagersQueryVariables
  >(GraphQL.GetCampaignManagersDocument)
)

export const fetchVanity = async (
  code: string,
): Promise<Status<GraphQL.GetVanityQuery>> => (
  API.query<
    GraphQL.GetVanityQuery,
    GraphQL.GetVanityQueryVariables
  >(
    GraphQL.GetVanityDocument,
    { code },
    undefined,
    undefined,
    true,
  )
)

export const fetchAlternateLoginFlowsForVanity = async (
  code: string,
  targetCustomerCode: string,
): Promise<Status<GraphQL.GetAlternateLoginFlowsForVanityQuery>> => (
  API.query<
    GraphQL.GetAlternateLoginFlowsForVanityQuery,
    GraphQL.GetAlternateLoginFlowsForVanityQueryVariables
  >(
    GraphQL.GetAlternateLoginFlowsForVanityDocument,
    { code, targetCustomerCode },
    undefined,
    undefined,
    true,
  )
)

export const getDeliverableHistory = async (
  params: GraphQL.DeliverableHistoryQueryVariables,
): Promise<Status<GraphQL.DeliverableHistoryQuery>> => (
  API.query<
    GraphQL.DeliverableHistoryQuery,
    GraphQL.DeliverableHistoryQueryVariables
  >(GraphQL.DeliverableHistoryDocument, params)
)

export const fetchCampaignReportSettings = async (
  id: string,
) => (
  API.query<
    GraphQL.GetCampaignSettingsQuery,
    GraphQL.GetCampaignSettingsQueryVariables
  >(GraphQL.GetCampaignSettingsDocument, { id })
)

export const fetchCampaignForReporting = async (
  id: string,
) => (
  API.query<
    GraphQL.GetCampaignForReportQuery,
    GraphQL.GetCampaignForReportQueryVariables
  >(GraphQL.GetCampaignForReportDocument, { id })
)

export const fetchCampaignForReportingV2 = async (
  id: string,
) => (
  API.query<
    GraphQL.GetCampaignForReportV2Query,
    GraphQL.GetCampaignForReportQueryVariables
  >(GraphQL.GetCampaignForReportV2Document, { id })
)

export const fetchPublicCampaignReport = async (
  params: GraphQL.GetPublicCampaignReportQueryVariables,
) => (
  API.query<
    GraphQL.GetPublicCampaignReportQuery,
    GraphQL.GetPublicCampaignReportQueryVariables
  >(GraphQL.GetPublicCampaignReportDocument, params)
)

export const fetchPublicCampaignReportV2 = async (
  params: GraphQL.GetPublicCampaignReportQueryVariables,
) => (
  API.query<
    GraphQL.GetPublicCampaignReportV2Query,
    GraphQL.GetPublicCampaignReportQueryVariables
  >(GraphQL.GetPublicCampaignReportV2Document, params)
)

export const fetchCampaignReportMetricDefinitions = async () => (
  API.query<
    GraphQL.GetCampaignReportMetricDefinitionsQuery
  >(GraphQL.GetCampaignReportMetricDefinitionsDocument)
)

export const fetchSocialAccountScoreHistogram = async (
  id: string,
) => (
  API.query<
    GraphQL.SocialAccountScoreHistogramQuery,
    GraphQL.SocialAccountScoreHistogramQueryVariables
  >(GraphQL.SocialAccountScoreHistogramDocument, { id })
)

export const fetchScoreHistogram = async (
  params: GraphQL.GodSearchInput,
) => (
  API.query<
    GraphQL.SearchScoreHistogramQuery,
    GraphQL.SearchScoreHistogramQueryVariables
  >(GraphQL.SearchScoreHistogramDocument, { params })
)

export const fetchSocialTrackerById = async (
  xref: string,
  page?: number,
  limit?: number,
) => (
  API.query<
    GraphQL.GetContentAlertsAndSearchContentViaContentAlertQuery,
    GraphQL.GetContentAlertsAndSearchContentViaContentAlertQueryVariables
  >(GraphQL.GetContentAlertsAndSearchContentViaContentAlertDocument, {
    xref, page, limit,
  })
)

export const fetchDeliverable = async (
  params: GraphQL.DeliverableQueryVariables,
) => (
  API.query<
    GraphQL.DeliverableQuery,
    GraphQL.DeliverableQueryVariables
  >(GraphQL.DeliverableDocument, params)
)

export const fetchQualityAudienceScoresSocialProfile = async (
  id: string,
) => (
  API.query<
    GraphQL.SocialAccountQuery,
    GraphQL.SocialAccountQueryVariables
  >(GraphQL.SocialAccountDocument, { id })
)

export const fetchCustomerUser = async (
  params: GraphQL.UserForCustomerManagementQueryVariables,
) => (
  API.query<
    GraphQL.UserForCustomerManagementQuery,
    GraphQL.UserForCustomerManagementQueryVariables
  >(GraphQL.UserForCustomerManagementDocument, params)
)

export const searchRoles = async (
  params: GraphQL.SearchRoleQueryVariables,
) => (
  API.query<
    GraphQL.SearchRoleQuery,
    GraphQL.SearchRoleQueryVariables
  >(GraphQL.SearchRoleDocument, params)
)

export const getCustomerPositions = async (
  params: GraphQL.CustomerPositionsQueryVariables,
) => (
  API.query<
    GraphQL.CustomerPositionsQuery,
    GraphQL.CustomerPositionsQueryVariables
  >(GraphQL.CustomerPositionsDocument, params)
)

export const searchCampaignForCustomerUser = async (
  params: GraphQL.SearchCampaignsCustomerUserQueryVariables,
) => (
  API.query<
    GraphQL.SearchCampaignsCustomerUserQuery,
    GraphQL.SearchCampaignsCustomerUserQueryVariables
  >(GraphQL.SearchCampaignsCustomerUserDocument, params)
)

export const fetchCampaignForAutoComplete = async (
  startsWith: string,
) => (
  API.query<
    GraphQL.SearchCampaignQuery,
    GraphQL.SearchCampaignQueryVariables
  >(GraphQL.SearchCampaignDocument, { startsWith })
)

export const getPublicListScoreHistogram = async (
  params: GraphQL.GetPublicListScoreHistogramQueryVariables,
) => (
  API.query<
  GraphQL.GetPublicListScoreHistogramQuery,
  GraphQL.GetPublicListScoreHistogramQueryVariables
  >(GraphQL.GetPublicListScoreHistogramDocument, params)
)

export const getCommGroupforEditing = async (
  id: string,
) => (
  API.query<
  GraphQL.GetCommunicationGroupForEditQuery,
  GraphQL.GetCommunicationGroupForEditQueryVariables
  >(GraphQL.GetCommunicationGroupForEditDocument, { id })
)

export const getAccountContactInfo = async (
  params: GraphQL.GetAccountContactInfoQueryVariables,
) => (
  API.query<
    GraphQL.GetAccountContactInfoQuery,
    GraphQL.GetAccountContactInfoQueryVariables
  >(GraphQL.GetAccountContactInfoDocument, params)
)

export const fetchCommTemplates = async () => (
  API.query<
    GraphQL.GetCommunicationTemplatesQuery,
    GraphQL.GetCommunicationTemplatesQueryVariables
  >(GraphQL.GetCommunicationTemplatesDocument)
)

export const fetchCommunicationGroupEmails = async (
  communicationGroupId: string,
  startsWith: string,
) => (
  API.query<
    GraphQL.SearchCommunicationGroupNetworkAccountQuery,
    GraphQL.SearchCommunicationGroupNetworkAccountQueryVariables
  >(GraphQL.SearchCommunicationGroupNetworkAccountDocument, { communicationGroupId, startsWith })
)

export const fetchConversations = async (
  params: GraphQL.SearchConversationsQueryVariables,
) => (
  API.query<
    GraphQL.SearchConversationsQuery,
    GraphQL.SearchConversationsQueryVariables
  >(GraphQL.SearchConversationsDocument, params)
)

export const getConversationMessages = async (
  id: string,
) => (
  API.query<
    GraphQL.GetConversationQuery,
    GraphQL.GetConversationQueryVariables
  >(GraphQL.GetConversationDocument, { id })
)

export const getNetworkAccountLabels = async (
  vars: GraphQL.GetNetworkAccountLabelsQueryVariables,
) => (
  API.query<
    GraphQL.GetNetworkAccountLabelsQuery,
    GraphQL.GetNetworkAccountLabelsQueryVariables
  >(GraphQL.GetNetworkAccountLabelsDocument, vars)
)

export const fetchCustomerByCampaign = async (
  params: GraphQL.CampaignCustomerQueryVariables,
) => (
  API.query<
    GraphQL.CampaignCustomerQuery,
    GraphQL.CampaignCustomerQueryVariables
  >(GraphQL.CampaignCustomerDocument, params)
)

export const getGroupAccountPersonalityProfile = async ({ id }: GraphQL.GetGroupAccountPersonalityProfileQueryVariables) => (
  API.query<
    GraphQL.GetGroupAccountPersonalityProfileQuery,
    GraphQL.GetGroupAccountPersonalityProfileQueryVariables
  >(GraphQL.GetGroupAccountPersonalityProfileDocument, { id })
)

export const getCommunicationGroupDrafts = async (params: GraphQL.GetCommunicationGroupDraftsQueryVariables) => (
  API.query<
    GraphQL.GetCommunicationGroupDraftsQuery,
    GraphQL.GetCommunicationGroupDraftsQueryVariables
  >(GraphQL.GetCommunicationGroupDraftsDocument, params)
)

export const getCommunicationGroupDraft = async (params: GraphQL.GetCommunicationGroupDraftQueryVariables) => (
  API.query<
    GraphQL.GetCommunicationGroupDraftQuery,
    GraphQL.GetCommunicationGroupDraftQueryVariables
  >(GraphQL.GetCommunicationGroupDraftDocument, params)
)

export const searchRoleTable = async (params: GraphQL.SearchTableRoleQueryVariables) => (
  API.query<
    GraphQL.SearchTableRoleQuery,
    GraphQL.SearchTableRoleQueryVariables
  >(GraphQL.SearchTableRoleDocument, params)
)

export const searchRoleTableByScope = async (params: GraphQL.SearchTableRoleScopeQueryVariables) => (
  API.query<
    GraphQL.SearchTableRoleScopeQuery,
    GraphQL.SearchTableRoleScopeQueryVariables
  >(GraphQL.SearchTableRoleScopeDocument, params)
)

export const getScopeGroups = async () => (
  API.query<
    GraphQL.ScopeGroupsQuery,
    GraphQL.ScopeGroupsQueryVariables
    >(GraphQL.ScopeGroupsDocument)
)

export const getRole = async (params: GraphQL.GetRoleQueryVariables) => (
  API.query<
    GraphQL.GetRoleQuery,
    GraphQL.GetRoleQueryVariables
  >(GraphQL.GetRoleDocument, params)
)

export const mailCsvSearchResults = async (params: GraphQL.SearchInfluencerContentCsvQueryVariables) => (
  API.query<
    GraphQL.SearchInfluencerContentCsvQuery,
    GraphQL.SearchInfluencerContentCsvQueryVariables
  >(GraphQL.SearchInfluencerContentCsvDocument, params)
)
