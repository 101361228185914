import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { RootState } from "../store"
import { defaultSelectedMetrics, highlightMetrics } from "../../component/ListConfiguration/constants"
import { DefaultSuggestionListToggles } from "../../util/constant"

export type Metric = {
  code: string
  name: string
}

export type SocialAccountFetchedTopics = {
  socialAccountId: string
  posts: GraphQL.SuggestionsPostFragment[]
}

export type TagPosts = {
  tagId: string,
  posts: GraphQL.PostFragment[]
}

export type SocialAccountTaggedTopics = {
  socialAccountId: string
  tagPosts: TagPosts[]
}

export type SocialAccountAudienceDemographics = {
  demographics: GraphQL.GetPublicAudienceDemographicsQuery["publicSocialAccount"]["audienceDemographics"]
  socialAccountId: string
}

export interface ListConfigurationState {
  highlightsMetrics: Metric[]
  appendixsMetrics: Metric[]
  audienceLocations: Status<GraphQL.SearchAudienceLocationsQuery>
  occupations: Status<GraphQL.SearchOccupationsForDemographicScoreQuery>
  affinities: Status<GraphQL.SearchAffinitiesForListQuery>
  topPosts: SocialAccountFetchedTopics[],
  postKeywords: GraphQL.SuggestionListKeyword[],
  keywordPosts: SocialAccountTaggedTopics[],
  postImageTags: GraphQL.SuggestionListImageTag[],
  imageTagPosts: SocialAccountTaggedTopics[],
  audiences: SocialAccountAudienceDemographics[]
  updateListForm?: GraphQL.UpdateSuggestionListMutationVariables
  showHighlightsAudienceForm: boolean
}

const initialState: ListConfigurationState = {
  highlightsMetrics: [],
  appendixsMetrics: [],
  audienceLocations: "init",
  occupations: "init",
  affinities: "init",
  topPosts: [],
  postKeywords: [],
  keywordPosts: [],
  postImageTags: [],
  imageTagPosts: [],
  showHighlightsAudienceForm: false,
  audiences: [],
}

export const listConfigurationSlice = createSlice({
  name: "listConfigurationSlice",
  initialState,
  reducers: {
    setHighlightsMetrics: ((state, action: PayloadAction<Metric[]>) => ({
      ...state,
      highlightsMetrics: action.payload,
    })),
    setAppendixMetrics: ((state, action: PayloadAction<Metric[]>) => ({
      ...state,
      appendixsMetrics: action.payload,
    })),
    setAudienceLocations: ((state, action: PayloadAction<Status<GraphQL.SearchAudienceLocationsQuery>>) => ({
      ...state,
      audienceLocations: action.payload,
    })),
    setOccupations: ((state, action: PayloadAction<Status<GraphQL.SearchOccupationsForDemographicScoreQuery>>) => ({
      ...state,
      occupations: action.payload,
    })),
    setAffinities: ((state, action: PayloadAction<Status<GraphQL.SearchAffinitiesForListQuery>>) => ({
      ...state,
      affinities: action.payload,
    })),
    setUpdateListForm: ((state, action: PayloadAction<GraphQL.UpdateSuggestionListMutationVariables>) => ({
      ...state,
      updateListForm: action.payload,
    })),
    setTopPosts: ((
      state,
      action: PayloadAction<SocialAccountFetchedTopics[]>,
    ) => ({
      ...state,
      topPosts: action.payload,
    })),
    setPostKeywords: ((state, action: PayloadAction<GraphQL.SuggestionListKeyword[]>) => ({
      ...state,
      postKeywords: action.payload,
    })),
    setKeywordPosts: ((
      state,
      action: PayloadAction<SocialAccountTaggedTopics[]>,
    ) => ({
      ...state,
      keywordPosts: action.payload,
    })),
    setPostImageTags: ((state, action: PayloadAction<GraphQL.SuggestionListImageTag[]>) => ({
      ...state,
      postImageTags: action.payload,
    })),
    setImageTagPosts: ((
      state,
      action: PayloadAction<SocialAccountTaggedTopics[]>,
    ) => ({
      ...state,
      imageTagPosts: action.payload,
    })),
    setShowHighlightsAudienceForm: ((state, action: PayloadAction<boolean>) => ({
      ...state,
      showHighlightsAudienceForm: action.payload,
    })),
    setAudienceDemographics: ((state, action: PayloadAction<SocialAccountAudienceDemographics[]>) => ({
      ...state,
      audiences: action.payload,
    })),
    resetState: () => initialState,
  },
})

export const {
  setHighlightsMetrics,
  setAppendixMetrics,
  setAudienceLocations,
  setOccupations,
  setAffinities,
  resetState,
  setUpdateListForm,
  setTopPosts,
  setKeywordPosts,
  setImageTagPosts,
  setPostKeywords,
  setPostImageTags,
  setShowHighlightsAudienceForm,
  setAudienceDemographics,
} = listConfigurationSlice.actions
export default listConfigurationSlice.reducer

export const populateState = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  // Grab list from list state
  const { list } = getState().list

  // Check if the list was successfully fetched
  if (API.isSuccess(list)) {
    // Grab the suggestion list
    const suggestionList = list.payload.suggestionListById

    // Create update list form
    const updateData: GraphQL.UpdateSuggestionListMutationVariables = {
      id: suggestionList.id,
      affinities: suggestionList.affinities.map((aff) => aff.code),
      avatarId: suggestionList.avatar?.id,
      categories: suggestionList.suggestionListCategories.map((category) => category.name),
      clientIds: suggestionList.clients.map((client) => client.id),
      contextualRelevancy: suggestionList.contextualRelevancy,
      description: suggestionList.description,
      keywords: suggestionList.keywords.map((keyword) => keyword.name),
      imageTags: suggestionList.imageTags.map((imageTag) => imageTag.name),
      maxAge: suggestionList.maxAge,
      minAge: suggestionList.minAge,
      name: suggestionList.name,
      network: suggestionList.network,
      isPublic: suggestionList.public,
      sexes: suggestionList.sexes,
      subscribedUserIds: suggestionList.subscribedUsers.map((user) => user.id),
      suggestionAccessCode: suggestionList.suggestionAccessCode,
      suggestionListMode: suggestionList.suggestionListMode,
      suggestionListVerticalIds: suggestionList.verticals.map((vertical) => vertical.id),
      toggles: suggestionList.toggles.map((toggle) => ({
        type: toggle.type,
        name: toggle.name,
        order: toggle.order,
      })),
      vetting: suggestionList.vetting,
      vettingSubscriptionUserIds: suggestionList.subscribedVettingUsers.map((user) => user.id),
      demographicScoreGender: suggestionList.demographicScoreGender,
      demographicScoreEthnicity: suggestionList.demographicScoreEthnicity,
      demographicScoreFamily: suggestionList.demographicScoreFamily,
      demographicScoreReligion: suggestionList.demographicScoreReligion,
      demographicScoreIncome: suggestionList.demographicScoreIncome,
      demographicScoreLocations: suggestionList.demographicScoreLocations.map((location) => location.code),
      demographicScoreOccupations: suggestionList.demographicScoreOccupations.map((occupation) => occupation.code),
      demographicScoreAffinities: suggestionList.demographicScoreAffinities.map((affinity) => affinity.code),
      highlightsAudienceDemographics: (suggestionList.highlightsAudienceDemographics)
        ? {
          audienceGender: suggestionList.highlightsAudienceDemographics.audienceGender,
          audienceFamilyStatus: suggestionList.highlightsAudienceDemographics.audienceFamilyStatus,
          audienceAgeRange: suggestionList.highlightsAudienceDemographics.audienceAgeRange,
          audienceIncomeRange: suggestionList.highlightsAudienceDemographics.audienceIncomeRange,
          audienceEthnicity: suggestionList.highlightsAudienceDemographics.audienceEthnicity,
          audienceReligion: suggestionList.highlightsAudienceDemographics.audienceReligion,
          audienceCountry: (suggestionList.highlightsAudienceDemographics.audienceCountry.length > 0)
            ? suggestionList.highlightsAudienceDemographics.audienceCountry.map((c) => ({
              name: c.name,
              code: c.code,
              type: c.type,
            })) : [],
          audienceState: (suggestionList.highlightsAudienceDemographics.audienceState.length > 0)
            ? suggestionList.highlightsAudienceDemographics.audienceState.map((s) => ({
              name: s.name,
              code: s.code,
              type: s.type,
            })) : [],
          audienceCity: (suggestionList.highlightsAudienceDemographics.audienceCity.length > 0)
            ? suggestionList.highlightsAudienceDemographics.audienceCity.map((c) => ({
              name: c.name,
              code: c.code,
              type: c.type,
            })) : [],
          audienceOccupation: (suggestionList.highlightsAudienceDemographics.audienceOccupation.length > 0)
            ? suggestionList.highlightsAudienceDemographics.audienceOccupation.map((o) => ({
              name: o.name,
              code: o.code,
              category: o.category,
            })) : [],
          audienceAffinity: (suggestionList.highlightsAudienceDemographics.audienceAffinity.length > 0)
            ? suggestionList.highlightsAudienceDemographics.audienceAffinity.map((a) => ({
              name: a.name,
              code: a.code,
              category: a.category,
            })) : [],
        }
        : undefined,
      epsilonVerified: suggestionList.epsilonVerified,
      audienceTypeNotes: suggestionList.audienceTypeNotes,
    }

    const defaultToggles = [
      ...DefaultSuggestionListToggles.filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.AccountInsightsToggles),
      ...DefaultSuggestionListToggles.filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.AudienceToggles),
      ...DefaultSuggestionListToggles.filter((t) => t.type === GraphQL.SuggestionListToggleGroupType.ContentToggles),
    ]
    defaultToggles.forEach((code) => {
      if (!suggestionList.toggles.map((toggle) => toggle.name).includes(code.name)) {
        if (!Array.isArray(updateData.toggles)) updateData.toggles = [ updateData.toggles ]
        updateData.toggles.push(code)
      }
    })

    // Set image tag names and keywords
    dispatch(setPostKeywords(list.payload.suggestionListById.keywords))
    dispatch(setPostImageTags(list.payload.suggestionListById.imageTags))

    // Update highlights metrics
    const hMetrics: Metric[] = []
    const codes: string[] = (Array.isArray(updateData.toggles))
      ? updateData.toggles.map((t) => t.name)
      : [ updateData.toggles.name ]
    codes.forEach((toggleCode) => {
      const toggle = highlightMetrics.flatMap((group) => group.metrics).find((metric) => metric.code === toggleCode)
      if (toggle) hMetrics.push(toggle)
    })

    // Update with default values
    const missingInUpdateForm = defaultSelectedMetrics.filter((defaultMetric) => !codes.includes(defaultMetric.name))
    missingInUpdateForm.forEach((t) => {
      const toggle = highlightMetrics.flatMap((group) => group.metrics).find((metric) => metric.code === t.name)
      if (toggle) hMetrics.push(toggle)
    })
    updateData.toggles = (Array.isArray(updateData.toggles))
      ? [ ...updateData.toggles, ...missingInUpdateForm ]
      : [ updateData.toggles, ...missingInUpdateForm ]

    // Set the state
    dispatch(setUpdateListForm(updateData))
    dispatch(setHighlightsMetrics(hMetrics))
  }
}

export const fetchAudienceLocations = (
  startsWith: string,
) => async (dispatch: Dispatch) => {
  // Set state to loading
  dispatch(setAudienceLocations("loading"))

  // Run the query
  const results = await API.fetchAudienceLocations(startsWith)

  // Set the results
  dispatch(setAudienceLocations(results))
}

export const fetchOccupations = (
  startsWith: string,
) => async (dispatch: Dispatch) => {
  // Set state to loading
  dispatch(setOccupations("loading"))

  // Run the query
  const results = await API.fetchOccupationsForDemographicScore(startsWith)

  // Set the results
  dispatch(setOccupations(results))
}

export const fetchAffinities = (
  startsWith: string,
) => async (dispatch: Dispatch) => {
  // Set state to loading
  dispatch(setAffinities("loading"))

  // Run the query
  const results = await API.fetchAffinitiesForList(startsWith)

  // Set the results
  dispatch(setAffinities(results))
}

export const fetchAudiences = (
  listCode: string,
  socialAccountIds: string[],
) => async (dispatch: Dispatch) => {
  // Fetch all the audience demographics for given social accounts
  const promises = socialAccountIds.map((id) => API.fetchPublicListAudienceByCode({ code: listCode, socialAccountId: id }))
  const results = await Promise.all(promises)

  // Save all the audience demographics to state
  dispatch(setAudienceDemographics(socialAccountIds.map((id, index) => {
    const audResult = results[index]
    if (API.isSuccess(audResult)) {
      return {
        socialAccountId: id,
        demographics: audResult.payload.publicSocialAccount.audienceDemographics,
      }
    }
    return { socialAccountId: id, demographics: undefined }
  })))
}

export const updateList = (params: {
  onSuccess: () => void,
  onError: () => void,
}) => async (dispatch: Dispatch, getState: () => RootState) => {
  // Pull in the list
  const { updateListForm } = getState().listConfiguration

  if (updateListForm) {
    // Call API to update list
    const result = await API.updateList(updateListForm)

    if (API.isSuccess(result)) params.onSuccess()
    if (API.isError(result)) params.onError()
  }
}

export const fetchTopPosts = (
  socialAccountIds: string[],
  listId: string,
) => async (dispatch: Dispatch): Promise<void> => {
  // Fetch all the top posts
  const promises = socialAccountIds.map((id) => API.fetchTopPostsForContentTab(listId, id))
  const results = await Promise.all(promises)

  // Loop through and set results
  const posts: SocialAccountFetchedTopics[] = []
  results.forEach((result, index) => {
    if (API.isSuccess(result)) {
      const accountPosts = result.payload.suggestionListSocialAccountByListIdSocialAccountId.cippusFeatured
        .map((item) => item.post)
      posts.push({
        socialAccountId: socialAccountIds[index],
        posts: accountPosts,
      })
    }
  })

  // Set the posts
  dispatch(setTopPosts(posts))
}

export const getSuggestionListPostsByKeyword = (
  socialAccountIds: string[],
  listId: string,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    listConfiguration: {
      postKeywords,
    },
  } = getState()

  // Get all the keywords
  const postPromises: Promise<Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQuery>>[] = []
  const posts: TagPosts[] = []
  const socialIdToPromise: string[] = []
  socialAccountIds.forEach((socialAccountId) => {
    postKeywords.forEach((keyword) => {
      postPromises.push(API.getSuggestionListPostsByKeyword({
        socialAccountId,
        listId,
        keywordId: keyword.id,
      }))
      posts.push({
        tagId: keyword.id,
        posts: [],
      })
      socialIdToPromise.push(socialAccountId)
    })
  })

  // Process and wait for all to complete processing
  const postResults: Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdQuery>[] = await Promise.all(postPromises)

  // Looop through and grap first post of for each keyword
  const map = new Map<string, TagPosts[]>()
  postResults.forEach((post, index) => {
    if (API.isSuccess(post)) {
      // Extract the posts
      const { cippusRelevant } = post.payload.suggestionListSocialAccountByListIdSocialAccountId
      const allKewordPosts = cippusRelevant.map((item) => item.post)

      // Set in map
      if (map.has(socialIdToPromise[index])) {
        map.get(socialIdToPromise[index])?.push({
          tagId: posts[index].tagId,
          posts: allKewordPosts,
        })
      } else {
        map.set(socialIdToPromise[index], [ {
          tagId: posts[index].tagId,
          posts: allKewordPosts,
        } ])
      }
    }
  })

  // Create resultant array
  const keywordPosts: SocialAccountTaggedTopics[] = []
  socialAccountIds.forEach((socialAccountId) => keywordPosts.push({
    socialAccountId,
    tagPosts: map.get(socialAccountId) || [],
  }))

  // Save the posts to list
  dispatch(setKeywordPosts(keywordPosts))
}

export const getPostsByImageTag = (
  socialAccountIds: string[],
  listId: string,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  // Grab list from list state
  const {
    listConfiguration: {
      postImageTags,
    },
  } = getState()

  // Find all the posts
  const imageTagsPostsPromises: Promise<Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQuery>>[] = []
  const posts: TagPosts[] = []
  const socialAccountIdToPromise: string[] = []
  socialAccountIds.forEach((socialAccountId) => {
    postImageTags.forEach((tag) => {
      imageTagsPostsPromises.push(API.getSuggestionListPostsByImageTag({
        socialAccountId,
        listId,
        suggestionListImageTagId: tag.id,
      }))
      posts.push({
        tagId: tag.id,
        posts: [],
      })
      socialAccountIdToPromise.push(socialAccountId)
    })
  })

  // Wait for all queries to resolve and then process
  // eslint-disable-next-line max-len
  const imageTagPostResults: Status<GraphQL.SuggestionListSocialAccountByListIdSocialAccountIdImageTagQuery>[] = await Promise.all(imageTagsPostsPromises)

  // Process results
  const imageTagMap = new Map<string, TagPosts[]>()
  imageTagPostResults.forEach((imageTagPost, index) => {
    if (API.isSuccess(imageTagPost)) {
      const { cippusImageTags } = imageTagPost.payload.suggestionListSocialAccountByListIdSocialAccountId
      const allImageTagPosts = cippusImageTags.map((item) => item.post)

      // Set in map
      if (imageTagMap.has(socialAccountIdToPromise[index])) {
        imageTagMap.get(socialAccountIdToPromise[index])?.push({
          tagId: posts[index].tagId,
          posts: allImageTagPosts,
        })
      } else {
        imageTagMap.set(socialAccountIdToPromise[index], [ {
          tagId: posts[index].tagId,
          posts: allImageTagPosts,
        } ])
      }
    }
  })

  // Create resultant array
  const imageTagPosts: SocialAccountTaggedTopics[] = []
  socialAccountIds.forEach((socialAccountId) => imageTagPosts.push({
    socialAccountId,
    tagPosts: imageTagMap.get(socialAccountId) || [],
  }))

  // Save the posts to list
  dispatch(setImageTagPosts(imageTagPosts))
}
